import { useAppDispatch } from "src/store/store";
import type { ConfirmSaveReportParams } from "../../store/slices";
import { popupsSlice } from "../../store/slices";
import { useMemo } from "react";

function createHook() {
    const {
        setCreateReportPopupOpen, //
        setCreateReportPopupClose,

        setCreateReportColumnsPopupOpen,
        setCreateReportColumnsPopupClose,

        setCreateReportFiltersPopupOpen,
        setCreateReportFiltersPopupClose,

        setEditReportPopupOpen,
        setEditReportPopupClose,

        setEditReportColumnsPopupOpen,
        setEditReportColumnsPopupClose,

        setEditReportFiltersPopupOpen,
        setEditReportFiltersPopupClose,

        setDraftFiltersPopupOpen,
        setDraftFiltersPopupClose,

        setCopyReportPopupOpen,
        setCopyReportPopupClose,

        setDeleteReportPopupOpen,
        setDeleteReportPopupClose,

        setConfirmSaveReportPopupOpen,
        setConfirmSaveReportPopupClose,

        setNotReportCreatorWarningPopupOpen,
        setNotReportCreatorWarningPopupClose,
    } = popupsSlice.actions;

    return function usePopups() {
        const dispatch = useAppDispatch();

        const actions = useMemo(
            () => ({
                createReportPopup: {
                    open: () => dispatch(setCreateReportPopupOpen()),
                    close: () => dispatch(setCreateReportPopupClose()),
                },
                createReportColumnsPopup: {
                    open: () => dispatch(setCreateReportColumnsPopupOpen()),
                    close: () => dispatch(setCreateReportColumnsPopupClose()),
                },
                createReportFiltersPopup: {
                    open: () => dispatch(setCreateReportFiltersPopupOpen()),
                    close: () => dispatch(setCreateReportFiltersPopupClose()),
                },
                editReportPopup: {
                    open: () => dispatch(setEditReportPopupOpen()),
                    close: () => dispatch(setEditReportPopupClose()),
                },
                editReportColumnsPopup: {
                    open: () => dispatch(setEditReportColumnsPopupOpen()),
                    close: () => dispatch(setEditReportColumnsPopupClose()),
                },
                editReportFiltersPopup: {
                    open: () => dispatch(setEditReportFiltersPopupOpen()),
                    close: () => dispatch(setEditReportFiltersPopupClose()),
                },
                draftFiltersPopup: {
                    open: () => dispatch(setDraftFiltersPopupOpen()),
                    close: () => dispatch(setDraftFiltersPopupClose()),
                },
                copyReportPopup: {
                    open: () => dispatch(setCopyReportPopupOpen()),
                    close: () => dispatch(setCopyReportPopupClose()),
                },
                deleteReportPopup: {
                    open: () => dispatch(setDeleteReportPopupOpen()),
                    close: () => dispatch(setDeleteReportPopupClose()),
                },
                confirmSaveReportPopup: {
                    open: (params: ConfirmSaveReportParams) => dispatch(setConfirmSaveReportPopupOpen(params)),
                    close: () => dispatch(setConfirmSaveReportPopupClose()),
                },
                notReportCreatorWarningPopup: {
                    open: () => dispatch(setNotReportCreatorWarningPopupOpen()),
                    close: () => dispatch(setNotReportCreatorWarningPopupClose()),
                },
            }),
            [dispatch],
        );

        return {
            actions,
        };
    };
}

export const usePopups = createHook();
