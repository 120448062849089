import React from "react";

import classnames from "classnames/bind";

import styles from "./NoAccess.module.scss";

const cx = classnames.bind(styles);

export function NoAccess() {
    return <div className={cx("no-access")}>No access</div>;
}
