export const DateType = {
    IS: "IS",
    IS_NOT: "IS_NOT",
    IS_AFTER: "IS_AFTER",
    IS_ON_OR_AFTER: "IS_ON_OR_AFTER",
    IS_BEFORE: "IS_BEFORE",
    IS_ON_OR_BEFORE: "IS_ON_OR_BEFORE",
    BETWEEN: "BETWEEN",
    NOT_BETWEEN: "NOT_BETWEEN",
} as const;

export type DateType = (typeof DateType)[keyof typeof DateType];
