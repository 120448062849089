import type { ColumnFilterPair, SourceColumn } from "src/core/entities";
import { getReportSource } from "./get-report-source";
import { format } from "date-fns";

const SYMBOLS_KEYS = [
    "IS", //
    "IS_NOT",
    "IS_AFTER",
    "IS_ON_OR_AFTER",
    "IS_BEFORE",
    "IS_ON_OR_BEFORE",
] as const;

type SymbolKey = (typeof SYMBOLS_KEYS)[number];

const SYMBOLS = {
    IS: "=",
    IS_NOT: "<>",
    IS_AFTER: ">",
    IS_ON_OR_AFTER: ">=",
    IS_BEFORE: "<",
    IS_ON_OR_BEFORE: "<=",
} as const satisfies Record<SymbolKey, string>;

function sortStringChoices(a: string, b: string) {
    if (a < b) {
        return -1;
    }

    if (a > b) {
        return 1;
    }

    return 0;
}

function sortBooleanChoices(a: boolean, b: boolean) {
    if (a < b) {
        return -1;
    }

    if (a > b) {
        return 1;
    }

    return 0;
}

function getColumnsParams(urlParams: URLSearchParams) {
    //

    return (columns: SourceColumn[], pairs: ColumnFilterPair[]): URLSearchParams => {
        if (columns.length > 0) {
            const newColumns: SourceColumn[] = [
                ...columns,
                ...pairs
                    .map((pair: ColumnFilterPair) => {
                        if (pair.column.type === "SELECT") {
                            return pair.column;
                        }

                        return null;
                    })
                    .filter(Boolean),
            ];

            const uniqueColumns = [
                ...new Map(
                    newColumns.map((sourceColumn: SourceColumn) => {
                        return [sourceColumn.id, sourceColumn];
                    }),
                ).values(),
            ];

            const sorted = [...uniqueColumns];

            sorted.sort((a: SourceColumn, b: SourceColumn) => {
                return a.id - b.id;
            });

            sorted.forEach((column: SourceColumn) => {
                urlParams.append("column", column.name);
            });
        }

        return urlParams;
    };
}

function getFiltersParams(urlParams: URLSearchParams) {
    //

    return (pairs: ColumnFilterPair[]): URLSearchParams => {
        if (pairs.length > 0) {
            const values: string[] = [];

            pairs.forEach((pair: ColumnFilterPair) => {
                const { filter, column } = pair;

                /**
                 * "SELECT" filter
                 */
                if (filter.value.type === "SELECT" && filter.value.choices.length > 0) {
                    const choices = [...filter.value.choices];

                    choices.sort(sortStringChoices);

                    const query = choices
                        .map((choice: string) => {
                            if (pair.column.originalType === "User") {
                                const match = choice.match(/^.*<(.+)>.*$/);

                                if (!match) {
                                    return null;
                                }

                                return `[${column.name}]=ToUser('${match[1]!.trim()}')`;
                            }

                            return `[${column.name}]='${choice}'`;
                        })
                        .filter(Boolean)
                        .join(" or ");

                    if (query !== "") {
                        values.push("(" + query + ")");
                    }
                }

                /**
                 * "NUMERIC" filter
                 */
                if (filter.value.type === "NUMERIC" && filter.value.choices !== null) {
                    const { choices } = filter.value;

                    const query = [
                        `ToNumber(ToText([${column.name}]))>=${choices.min}`,
                        `ToNumber(ToText([${column.name}]))<=${choices.max}`,
                    ].join(" and ");

                    values.push("(" + query + ")");
                }

                /**
                 * "DATE" filter
                 */
                if (filter.value.type === "DATE" && filter.value.choices !== null) {
                    const { choices } = filter.value;

                    const dateColumn = `ToDate([${column.name}])`;

                    if (
                        choices.dateType !== "BETWEEN" && //
                        choices.dateType !== "NOT_BETWEEN"
                    ) {
                        /**
                         * Current day
                         */
                        if (choices.periodFrom === "CURRENT_DAY") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `${dateColumn}${symbol}Today()`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * This week
                         */
                        if (choices.periodFrom === "THIS_WEEK") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfWeek(${dateColumn})${symbol}FirstDayOfWeek(Today())`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * This month
                         */
                        if (choices.periodFrom === "THIS_MONTH") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfMonth(${dateColumn})${symbol}FirstDayOfMonth(Today())`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * This quarter
                         */
                        if (choices.periodFrom === "THIS_QUARTER") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfQuarter(${dateColumn})${symbol}FirstDayOfQuarter(Today())`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * This year
                         */
                        if (choices.periodFrom === "THIS_YEAR") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfYear(${dateColumn})${symbol}FirstDayOfYear(Today())`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * Last week
                         */
                        if (choices.periodFrom === "LAST_WEEK") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfWeek(${dateColumn})${symbol}FirstDayOfWeek(Today()-1w)`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * Last month
                         */
                        if (choices.periodFrom === "LAST_MONTH") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfMonth(${dateColumn})${symbol}FirstDayOfMonth(AdjustMonth(Today(), -1))`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * Last quarter
                         */
                        if (choices.periodFrom === "LAST_QUARTER") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfQuarter(${dateColumn})${symbol}FirstDayOfQuarter(AdjustMonth(Today(), -3))`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * Last year
                         */
                        if (choices.periodFrom === "LAST_YEAR") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfYear(${dateColumn})${symbol}FirstDayOfYear(AdjustYear(Today(), -1))`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * Next week
                         */
                        if (choices.periodFrom === "NEXT_WEEK") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfWeek(${dateColumn})${symbol}FirstDayOfWeek(Today()+1w)`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * Next month
                         */
                        if (choices.periodFrom === "NEXT_MONTH") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfMonth(${dateColumn})${symbol}FirstDayOfMonth(AdjustMonth(Today(), 1))`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * Next quarter
                         */
                        if (choices.periodFrom === "NEXT_QUARTER") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfQuarter(${dateColumn})${symbol}FirstDayOfQuarter(AdjustMonth(Today(), 3))`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * Next year
                         */
                        if (choices.periodFrom === "NEXT_YEAR") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `FirstDayOfYear(${dateColumn})${symbol}FirstDayOfYear(AdjustYear(Today(), 1))`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * Days in future
                         */
                        if (choices.periodFrom === "DAYS_IN_FUTURE" && typeof choices.valueFrom === "number") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `${dateColumn}${symbol}Today()+${choices.valueFrom}d`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * Days past
                         */
                        if (choices.periodFrom === "DAYS_PAST" && typeof choices.valueFrom === "number") {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `${dateColumn}${symbol}Today()-${choices.valueFrom}d`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }

                        /**
                         * The date
                         */
                        if (choices.periodFrom === "THE_DATE" && choices.valueFrom instanceof Date) {
                            for (const key of SYMBOLS_KEYS) {
                                if (choices.dateType === key) {
                                    const symbol = SYMBOLS[key];

                                    const query = `${dateColumn}${symbol}#${format(choices.valueFrom, "yyyy-MM-dd")}#`;

                                    values.push("(" + query + ")");

                                    break;
                                }
                            }
                        }
                    } else {
                        const periods: {
                            from: string | null;
                            to: string | null;
                        } = {
                            from: null,
                            to: null,
                        };

                        /**
                         * Current day "From"
                         */
                        if (choices.periodFrom === "CURRENT_DAY") {
                            periods.from = "Today()";
                        }

                        /**
                         * Days in future "From"
                         */
                        if (choices.periodFrom === "DAYS_IN_FUTURE" && typeof choices.valueFrom === "number") {
                            periods.from = `Today()+${choices.valueFrom}d`;
                        }

                        /**
                         * Days past "From"
                         */
                        if (choices.periodFrom === "DAYS_PAST" && typeof choices.valueFrom === "number") {
                            periods.from = `Today()-${choices.valueFrom}d`;
                        }

                        /**
                         * The date "From"
                         */
                        if (choices.periodFrom === "THE_DATE" && choices.valueFrom instanceof Date) {
                            periods.from = `#${format(choices.valueFrom, "yyyy-MM-dd")}#`;
                        }

                        /**
                         * Current day "To"
                         */
                        if (choices.periodTo === "CURRENT_DAY") {
                            periods.to = "Today()";
                        }

                        /**
                         * Days in future "To"
                         */
                        if (choices.periodTo === "DAYS_IN_FUTURE" && typeof choices.valueTo === "number") {
                            periods.to = `Today()+${choices.valueTo}d`;
                        }

                        /**
                         * Days past "To"
                         */
                        if (choices.periodTo === "DAYS_PAST" && typeof choices.valueTo === "number") {
                            periods.to = `Today()-${choices.valueTo}d`;
                        }

                        /**
                         * The date "To"
                         */
                        if (choices.periodTo === "THE_DATE" && choices.valueTo instanceof Date) {
                            periods.to = `#${format(choices.valueTo, "yyyy-MM-dd")}#`;
                        }

                        if (periods.from !== null && periods.to !== null) {
                            if (choices.dateType === "BETWEEN") {
                                const query = `Between(${dateColumn}, ${periods.from}, ${periods.to})`;

                                values.push("(" + query + ")");
                            }

                            if (choices.dateType === "NOT_BETWEEN") {
                                const query = `not Between(${dateColumn}, ${periods.from}, ${periods.to})`;

                                values.push("(" + query + ")");
                            }
                        }
                    }
                }

                /**
                 * "CHECKBOX" filter
                 */
                if (filter.value.type === "CHECKBOX") {
                    const choices = [...filter.value.choices];

                    choices.sort(sortBooleanChoices);

                    const query = choices
                        .map((choice: boolean) => {
                            return `[${column.name}]=${choice}`;
                        })
                        .filter(Boolean)
                        .join(" or ");

                    if (query !== "") {
                        values.push("(" + query + ")");
                    }
                }
            });

            if (values.length > 0) {
                urlParams.append("filter", values.join(" and "));
            }
        }

        return urlParams;
    };
}

interface ReportSourceParams {
    columns: SourceColumn[];
    pairs: ColumnFilterPair[];
}

interface ReportSourceParamsOptions {
    ignoreFilters: boolean;
}

export function getReportSourceParams(
    {
        columns, //
        pairs,
    }: ReportSourceParams,
    {
        ignoreFilters, //
    }: ReportSourceParamsOptions,
): URLSearchParams {
    let urlParams = new URLSearchParams();

    /**
     * Transform columns and filters columns to DB query
     */
    urlParams = getColumnsParams(urlParams)(columns, pairs);

    if (!ignoreFilters) {
        /**
         * Transform filters to DB query
         */
        urlParams = getFiltersParams(urlParams)(pairs);
    }

    return urlParams;
}

type ApiUrlParams = ReportSourceParams & {
    sourceId: string;
};

export function getApiUrl({ columns, pairs, sourceId }: ApiUrlParams): string {
    const base = "https://compass.apexdigital.online/secure/api/v2/90539/C1C0B52B7998429DA8B71CDF3F713293";

    const urlParams = getReportSourceParams(
        {
            columns,
            pairs,
        },
        {
            ignoreFilters: false,
        },
    );

    const source = getReportSource(sourceId);

    return `${base}/${source.table}/select.json?${urlParams.toString()}`;
}
