import React from "react";

import classnames from "classnames/bind";

import styles from "./InputErrorMessage.module.scss";

import type { FieldErrors, Message } from "react-hook-form";
import type { Props as ErrorMessageProps } from "@hookform/error-message";
import { ErrorMessage } from "@hookform/error-message";

const cx = classnames.bind(styles);

type As = undefined | React.ReactElement | React.ComponentType<object | keyof React.ReactElement>;

export function InputErrorMessage<TFieldErrors extends FieldErrors, TAs extends As>(
    props: ErrorMessageProps<TFieldErrors, TAs>,
) {
    return (
        <div className={cx("input-error-message")}>
            <ErrorMessage
                render={({ message }: { message: Message }) => {
                    return message;
                }}
                {...props}
            />
        </div>
    );
}
