import type { RefAttributes } from "react";
import React from "react";

import { BaseTooltip } from "../BaseTooltip";
import type { ITooltip, TooltipRefProps } from "react-tooltip";

const DEFAULT_BORDER = 8;

export function InfoTooltip({
    borders = [], //
    style,
    ...rest
}: Props) {
    return (
        <BaseTooltip
            style={{
                borderTopLeftRadius: borders[0] ?? DEFAULT_BORDER,
                borderTopRightRadius: borders[1] ?? DEFAULT_BORDER,
                borderBottomRightRadius: borders[2] ?? DEFAULT_BORDER,
                borderBottomLeftRadius: borders[3] ?? DEFAULT_BORDER,
                padding: "12px 16px 12px 12px",
                lineHeight: "20px",
                ...style,
            }}
            {...rest}
        />
    );
}

type Props = ITooltip &
    RefAttributes<TooltipRefProps> & {
        borders?: [number, number, number, number] | [];
    };
