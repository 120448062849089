import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { User } from "../../core/entities";

interface AuthState {
    user: User | null;
}

const initialState: AuthState = {
    user: null,
};

export const authSlice = createSlice({
    name: "auth-slice",
    initialState,

    reducers: {
        setUser: (state: Draft<AuthState>, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        },
    },
});

export const {
    setUser, //
} = authSlice.actions;
