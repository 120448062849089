import React from "react";
import type { MenuProps } from "react-select";
import { components } from "react-select";
import { SearchInput } from "../../../input";
import type { AbstractSelectOption, Visibility } from "../../types";

import classnames from "classnames/bind";

import styles from "./MenuMulti.module.scss";

const cx = classnames.bind(styles);

export function MenuMulti<T extends AbstractSelectOption<unknown>>({
    visibility, //
    onVisibilityChange,
    isSearchable,
    maxOptionsToSelect,
    children,
    ...props
}: Props<T, true>) {
    const selectedOptionsCount = props.getValue().length;

    return (
        <components.Menu {...props}>
            {isSearchable && props.options.length > 0 && (
                <div className={cx("menu-header")}>
                    <div className={cx("input-wrapper")}>
                        <SearchInput
                            value={props.selectProps.inputValue}
                            onChange={(value: string) => {
                                props.selectProps.onInputChange(value, {
                                    prevInputValue: "",
                                    action: "input-change",
                                });
                            }}
                            onMouseDown={(event: React.MouseEvent<HTMLInputElement>) => {
                                event.stopPropagation();
                            }}
                            onTouchEnd={(event: React.TouchEvent<HTMLInputElement>) => {
                                event.stopPropagation();
                            }}
                            placeholder="Search"
                        />
                    </div>
                    <div className={cx("menu-subheader")}>
                        <button
                            type="button"
                            className={cx("visibility-button")}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.stopPropagation();

                                onVisibilityChange(visibility === "all" ? "selected" : "all");
                            }}
                        >
                            {visibility === "all" ? "Show only selected" : "Show all"}
                        </button>
                        {typeof maxOptionsToSelect === "number" && (
                            <div
                                className={cx("selected-options-counter", {
                                    full: selectedOptionsCount === maxOptionsToSelect,
                                })}
                            >
                                {selectedOptionsCount} / {maxOptionsToSelect}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {children}
        </components.Menu>
    );
}

type Props<T extends AbstractSelectOption<unknown>, IsMulti extends boolean> = MenuProps<T, IsMulti> & {
    visibility: Visibility;
    onVisibilityChange: (value: Visibility) => void;
    isSearchable: boolean;
    maxOptionsToSelect?: number;
};
