import React from "react";
import { useFindAllReportsQuery, useFindAllUsersQuery } from "src/store/services";
import { DotSpinner } from "src/view/components/basic/spinners";

import classnames from "classnames/bind";

import styles from "./Body.module.scss";
import type { RootState } from "src/store/store";
import { useAppSelector } from "src/store/store";
import { ReportBuilder } from "../../common/ReportBuilder";

const cx = classnames.bind(styles);

export function Body() {
    const { isLoading: isReportsLoading, isError: isReportsError } = useFindAllReportsQuery();
    const { isLoading: isUsersLoading, isError: isUsersError } = useFindAllUsersQuery();

    const { report } = useAppSelector((state: RootState) => state.reports);

    const isLoading = isReportsLoading || isUsersLoading;
    const isError = isReportsError || isUsersError;

    if (isLoading) {
        return (
            <div className={cx("loading")}>
                <DotSpinner />
            </div>
        );
    }

    if (isError) {
        return <div className={cx("error")}>Server error</div>;
    }

    if (report === null) {
        return <div className={cx("no-report-selected")}>Please select a report</div>;
    }

    return <ReportBuilder />;
}
