import React from "react";

import classnames from "classnames/bind";

import { DotSpinner } from "../../../basic/spinners";
import { Popup } from "../Popup";

import styles from "./SidePopup.module.scss";

const cx = classnames.bind(styles);

export function SidePopup({
    onClose, //
    isLoading,
    children,
}: Props) {
    return (
        <Popup onClose={onClose}>
            <div className={cx("side-popup")}>
                {isLoading === true ? (
                    <div>
                        <DotSpinner />
                    </div>
                ) : (
                    <div className={cx("popup")}>{children}</div>
                )}
            </div>
        </Popup>
    );
}

interface Props extends ChildrenProps {
    isLoading?: boolean;
    onClose: () => void;
}
