import React, { useEffect, useState } from "react";
import { ReportBuilderHeader } from "../ReportBuilderHeader";

import classnames from "classnames/bind";

import styles from "./ReportBuilder.module.scss";
import { ReportBuilderTable } from "../ReportBuilderTable";

const cx = classnames.bind(styles);

export function ReportBuilder() {
    const [searchValue, setSearchValue] = useState("");
    const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 250);

        return () => {
            clearTimeout(timer);
        };
    }, [searchValue]);

    return (
        <div className={cx("report-builder")}>
            <ReportBuilderHeader
                searchValue={searchValue}
                onSearchChange={(value: string) => {
                    setSearchValue(value);
                }}
            />
            <ReportBuilderTable searchValue={debouncedSearchValue} />
        </div>
    );
}
