import type { FormEvent } from "react";
import React, { useRef, useState } from "react";

import classnames from "classnames/bind";

import styles from "./ReportBasicForm.module.scss";
import type { FieldErrors, ValidateResult } from "react-hook-form";
import { useController, useFormContext } from "react-hook-form";
import { BasicInput } from "../../basic/inputs/input";
import { InputErrorMessage } from "../../mix";
import TabRadio from "../../basic/inputs/radio/TabRadio";
import { ButtonWrapper } from "src/view/components/mix/ButtonWrapper/ButtonWrapper.com";
import type { Report } from "../../../../core/entities";
import { useFindAllReportsQuery } from "src/store/services";
import type { ReportFormInput, ReportFormOutput } from "src/validation";
import { reportFormSchema } from "src/validation";
import { InfoTooltip } from "../../tooltips";
import { ReportType } from "src/core/enums";
import { BasicButton, GhostButton, HiddenFormButton } from "../../basic/buttons";
import { BasicSelect } from "../../basic/inputs/select";

const cx = classnames.bind(styles);

const TOOLTIP_ID = "source-tooltip-id";

const reportTypes = [
    {
        value: ReportType.PRIVATE,
        label: "Private",
    },
    {
        value: ReportType.PUBLIC,
        label: "Public",
    },
];

interface IOptions {
    label: string;
    value: string;
}

const options: IOptions[] = [
    { label: "Sites", value: "Site" },
    { label: "Projects", value: "Project" },
    { label: "Travel Requests", value: "Travel Request" },
    { label: "Tickets", value: "Ticket" },
    { label: "Tasks", value: "Task" },
];

export type ReportBasicFormOutput = Pick<ReportFormOutput, "name" | "sourceId" | "type">;

export function ReportBasicForm({
    mode, //
    onSubmit,
    onCancel,
}: Props) {
    const [isSubmitting, setSubmitting] = useState(false);

    const formButtonRef = useRef<HTMLButtonElement>(null);

    const { data: allReports } = useFindAllReportsQuery();

    const {
        control, //
        handleSubmit,
        resetField,
        setValue,
        formState: { errors, defaultValues },
    } = useFormContext<ReportFormInput, unknown, ReportBasicFormOutput>();

    const {
        field: name,
        fieldState: { invalid: isNameInvalid },
    } = useController({
        name: "name",
        control,
        rules: {
            onBlur: () => {
                setValue("name", name.value.trim());
            },
            validate: (value: string): ValidateResult => {
                const parsed = reportFormSchema.name.safeParse(value);

                if (!parsed.success) {
                    return parsed.error.issues[0]?.message ?? "Invalid value";
                }

                /**
                 * Skip check duplicates for current report if edit
                 */
                if (mode === "edit") {
                    if (defaultValues?.name === parsed.data) {
                        return;
                    }
                }

                const isDuplicateName = allReports?.some((r: Report) => r.name === parsed.data);

                if (isDuplicateName) {
                    return "Such report already exists, please use another name";
                }

                return;
            },
        },
    });

    const {
        field: sourceId,
        fieldState: { invalid: isSourceIdInvalid },
    } = useController({
        name: "sourceId",
        control,
        rules: {
            validate: (value: string | null) => {
                const parsed = reportFormSchema.sourceId.safeParse(value);

                if (!parsed.success) {
                    return parsed.error.issues[0]?.message ?? "Invalid value";
                }

                return;
            },
        },
    });

    const {
        field: type,
        fieldState: { invalid: isTypeInvalid },
    } = useController({
        name: "type",
        control,
        rules: {
            validate: (value: ReportType | null) => {
                const parsed = reportFormSchema.type.safeParse(value);

                if (!parsed.success) {
                    return parsed.error.issues[0]?.message ?? "Invalid value";
                }

                return;
            },
        },
    });

    async function handleSubmitAsync(values: ReportBasicFormOutput) {
        setSubmitting(true);

        try {
            await onSubmit(values);
        } catch {
            //
        }

        setSubmitting(false);
    }

    function onValid(values: ReportBasicFormOutput) {
        void handleSubmitAsync(values);
    }

    function onInvalid(_: FieldErrors<ReportBasicFormOutput>) {
        console.log(_);
    }

    const submitButtonTitle = (() => {
        if (mode === "copy") {
            return "Copy";
        }

        return "Continue";
    })();

    return (
        <div className={cx("report-basic-form")}>
            <form
                onSubmit={(event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault();

                    void handleSubmit(onValid, onInvalid)(event);
                }}
            >
                <div className={cx("name-input")}>
                    <BasicInput
                        value={name.value}
                        placeholder="Report Title"
                        type={isNameInvalid ? "error" : "default"}
                        onChange={name.onChange}
                        onBlur={name.onBlur}
                    />
                    <InputErrorMessage
                        name="name"
                        errors={errors}
                    />
                </div>

                <div className={cx("source-input")}>
                    <div
                        data-tooltip-id={TOOLTIP_ID}
                        data-tooltip-hidden={mode === "create"}
                    >
                        <BasicSelect<IOptions>
                            options={options}
                            value={options.find((opt: IOptions) => opt.value === sourceId.value) ?? null}
                            onChange={(val: IOptions | null) => {
                                if (val?.value && sourceId.value !== val.value) {
                                    resetField("columns");
                                    resetField("filters");
                                }

                                sourceId.onChange(val?.value ?? null);
                            }}
                            isDisabled={mode !== "create"}
                            isSearchable={false}
                            placeholder="Data Source"
                            isError={isSourceIdInvalid}
                        />
                    </div>
                    <InputErrorMessage
                        name="sourceId"
                        errors={errors}
                    />
                </div>

                <div className={cx("type-input")}>
                    <TabRadio
                        options={reportTypes}
                        value={type.value}
                        type={isTypeInvalid ? "error" : "default"}
                        onChange={type.onChange}
                    />
                    <InputErrorMessage
                        name="type"
                        errors={errors}
                    />
                </div>

                <HiddenFormButton ref={formButtonRef} />
            </form>

            <ButtonWrapper shift="right">
                <BasicButton
                    title={submitButtonTitle}
                    style={{ width: "134px" }}
                    isLoading={isSubmitting}
                    onClick={() => {
                        formButtonRef.current?.click();
                    }}
                />
                <GhostButton
                    title="Cancel"
                    style={{ width: "76px", marginLeft: "16px" }}
                    onClick={onCancel}
                />
            </ButtonWrapper>

            <InfoTooltip
                id={TOOLTIP_ID}
                content="You can't change Data Source for the existing report"
                place="bottom"
                borders={[8, 0, 8, 8]}
                offset={6}
            />
        </div>
    );
}

interface Props {
    mode: "create" | "edit" | "copy";
    onSubmit: (values: ReportBasicFormOutput) => Promise<void> | void;
    onCancel: () => void;
}
