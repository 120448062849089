import React from "react";

import classNames from "classnames/bind";

import styles from "./FakeCheckbox.module.scss";
import { CheckboxIcon } from "../../../custom-icons";

const cx = classNames.bind(styles);

export function FakeCheckbox({
    label, //
    checked,
    disabled = false,
}: Props) {
    return (
        <div
            className={cx("fake-checkbox", {
                disabled,
            })}
        >
            <div className={cx("icon-wrapper")}>
                <CheckboxIcon
                    checked={checked}
                    disabled={disabled}
                />
            </div>
            <div className={cx("label")}>{label}</div>
        </div>
    );
}

interface Props {
    label: string;
    checked: boolean | "half-checked";
    disabled?: boolean;
}
