import React, { useCallback, useLayoutEffect } from "react";

import classnames from "classnames/bind";

import styles from "./ConfirmSaveReportPopup.module.scss";
import { DialogPopup } from "../../dialog";
import type { RootState } from "src/store/store";
import { useAppSelector } from "src/store/store";
import { useCreateReportMutation, useUpdateReportMutation } from "src/store/services";
import { Description } from "../../../mix";
import { useNotifications, usePopups } from "src/view/hooks";
import { isErrorWithMessage, isFetchBaseQueryError } from "src/utils/helpers";

const cx = classnames.bind(styles);

const titles = {
    confirm: "Yes",
    cancel: "No",
};

export function ConfirmSaveReportPopup() {
    const { actions: popupsActions } = usePopups();
    const { notify } = useNotifications();

    const [createReport, { error: createReportError }] = useCreateReportMutation();
    const [updateReport, { error: updateReportError }] = useUpdateReportMutation();

    const { confirmSaveReportPopup } = useAppSelector((state: RootState) => state.popups);

    const params = confirmSaveReportPopup.params!;

    useLayoutEffect(() => {
        if (isFetchBaseQueryError(createReportError) && createReportError.status === "FETCH_ERROR") {
            notify.error(createReportError.error);
        }

        if (isErrorWithMessage(createReportError)) {
            notify.error(createReportError.message);
        }
    }, [createReportError, notify]);

    useLayoutEffect(() => {
        if (isFetchBaseQueryError(updateReportError) && updateReportError.status === "FETCH_ERROR") {
            notify.error(updateReportError.error);
        }

        if (isErrorWithMessage(updateReportError)) {
            notify.error(updateReportError.message);
        }
    }, [updateReportError, notify]);

    const handleConfirm = useCallback(async () => {
        if (params.mode === "create") {
            const res = await createReport({
                args: params.args,
            });

            if ("data" in res) {
                popupsActions.createReportColumnsPopup.close();
                popupsActions.createReportFiltersPopup.close();
                popupsActions.confirmSaveReportPopup.close();
            }
        }

        if (params.mode === "edit") {
            const res = await updateReport({
                args: params.args,
            });

            if ("data" in res) {
                popupsActions.editReportColumnsPopup.close();
                popupsActions.editReportFiltersPopup.close();
                popupsActions.confirmSaveReportPopup.close();
            }
        }
    }, [params, createReport, updateReport, popupsActions]);

    const handleClose = useCallback(() => {
        popupsActions.confirmSaveReportPopup.close();
    }, [popupsActions]);

    return (
        <DialogPopup
            titles={titles}
            onConfirm={handleConfirm}
            onCancel={handleClose}
            onClose={handleClose}
        >
            <div className={cx("confirm-save-report-popup")}>
                <Description
                    title="You are going to save edits"
                    description="Are you sure? All changes will be saved in default view."
                />
            </div>
        </DialogPopup>
    );
}
