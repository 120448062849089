import type { Report, User } from "src/core/entities";

export function hasReportPermissionsCheck(report: Report, user: User): boolean {
    if (
        [
            "System Administrator", //
            "IT Opening Leadership",
        ].includes(user.role)
    ) {
        return true;
    }

    return user.email === report.creatorEmail;
}
