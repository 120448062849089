import React from "react";

import classnames from "classnames/bind";

import { BasicPopup } from "../../base";
import { PopupHeader } from "../../../mix";
import { useFindAllReportsQuery } from "src/store/services";
import { ReportBasicForm } from "../../../forms";

import styles from "./CreateReportPopup.module.scss";
import { usePopups } from "src/view/hooks";

const cx = classnames.bind(styles);

export function CreateReportPopup() {
    const { actions: popupsActions } = usePopups();

    const { isLoading: isReportsLoading } = useFindAllReportsQuery();

    function handleSubmit() {
        popupsActions.createReportColumnsPopup.open();
    }

    function handleClose() {
        popupsActions.createReportPopup.close();
    }

    return (
        <BasicPopup
            isLoading={isReportsLoading}
            onClose={handleClose}
        >
            <div className={cx("create-report-popup")}>
                <div className={cx("popup-header")}>
                    <PopupHeader
                        title="Create New Report"
                        onClose={handleClose}
                    />
                </div>

                <ReportBasicForm
                    mode="create"
                    onSubmit={handleSubmit}
                    onCancel={handleClose}
                />
            </div>
        </BasicPopup>
    );
}
