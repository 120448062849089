import React from "react";

import classnames from "classnames/bind";

import styles from "./CreateReportFiltersPopup.module.scss";
import { SidePopup } from "../../base";
import { ReportFiltersForm } from "../../../forms";
import { PopupHeader } from "src/view/components/mix/PopupHeader/PopupHeader.com";
import type { ReportFormOutput } from "src/validation";
import { usePopups } from "src/view/hooks";

const cx = classnames.bind(styles);

export function CreateReportFiltersPopup() {
    const { actions: popupsActions } = usePopups();

    function handleSubmit(values: ReportFormOutput) {
        popupsActions.confirmSaveReportPopup.open({
            mode: "create",
            args: {
                name: values.name,
                sourceId: values.sourceId,
                type: values.type,
                creatorEmail: values.creatorEmail,
                columns: values.columns,
                filters: values.filters,
                API_URL: values.API_URL,
            },
        });
    }

    function handleBack() {
        popupsActions.createReportColumnsPopup.open();
    }

    function handleClose() {
        popupsActions.createReportFiltersPopup.close();
    }

    return (
        <SidePopup onClose={handleClose}>
            <div className={cx("create-report-filters-popup")}>
                <div className={cx("popup-header")}>
                    <PopupHeader
                        title="Filters"
                        description="Apply filters, set the order and select favorites to build report"
                        onClose={handleClose}
                    />
                </div>

                <ReportFiltersForm
                    mode="create"
                    onSubmit={handleSubmit}
                    onBack={handleBack}
                />
            </div>
        </SidePopup>
    );
}
