import styles from "./ButtonWrapper.module.scss";
import classnames from "classnames/bind";
import React from "react";

const cx = classnames.bind(styles);

export function ButtonWrapper({ children, shift }: Props) {
    return <div className={cx("button-wrapper", shift)}>{children}</div>;
}

interface Props extends ChildrenProps {
    shift?: "left" | "right" | "center";
}
