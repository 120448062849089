import type { ButtonHTMLAttributes } from "react";
import React from "react";

import classnames from "classnames/bind";

import styles from "./FiltersButton.module.scss";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter-icon.svg";

const cx = classnames.bind(styles);

export function FiltersButton({ onClick, ...attributes }: Props) {
    return (
        <button
            type="button"
            className={cx("filters-button")}
            onClick={onClick}
            {...attributes}
        >
            <FilterIcon />
        </button>
    );
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    onClick: () => void;
}
