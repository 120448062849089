/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React from "react";
import type {
    ColumnFilterPair,
    ReportFilterCheckboxChoices,
    ReportFilterDateChoices,
    ReportFilterNumericChoices,
    ReportFilterSelectChoices,
    SourceColumnChoice,
} from "src/core/entities";
import { MinMaxInput } from "../../basic/inputs/number";
import { BasicMultiSelect } from "src/view/components/basic/inputs/select";
import { DatePickerExtended } from "src/view/components/basic/inputs/date";
import { formatBooleanView, formatUserView } from "../../../../utils/helpers";

interface MultiSelectOption<T> {
    value: T;
    label: string;
}

const checkboxOptions: MultiSelectOption<boolean>[] = [
    {
        value: true,
        label: formatBooleanView(true),
    },
    {
        value: false,
        label: formatBooleanView(false),
    },
];

const MAX_OPTIONS_TO_SELECT = 15;

function View({
    pair, //
    onSelectChange,
    onNumericChange,
    onDateChange,
    onCheckboxChange,
}: Props) {
    const { column, filter } = pair;

    /**
     * SELECT
     */
    if (filter.value.type === "SELECT") {
        const choices: MultiSelectOption<string>[] = [
            ...(column.choices ?? []).map((choice: SourceColumnChoice) => {
                return choice.value;
            }),
            ...filter.value.choices,
        ].map((value: string) => {
            if (column.originalType === "User") {
                return {
                    label: formatUserView(value),
                    value,
                };
            }

            return {
                label: value,
                value,
            };
        });

        const options = [
            ...new Map(
                choices.map((choice: MultiSelectOption<string>) => {
                    return [choice.value, choice];
                }),
            ).values(),
        ];

        const value = options.filter((choice: MultiSelectOption<string>) => {
            if (filter.value.type === "SELECT") {
                return filter.value.choices.includes(choice.value);
            }

            return [];
        });

        return (
            <BasicMultiSelect
                options={options}
                value={value}
                isSearchable
                showAllCheckbox={options.length <= MAX_OPTIONS_TO_SELECT}
                maxOptionsToSelect={options.length <= MAX_OPTIONS_TO_SELECT ? undefined : MAX_OPTIONS_TO_SELECT}
                placeholder={"Select " + column.name}
                onChange={(val: MultiSelectOption<string>[]) => {
                    onSelectChange(val.map((option: MultiSelectOption<string>) => option.value));
                }}
            />
        );
    }

    /**
     * NUMERIC
     */
    if (filter.value.type === "NUMERIC") {
        return (
            <MinMaxInput
                value={filter.value.choices}
                onChange={onNumericChange}
            />
        );
    }

    /**
     * DATE
     */
    if (filter.value.type === "DATE") {
        return (
            <DatePickerExtended
                value={filter.value.choices}
                placeholder={"Select " + column.name}
                onChange={onDateChange}
            />
        );
    }

    /**
     * CHECKBOX
     */
    if (filter.value.type === "CHECKBOX") {
        const checkboxValue = checkboxOptions.filter((choice: MultiSelectOption<boolean>) => {
            if (filter.value.type === "CHECKBOX") {
                return filter.value.choices.includes(choice.value);
            }

            return [];
        });

        return (
            <BasicMultiSelect
                options={checkboxOptions}
                value={checkboxValue}
                isSearchable={false}
                showAllCheckbox={false}
                placeholder={"Select " + column.name}
                onChange={(val: MultiSelectOption<boolean>[]) => {
                    onCheckboxChange(val.map((option: MultiSelectOption<boolean>) => option.value));
                }}
            />
        );
    }

    throw new Error("Filter type is not defined");
}

interface Props {
    pair: ColumnFilterPair;
    onSelectChange: (choices: ReportFilterSelectChoices) => void;
    onNumericChange: (choices: ReportFilterNumericChoices) => void;
    onDateChange: (choices: ReportFilterDateChoices) => void;
    onCheckboxChange: (choices: ReportFilterCheckboxChoices) => void;
}

export const InputSelector = React.memo(View);
