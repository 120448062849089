import { BaseTooltip } from "../BaseTooltip";

import classnames from "classnames/bind";

import styles from "./ReportDetailsTooltip.module.scss";
import { format } from "date-fns";
import React from "react";

const cx = classnames.bind(styles);

export function ReportDetailsTooltip({
    tooltipId, //
    details,
}: Props) {
    return (
        <BaseTooltip
            id={tooltipId}
            place="right-end"
        >
            <div className={cx("report-details-tooltip")}>
                <div className={cx("header")}>{details.reportName}</div>
                <div className={cx("tooltip-body")}>
                    {[
                        `Report type: ${details.reportType}`,
                        `Creator: ${details.creator ?? "Unknown"}`,
                        `Department: ${details.department ?? "Unknown"}`,
                        `Date Created: ${format(details.dateCreated, "dd.MM.yyyy")}`,
                        `Date Updated: ${format(details.dateUpdated, "dd.MM.yyyy")}`,
                    ].map((line: string) => {
                        return (
                            <span
                                key={line}
                                className={cx("tooltip-line")}
                            >
                                <span className={cx("dot-line")}>•</span> {line}
                            </span>
                        );
                    })}
                </div>
            </div>
        </BaseTooltip>
    );
}

interface ReportDetails {
    reportName: string;
    reportType: string;
    creator: string | null;
    department: string | null;
    dateCreated: Date;
    dateUpdated: Date;
}

interface Props {
    tooltipId: string;
    details: ReportDetails;
}
