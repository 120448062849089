import { useLayoutEffect, useMemo } from "react";
import { useDataTable } from "./useDataTable";
import type { RootState } from "../../store/store";
import { useAppSelector } from "../../store/store";
import invariant from "tiny-invariant";
import { exportExel, exportPDF } from "../../utils/export";
import type { Column } from "@ag-grid-community/core";
import type { ColumnFilterPair, ReportFilter, SourceColumn, SourceItem } from "src/core/entities";
import { useLazyFindSourceColumnsQuery } from "../../store/services";
import { getReportSource } from "../../utils/common";
import { formatBooleanView, formatDateFilterChoicesView, formatMinMaxNumericView } from "../../utils/helpers";

const DEFAULT_COLUMNS: SourceColumn[] = [];
const DEFAULT_PAIRS: ColumnFilterPair[] = [];

function createHook() {
    return function useExports() {
        const { services: table } = useDataTable();

        const { user } = useAppSelector((state: RootState) => state.auth);
        const { draft } = useAppSelector((state: RootState) => state.reports);

        invariant(user, "User should be specified");

        const [fetchSourceColumns, { data: columnsList = DEFAULT_COLUMNS }] = useLazyFindSourceColumnsQuery();

        useLayoutEffect(() => {
            if (!draft || draft.filters.length === 0) return;

            const source = getReportSource(draft.sourceId);

            const controller = fetchSourceColumns(source, true);

            return () => {
                controller.abort();
            };
        }, [fetchSourceColumns, draft]);

        const services = useMemo(() => {
            function getPairs(): ColumnFilterPair[] | null {
                if (!draft) return null;

                if (draft.filters.length === 0) return DEFAULT_PAIRS;

                return draft.filters
                    .map((filter: ReportFilter) => {
                        const foundColumn = columnsList.find((column: SourceColumn) => column.id === filter.id);

                        if (foundColumn) {
                            return {
                                column: foundColumn,
                                filter,
                            };
                        }

                        return null;
                    })
                    .filter(Boolean);
            }

            function getAppliedFilters(pairs: ColumnFilterPair[]) {
                const sorted = [...pairs];

                sorted.sort((a: ColumnFilterPair, b: ColumnFilterPair) => {
                    return a.filter.position - b.filter.position;
                });

                return sorted
                    .map((pair: ColumnFilterPair) => {
                        const { filter } = pair;

                        const values: string[] = [];

                        if (filter.value.type === "DATE" && filter.value.choices !== null) {
                            values.push(formatDateFilterChoicesView(filter.value.choices));
                        }

                        if (filter.value.type === "SELECT") {
                            filter.value.choices.forEach((value: string) => {
                                values.push(value);
                            });
                        }

                        if (filter.value.type === "NUMERIC" && filter.value.choices !== null) {
                            const { min, max } = filter.value.choices;

                            values.push(formatMinMaxNumericView(min, max));
                        }

                        if (filter.value.type === "CHECKBOX") {
                            filter.value.choices.forEach((value: boolean) => {
                                values.push(formatBooleanView(value));
                            });
                        }

                        return {
                            name: pair.column.name,
                            values,
                        };
                    })
                    .filter(({ values }: { values: string[]; name: string }) => {
                        return values.length > 0;
                    });
            }

            function getColumns(columns: Column<SourceItem<unknown>>[]) {
                return columns
                    .map((col: Column<SourceItem<unknown>>) => {
                        const name = col.getColDef().field;

                        if (name !== undefined) {
                            return {
                                name,
                            };
                        }

                        return null;
                    })
                    .filter(Boolean);
            }

            return {
                exportToExcel: () => {
                    const columns = table.getColumns();
                    const rows = table.getRows({
                        formatNumbers: false,
                    });

                    const pinnedBottomRows = table.getPinnedBottomRows();

                    const pairs = getPairs();

                    if (draft && columns.length > 0 && pairs !== null) {
                        void exportExel({
                            name: draft.name,
                            user,
                            filters: getAppliedFilters(pairs),
                            columns: getColumns(columns),
                            rows,
                            bottomRows: pinnedBottomRows,
                        });
                    }
                },

                exportToPdf: () => {
                    const columns = table.getColumns();
                    const rows = table.getRows({
                        formatNumbers: true,
                    });

                    const pinnedBottomRows = table.getPinnedBottomRows();

                    const pairs = getPairs();

                    if (draft && columns.length > 0 && pairs !== null) {
                        exportPDF({
                            name: draft.name,
                            user,
                            filters: getAppliedFilters(pairs),
                            columns: getColumns(columns),
                            rows,
                            bottomRows: pinnedBottomRows,
                        });
                    }
                },
            };
        }, [draft, columnsList, table, user]);

        return {
            services,
        };
    };
}

export const useExports = createHook();
