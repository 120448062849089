import type { SourceColumn, ReportColumn } from "../../core/entities";

export const reportColumnMapper = {
    toValue: (sourceColumn: SourceColumn): ReportColumn => {
        return {
            id: sourceColumn.id,
            position: Number.MAX_SAFE_INTEGER,
        };
    },
};
