import React from "react";
import { NoAccess } from "../../mix";
import { useAuth } from "../../../hooks";
import { DotSpinner } from "../../basic/spinners";

import classnames from "classnames/bind";

import styles from "./Authorization.module.scss";

const cx = classnames.bind(styles);

export function Authorization({
    children, //
}: ChildrenProps) {
    const { isAuthorization, permissionAllowed } = useAuth();

    if (isAuthorization) {
        return (
            <div className={cx("authorization")}>
                <DotSpinner />
            </div>
        );
    }

    if (!permissionAllowed) {
        return <NoAccess />;
    }

    return (
        <>
            {}
            {children}
        </>
    );
}
