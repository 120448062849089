import React, { useState } from "react";

import classnames from "classnames/bind";

import type { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from "react-datepicker";
import ReactPicker from "react-datepicker";

import { ReactComponent as CalendarIcon } from "src/assets/icons/calendar-icon.svg";
import { ReactComponent as CaretLeft } from "src/assets/icons/caret-left-icon.svg";
import { ReactComponent as CaretRight } from "src/assets/icons/caret-right-icon.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";

import { format } from "date-fns";

import "./DatePickerBase.global.scss";
import styles from "./DatePickerBase.module.scss";

const cx = classnames.bind(styles);

function datesAreEqual(start: Date, end: Date) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    return date1.getTime() === date2.getTime();
}

const CustomInput = React.forwardRef(
    (
        { onClear, ...attributes }: React.InputHTMLAttributes<HTMLInputElement> & { onClear: () => void }, //
        ref: React.ForwardedRef<HTMLInputElement>,
    ) => {
        return (
            <div className={cx("input")}>
                <label>
                    <span className={cx("icon")}>
                        <CalendarIcon color="#4A7A8A" />
                    </span>
                    <input
                        ref={ref}
                        type="text"
                        {...attributes}
                    />
                </label>
                <button
                    type="button"
                    className={cx("clear-icon", {
                        hidden: !attributes.value,
                    })}
                    onClick={onClear}
                >
                    <CloseIcon />
                </button>
            </div>
        );
    },
);

CustomInput.displayName = "CustomInput";

function Header({ date, onNextMonth, onPreviousMonth }: HeaderProps) {
    return (
        <div className={cx("header")}>
            <button
                type="button"
                className={cx("month-control-icon", "month-control-icon-left")}
                onClick={onPreviousMonth}
            >
                <CaretLeft />
            </button>
            <button
                type="button"
                className={cx("month-control-icon", "month-control-icon-right")}
                onClick={onNextMonth}
            >
                <CaretRight />
            </button>
            <span className={cx("current-date")}>{format(date, "MMMM yyyy")}</span>
        </div>
    );
}

interface HeaderProps {
    date: Date;
    onNextMonth: () => void;
    onPreviousMonth: () => void;
}

export function DatePickerBase<WithRange extends boolean>({
    onClear, //
    isError,
    inline,
    excludeDateIntervals,
    ...attributes
}: Props<WithRange>) {
    const [isOpen, setOpen] = useState(false);

    function dayClassName(date: Date) {
        if (!excludeDateIntervals || excludeDateIntervals.length === 0) return "";

        const classes = new Set<string>();

        excludeDateIntervals.forEach((interval: { start: Date; end: Date }) => {
            if (datesAreEqual(date, interval.start)) {
                classes.add("react-datepicker__day--excluded-start");
            }

            if (datesAreEqual(date, interval.end)) {
                classes.add("react-datepicker__day--excluded-end");
            }
        });

        return Array.from(classes).join(" ");
    }

    return (
        <div
            className={cx("date-picker-base", {
                "is-open": isOpen,
            })}
        >
            <ReactPicker
                renderCustomHeader={(params: ReactDatePickerCustomHeaderProps) => {
                    return (
                        <Header
                            date={params.monthDate}
                            onNextMonth={() => {
                                params.increaseMonth();
                            }}
                            onPreviousMonth={() => {
                                params.decreaseMonth();
                            }}
                        />
                    );
                }}
                customInput={<CustomInput onClear={onClear} />}
                excludeDateIntervals={excludeDateIntervals}
                dateFormat="dd MMM yyyy"
                calendarStartDay={1}
                formatWeekDay={(day: string) => {
                    return day.slice(0, 3);
                }}
                placeholderText="Select Date"
                showPopperArrow={false}
                className={cx({
                    error: isError,
                })}
                calendarClassName={cx("calendar", {
                    inline,
                })}
                dayClassName={dayClassName}
                onCalendarOpen={() => {
                    setOpen(true);
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    event.preventDefault();
                }}
                {...attributes}
            />
        </div>
    );
}

interface Props<WithRange extends boolean> extends ReactDatePickerProps<never, WithRange> {
    isError?: boolean;
    onClear: () => void;
}
