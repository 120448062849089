import React from "react";
import { DraftFiltersPopup } from "../../popups/draft";
import { FormContextProvider } from "../FormContextProvider";
import { FormProvider, useForm } from "react-hook-form";
import type { ReportFormInput, ReportFormOutput } from "../../../../validation";
import type { RootState } from "../../../../store/store";
import { useAppSelector } from "../../../../store/store";
import invariant from "tiny-invariant";

function Provider({ children }: ChildrenProps) {
    const { draft } = useAppSelector((state: RootState) => state.reports);

    invariant(draft, "Draft should be specified");

    const methods = useForm<ReportFormInput, unknown, ReportFormOutput>({
        defaultValues: {
            name: draft.name,
            sourceId: draft.sourceId,
            type: draft.type,
            creatorEmail: draft.creatorEmail,
            columns: draft.columns,
            filters: draft.filters,
            API_URL: draft.API_URL,
        },
        mode: "onChange",
    });

    return <FormProvider {...methods}>{children}</FormProvider>;
}

export function DraftFormGroup({
    isDraftFilters, //
}: Props) {
    let popup: React.ReactNode;

    if (isDraftFilters) {
        popup = <DraftFiltersPopup />;
    }

    return <FormContextProvider Provider={Provider}>{popup}</FormContextProvider>;
}

interface Props {
    isDraftFilters: boolean;
}
