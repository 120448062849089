import React, { useCallback } from "react";

import classnames from "classnames/bind";

import styles from "./NotReportCreatorWarningPopup.module.scss";
import { BasicPopup } from "../../base";
import { Description, PopupHeaderEmpty } from "../../../mix";
import { usePopups } from "../../../../hooks";

const cx = classnames.bind(styles);

export function NotReportCreatorWarningPopup() {
    const { actions: popupsActions } = usePopups();

    const handleClose = useCallback(() => {
        popupsActions.notReportCreatorWarningPopup.close();
    }, [popupsActions]);

    return (
        <BasicPopup onClose={handleClose}>
            <div className={cx("not-report-creator-warning-popup")}>
                <PopupHeaderEmpty onClose={handleClose} />

                <div className={cx("body")}>
                    <Description
                        title="Order of columns will be not saved in the report settings as you are not the report creator"
                        description="You can copy current report and set required ordering on the copy. That will not affect the scheduled reports."
                    />
                </div>
            </div>
        </BasicPopup>
    );
}
