import type { BaseQueryApi, BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Endpoints } from "../../core/constants";
import type { EndpointBuilder, MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { z } from "zod";
import type { Report, ReportColumn, ReportFilter } from "../../core/entities";
import { catchError, from, lastValueFrom, map, switchMap } from "rxjs";
import type { AxiosResponse } from "axios";
import axios from "axios";
import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import type { Draft } from "@reduxjs/toolkit";
import type { DeleteDbResponse, UpsertDbResponse } from "../../core/types";
import { ReportType } from "../../core/enums";
import { reportColumnSchema, reportFilterSchema } from "../../validation";
import { format } from "date-fns";

function stringifyFilters(filters: ReportFilter[]) {
    return JSON.stringify(
        filters.map((filter: ReportFilter) => {
            if (filter.value.type === "DATE" && filter.value.choices !== null) {
                const { choices } = filter.value;

                return {
                    ...filter,
                    value: {
                        ...filter.value,
                        choices: {
                            ...choices,
                            valueFrom:
                                choices.valueFrom instanceof Date
                                    ? format(choices.valueFrom, "yyyy-MM-dd") + "T00:00:00"
                                    : choices.valueFrom,
                            valueTo:
                                choices.valueTo instanceof Date
                                    ? format(choices.valueTo, "yyyy-MM-dd") + "T00:00:00"
                                    : choices.valueTo,
                        },
                    },
                };
            }

            return filter;
        }),
    );
}

const reportSchema = z.object({
    "@row.id": z.number(),
    "sourceId": z.string(),
    "name": z.string(),
    "type": z.nativeEnum(ReportType),
    "Report Creator Email": z.string().email(),
    "columns": z
        .string()
        .nullable()
        .transform<ReportColumn[]>((val: string | null) => {
            if (val === null) {
                return [];
            }

            try {
                const res = JSON.parse(val);

                if (Array.isArray(res)) {
                    return res
                        .map((item: unknown) => {
                            const parsed = reportColumnSchema.safeParse(item);

                            if (!parsed.success) {
                                return null;
                            }

                            return parsed.data;
                        })
                        .filter(Boolean);
                }
            } catch {
                //
            }

            return [];
        }),

    // TODO replace to "filters" later
    "filters1": z
        .string()
        .nullable()
        .transform<ReportFilter[]>((val: string | null) => {
            if (val === null) {
                return [];
            }

            try {
                const res = JSON.parse(val);

                if (Array.isArray(res)) {
                    return res
                        .map((item: unknown) => {
                            const parsed = reportFilterSchema.safeParse(item);

                            if (!parsed.success) {
                                return null;
                            }

                            return parsed.data;
                        })
                        .filter(Boolean);
                }
            } catch {
                //
            }

            return [];
        }),
    "Date Created": z.coerce.date(),
    "Date Modified": z.coerce.date(),
    "API URL": z.string().nullable(),
});

type ReportSchemaInput = z.input<typeof reportSchema>;
type ReportSchemaOutput = z.output<typeof reportSchema>;

export interface ReportMutationOptions {
    silent?: boolean;
}

export interface CreateReportParams {
    args: Omit<Report, "id" | "dateCreated" | "dateModified">;
    options?: ReportMutationOptions;
}

export interface UpdateReportParams {
    args: Omit<Report, "dateCreated" | "dateModified" | "creatorEmail" | "sourceId">;
    options?: ReportMutationOptions;
}

interface CopyReportParams {
    args: Pick<Report, "id" | "name" | "type" | "creatorEmail">;
    options?: ReportMutationOptions;
}

interface UpsertRequestParams {
    "name": string;
    "type": ReportType;
    "columns": string | null;
    // TODO replace to "filters" later
    "filters1": string | null;
    "API URL": string | null;
}

interface CreateReportRequestParams extends UpsertRequestParams {
    "sourceId": string;
    "Report Creator Email": string;
}

interface UpdateReportRequestParams extends UpsertRequestParams {
    "@row.id": number;
}

/**
 * Get url params with required columns
 */
function getReportURLSearchParams(): URLSearchParams {
    const urlParams = new URLSearchParams();

    urlParams.append("column", "sourceId");
    urlParams.append("column", "name");
    urlParams.append("column", "type");
    urlParams.append("column", "Report Creator Email");
    urlParams.append("column", "columns");

    // TODO replace to "filters" later
    urlParams.append("column", "filters1");

    urlParams.append("column", "Date Created");
    urlParams.append("column", "Date Modified");
    urlParams.append("column", "API URL");

    return urlParams;
}

/**
 * Validate and transform raw report
 */
function parseRawReport(raw: ReportSchemaInput): ReportSchemaOutput | null {
    const parsed = reportSchema.safeParse(raw);

    if (!parsed.success) {
        console.error("Report api validation error", parsed.error.message);

        return null;
    }

    return parsed.data;
}

/**
 * Convert valid raw report to Report type
 * @see Report
 */
function mapRawReport(raw: ReportSchemaOutput): Report {
    return {
        id: raw["@row.id"],
        sourceId: raw.sourceId,
        name: raw.name,
        type: raw.type,
        creatorEmail: raw["Report Creator Email"],
        columns: raw.columns,
        filters: raw.filters1,
        dateCreated: raw["Date Created"],
        dateModified: raw["Date Modified"],
        API_URL: raw["API URL"],
    };
}

/**
 * Transforms raw response while create/update/copy report
 * @returns report id
 */
function transformUpsertDbResponse(response: UpsertDbResponse): number {
    if (response[0].status === 400) {
        throw new Error(response[0].errors[0].message);
    }

    return response[0].id;
}

export const reportsApi = createApi({
    reducerPath: "reports-api",
    baseQuery: fetchBaseQuery({
        baseUrl: Endpoints.API_CUSTOM_REPORT,
    }),
    endpoints: (builder: EndpointBuilder<BaseQueryFn, string, "reports-api">) => ({
        /**
         * Get full list of reports
         */
        findAll: builder.query<Report[], void>({
            query: () => {
                const urlParams = getReportURLSearchParams();

                return "select.json?" + urlParams.toString();
            },
            transformResponse: (raw: ReportSchemaInput[]): Report[] => {
                return raw.map(parseRawReport).filter(Boolean).map(mapRawReport);
            },
        }),

        /**
         * Find one report by id
         */
        findById: builder.query<Report, number>({
            query(id: number) {
                const urlParams = getReportURLSearchParams();

                urlParams.append("id", id.toString());

                return "retrieve.json?" + urlParams.toString();
            },
            transformResponse: (raw: ReportSchemaInput[], _: unknown, id: number): Report => {
                if (!raw[0]) {
                    throw new Error(`Report with id <${id}> not found`);
                }

                const parsed = parseRawReport(raw[0]);

                if (parsed === null) {
                    throw new Error(`Report with id <${id}> is not valid`);
                }

                return mapRawReport(parsed);
            },
        }),

        /**
         * Create report
         */
        create: builder.mutation<Report, CreateReportParams>({
            queryFn: async (
                params: CreateReportParams,
                api: BaseQueryApi,
            ): Promise<QueryReturnValue<Report, Error, object>> => {
                const { args } = params;
                const { dispatch, signal } = api;

                const url = Endpoints.API_CUSTOM_REPORT + "/upsert.json";

                const body: CreateReportRequestParams = {
                    "sourceId": args.sourceId,
                    "name": args.name,
                    "type": args.type,
                    "Report Creator Email": args.creatorEmail,
                    "columns": JSON.stringify(args.columns),
                    "filters1": stringifyFilters(args.filters),
                    "API URL": args.API_URL,
                };

                const res = await lastValueFrom(
                    from(
                        axios.post(url, body, {
                            signal,
                        }),
                    )
                        .pipe(
                            map((response: AxiosResponse<UpsertDbResponse>) => {
                                const { data } = response;

                                return transformUpsertDbResponse(data);
                            }),
                            switchMap((id: number) => {
                                return from(dispatch(reportsApi.endpoints.findById.initiate(id)).unwrap());
                            }),
                        )
                        .pipe(
                            catchError((error: unknown) => {
                                if (error instanceof Error) {
                                    throw new Error(`Can't create report: ${error.message}`);
                                }

                                throw new Error("Can't create report: Unexpected error");
                            }),
                        ),
                );

                return {
                    data: res,
                };
            },
            onQueryStarted: async (
                _: CreateReportParams,
                api: MutationLifecycleApi<CreateReportParams, BaseQueryFn, Report>,
            ) => {
                const { dispatch, queryFulfilled } = api;

                try {
                    const { data: newReport } = await queryFulfilled;

                    /**
                     * Add new report to Api cache
                     */
                    dispatch(
                        reportsApi.util.updateQueryData("findAll", undefined, (draft: Draft<Report[]>) => {
                            draft.push(newReport);
                        }),
                    );
                } catch {
                    //
                }
            },
        }),

        /**
         * Update report
         */
        update: builder.mutation<Report, UpdateReportParams>({
            queryFn: async (
                params: UpdateReportParams,
                api: BaseQueryApi,
            ): Promise<QueryReturnValue<Report, Error, object>> => {
                const { args } = params;

                const { dispatch, signal } = api;

                const url = Endpoints.API_CUSTOM_REPORT + "/upsert.json";

                const body: UpdateReportRequestParams = {
                    "@row.id": args.id,
                    "name": args.name,
                    "type": args.type,
                    "columns": JSON.stringify(args.columns),
                    "filters1": stringifyFilters(args.filters),
                    "API URL": args.API_URL,
                };

                const res = await lastValueFrom<Report>(
                    from(
                        axios.post(url, body, {
                            signal,
                        }),
                    )
                        .pipe(
                            map((response: AxiosResponse<UpsertDbResponse>) => {
                                const { data } = response;

                                return transformUpsertDbResponse(data);
                            }),
                            switchMap((id: number) => {
                                return from(
                                    dispatch(
                                        reportsApi.endpoints.findById.initiate(id, {
                                            forceRefetch: true,
                                        }),
                                    ).unwrap(),
                                );
                            }),
                        )
                        .pipe(
                            catchError((error: unknown) => {
                                if (error instanceof Error) {
                                    throw new Error(`Can't update report: ${error.message}`);
                                }

                                throw new Error("Can't update report: Unexpected error");
                            }),
                        ),
                );

                return {
                    data: res,
                };
            },
            onQueryStarted: async (
                _: UpdateReportParams,
                api: MutationLifecycleApi<UpdateReportParams, BaseQueryFn, Report>,
            ) => {
                const { dispatch, queryFulfilled } = api;

                try {
                    const { data: updatedReport } = await queryFulfilled;

                    /**
                     * Replace old report to updated in Api cache
                     */
                    dispatch(
                        reportsApi.util.updateQueryData("findAll", undefined, (draft: Draft<Report[]>) => {
                            const oldReportIndex = draft.findIndex((r: Report) => r.id === updatedReport.id);

                            if (oldReportIndex !== -1) {
                                draft.splice(oldReportIndex, 1, updatedReport);
                            }
                        }),
                    );
                } catch {
                    //
                }
            },
        }),

        /**
         * Copy report
         */
        copy: builder.mutation<Report, CopyReportParams>({
            queryFn: async (
                params: CopyReportParams,
                api: BaseQueryApi,
            ): Promise<QueryReturnValue<Report, Error, object>> => {
                const { args } = params;
                const { dispatch, signal } = api;

                const res = await lastValueFrom(
                    from(
                        dispatch(
                            reportsApi.endpoints.findById.initiate(args.id, {
                                forceRefetch: true,
                            }),
                        ).unwrap(),
                    )
                        .pipe(
                            switchMap((report: Report) => {
                                const url = Endpoints.API_CUSTOM_REPORT + "/upsert.json";

                                const body: CreateReportRequestParams = {
                                    // new values
                                    "name": args.name,
                                    "type": args.type,
                                    "Report Creator Email": args.creatorEmail,

                                    // copied values
                                    "sourceId": report.sourceId,
                                    "columns": JSON.stringify(report.columns),
                                    "filters1": stringifyFilters(report.filters),
                                    "API URL": report.API_URL,
                                };

                                return from(
                                    axios.post(url, body, {
                                        signal,
                                    }),
                                ).pipe(
                                    map((response: AxiosResponse<UpsertDbResponse>) => {
                                        const { data } = response;

                                        return transformUpsertDbResponse(data);
                                    }),
                                    switchMap((id: number) => {
                                        return from(dispatch(reportsApi.endpoints.findById.initiate(id)).unwrap());
                                    }),
                                );
                            }),
                        )
                        .pipe(
                            catchError((error: unknown) => {
                                if (error instanceof Error) {
                                    throw new Error(`Can't copy report: ${error.message}`);
                                }

                                throw new Error("Can't copy report: Unexpected error");
                            }),
                        ),
                );

                return {
                    data: res,
                };
            },
            onQueryStarted: async (
                _: CopyReportParams,
                api: MutationLifecycleApi<CopyReportParams, BaseQueryFn, Report>,
            ) => {
                const { dispatch, queryFulfilled } = api;

                try {
                    const { data: reportCopy } = await queryFulfilled;

                    /**
                     * Add report copy to Api cache
                     */
                    dispatch(
                        reportsApi.util.updateQueryData("findAll", undefined, (draft: Draft<Report[]>) => {
                            draft.push(reportCopy);
                        }),
                    );
                } catch {
                    //
                }
            },
        }),

        /**
         * Delete report
         */
        delete: builder.mutation<{ id: number }, number>({
            query: (id: number) => ({
                url: `/delete.json?id=${id}`,
                method: "GET",
            }),
            transformResponse: (raw: DeleteDbResponse) => {
                const data = raw[0];

                if (data.status !== 200) {
                    throw new Error(`Can't delete report: ${data.error.message}`);
                }

                return {
                    id: data.id,
                };
            },
            onQueryStarted: async (
                id: number, //
                api: MutationLifecycleApi<number, BaseQueryFn, { id: number }>,
            ) => {
                const { dispatch, queryFulfilled } = api;

                try {
                    await queryFulfilled;

                    /**
                     * Delete report from Api cache
                     */
                    dispatch(
                        reportsApi.util.updateQueryData("findAll", undefined, (draft: Draft<Report[]>) => {
                            return draft.filter((report: Report) => report.id !== id);
                        }),
                    );
                } catch {
                    //
                }
            },
        }),
    }),
});

export const {
    useFindAllQuery: useFindAllReportsQuery, //
    useCreateMutation: useCreateReportMutation,
    useUpdateMutation: useUpdateReportMutation,
    useCopyMutation: useCopyReportMutation,
    useDeleteMutation: useDeleteReportMutation,
} = reportsApi;
