import React, { useCallback, useState } from "react";
import styles from "./SideMenuHeader.module.scss";
import classnames from "classnames/bind";
import type {
    AbstractUser } from "src/view/components/basic/inputs/select/inputs/RolesPersonsSelect/RolesPersonsSelect";
import {
    RolesPersonsSelect,
} from "src/view/components/basic/inputs/select/inputs/RolesPersonsSelect/RolesPersonsSelect";
import _ from "lodash";
import { useFindAllUsersQuery } from "../../../../../store/services";
import { SearchInput } from "../../../basic/inputs/input";

const cx = classnames.bind(styles);

export function SideMenuHeader({
    searchValue, //
    setSearchValue,
    setResourceFilter,
}: Props) {
    const [selectedResources, setSelectedResources] = useState<AbstractUser[]>([]);

    const { data: users = [] } = useFindAllUsersQuery();

    function handleSearchChange(newValue: string) {
        setSearchValue(newValue);
    }

    const onPrimaryChange = useCallback(
        (newUsers: AbstractUser[]): void => {
            setSelectedResources(newUsers);

            const selectedIds = _.map(newUsers, (user: AbstractUser) => user.id);

            setResourceFilter(selectedIds);
        },
        [setResourceFilter],
    );

    return (
        <div className={cx("side-menu-header")}>
            <div className={cx("side-menu-filters")}>
                <SearchInput
                    value={searchValue}
                    placeholder="Search by Reports"
                    onChange={handleSearchChange}
                />
                <RolesPersonsSelect
                    extractData={users}
                    selectedResources={selectedResources}
                    onChange={onPrimaryChange}
                />
            </div>
        </div>
    );
}

interface Props {
    searchValue: string;
    setSearchValue: (value: string) => void;
    setResourceFilter: (value: number[]) => void;
}
