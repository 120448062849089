import React from "react";

import classnames from "classnames/bind";

import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";

import styles from "./PopupHeaderEmpty.module.scss";

const cx = classnames.bind(styles);

function View({ onClose }: Props) {
    return (
        <div className={cx("popup-header-empty")}>
            <button
                type="button"
                className={cx("close-button")}
                onClick={onClose}
            >
                <CloseIcon />
            </button>
        </div>
    );
}

interface Props {
    onClose: () => void;
}

export const PopupHeaderEmpty = React.memo(View);
