import React from "react";
import type { DropdownIndicatorProps } from "react-select";
import { components } from "react-select";

import classnames from "classnames/bind";

import styles from "./DropdownIndicatorBase.module.scss";

const cx = classnames.bind(styles);

export function DropdownIndicatorBase<T, IsMulti extends boolean>({
    children,
    ...props
}: DropdownIndicatorProps<T, IsMulti>) {
    return (
        <components.DropdownIndicator
            className={cx("dropdown-indicator-base")}
            {...props}
        >
            <svg
                style={{
                    transform: props.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0)",
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
            >
                <g>
                    <path
                        id="Vector"
                        d="M13 6L8 11L3 6"
                        stroke="#4A7A8A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
        </components.DropdownIndicator>
    );
}
