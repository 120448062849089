import { useLayoutEffect, useRef, useState } from "react";

interface Size {
    width: number;
    height: number;
}

function createHook() {
    return function useElementSize() {
        const elementRef = useRef<HTMLDivElement>(null);

        const [size, setSize] = useState<Size>({
            width: 0,
            height: 0,
        });

        useLayoutEffect(() => {
            const element = elementRef.current;

            if (element) {
                const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
                    if (entries[0]) {
                        const { width, height } = entries[0].contentRect;

                        setSize((prev: Size) => {
                            if (width === prev.width && height === prev.height) {
                                return prev;
                            }

                            return {
                                width,
                                height,
                            };
                        });
                    }
                });

                resizeObserver.observe(element);

                return () => {
                    resizeObserver.disconnect();
                };
            }

            return;
        }, []);

        return { elementRef, size };
    };
}

export const useElementSize = createHook();
