import React from "react";

import classnames from "classnames/bind";

import { usePersistedData } from "../../../hooks";
import { SideMenu } from "src/view/components/layout/SideMenu/SideMenu.com";

import styles from "./Main.module.scss";
import { PopupsContainer } from "../../popups/providers/PopupsContainer";
import { Body } from "../Body";

const cx = classnames.bind(styles);

export function Main() {
    usePersistedData();

    return (
        <div className={cx("main")}>
            <div className={cx("layout")}>
                <div className={cx("side-menu")}>
                    <SideMenu />
                </div>
                <div className={cx("body")}>
                    <Body />
                </div>
            </div>

            <PopupsContainer />
        </div>
    );
}
