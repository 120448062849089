import React from "react";

import classnames from "classnames/bind";

import styles from "./DatePickerMenu.module.scss";
import { BasicPopup } from "src/view/components/popups/base";
import { ButtonWrapper, InputErrorMessage, PopupHeader } from "../../../../../../../mix";
import { BasicButton, GhostButton } from "src/view/components/basic/buttons";
import { BasicSelect } from "../../../../../select";
import { DatePeriod, DateType } from "src/core/enums";
import type { FieldErrors } from "react-hook-form";
import { useController, useForm } from "react-hook-form";
import type { RefinementCtx } from "zod";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { BasicNumberInput } from "../../../../../number";
import { DatePickerSingle } from "../../../DatePickerSingle";
import type { ReportFilterDateChoices } from "src/core/entities";

const cx = classnames.bind(styles);

interface DateTypeOption {
    label: string;
    value: DateType;
}

interface DatePeriodOption {
    label: string;
    value: DatePeriod;
}

const dateTypeOptionsMap: Record<DateType, DateTypeOption> = {
    IS: {
        label: "Is",
        value: "IS",
    },
    IS_NOT: {
        label: "Is not",
        value: "IS_NOT",
    },
    IS_AFTER: {
        label: "Is after",
        value: "IS_AFTER",
    },
    IS_ON_OR_AFTER: {
        label: "Is on or after",
        value: "IS_ON_OR_AFTER",
    },
    IS_BEFORE: {
        label: "Is before",
        value: "IS_BEFORE",
    },
    IS_ON_OR_BEFORE: {
        label: "Is on or before",
        value: "IS_ON_OR_BEFORE",
    },
    BETWEEN: {
        label: "Between",
        value: "BETWEEN",
    },
    NOT_BETWEEN: {
        label: "Not between",
        value: "NOT_BETWEEN",
    },
};

const datePeriodOptionsMap: Record<DatePeriod, DatePeriodOption> = {
    CURRENT_DAY: {
        label: "Current day",
        value: "CURRENT_DAY",
    },
    THIS_WEEK: {
        label: "This week",
        value: "THIS_WEEK",
    },
    THIS_MONTH: {
        label: "This month",
        value: "THIS_MONTH",
    },
    THIS_QUARTER: {
        label: "This quarter",
        value: "THIS_QUARTER",
    },
    THIS_YEAR: {
        label: "This year",
        value: "THIS_YEAR",
    },
    LAST_WEEK: {
        label: "Last week",
        value: "LAST_WEEK",
    },
    LAST_MONTH: {
        label: "Last month",
        value: "LAST_MONTH",
    },
    LAST_QUARTER: {
        label: "Last quarter",
        value: "LAST_QUARTER",
    },
    LAST_YEAR: {
        label: "Last year",
        value: "LAST_YEAR",
    },
    NEXT_WEEK: {
        label: "Next week",
        value: "NEXT_WEEK",
    },
    NEXT_MONTH: {
        label: "Next month",
        value: "NEXT_MONTH",
    },
    NEXT_QUARTER: {
        label: "Next quarter",
        value: "NEXT_QUARTER",
    },
    NEXT_YEAR: {
        label: "Next year",
        value: "NEXT_YEAR",
    },
    DAYS_IN_FUTURE: {
        label: "Days in future",
        value: "DAYS_IN_FUTURE",
    },
    DAYS_PAST: {
        label: "Days past",
        value: "DAYS_PAST",
    },
    THE_DATE: {
        label: "The date",
        value: "THE_DATE",
    },
};

const dateTypeOptions = Object.values(dateTypeOptionsMap);
const datePeriodOptions = Object.values(datePeriodOptionsMap);
const datePeriodRangeOptions = datePeriodOptions.filter((option: DatePeriodOption) => {
    return isPeriodAllowedInRangeCheck(option.value);
});

const schema = z
    .object({
        dateType: z.nativeEnum(DateType).nullable(),
        periodFrom: z.nativeEnum(DatePeriod).nullable(),
        valueFrom: z.union([z.number(), z.date()]).nullable(),
        periodTo: z.nativeEnum(DatePeriod).nullable(),
        valueTo: z.union([z.number(), z.date()]).nullable(),
    })
    .superRefine(
        (
            arg: {
                dateType: DateType | null;
                periodFrom: DatePeriod | null;
                valueFrom: number | Date | null;
                periodTo: DatePeriod | null;
                valueTo: number | Date | null;
            },
            ctx: RefinementCtx,
        ) => {
            const { dateType, periodFrom, valueFrom, periodTo, valueTo } = arg;

            if (dateType !== null) {
                if (periodFrom === null) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "Period is required",
                        path: ["periodFrom"],
                    });
                } else if (getPeriodType(periodFrom) !== null && valueFrom === null) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "Value is required",
                        path: ["valueFrom"],
                    });
                }

                if (isRangeDateTypeCheck(dateType)) {
                    if (periodTo === null) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: "Period is required",
                            path: ["periodTo"],
                        });
                    } else if (getPeriodType(periodTo) !== null && valueTo === null) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: "Value is required",
                            path: ["valueTo"],
                        });
                    }
                }
            }
        },
    );

type SchemaInput = z.input<typeof schema>;
type SchemaOutput = z.output<typeof schema>;

function isRangeDateTypeCheck(type: DateType | null): boolean {
    if (type === null) return false;

    return [
        DateType.BETWEEN, //
        DateType.NOT_BETWEEN,
    ].includes(type);
}

function isPeriodAllowedInRangeCheck(period: DatePeriod | null): boolean {
    if (period === null) return true;

    return [
        DatePeriod.CURRENT_DAY, //
        DatePeriod.DAYS_IN_FUTURE,
        DatePeriod.DAYS_PAST,
        DatePeriod.THE_DATE,
    ].includes(period);
}

function getPeriodType(period: DatePeriod | null): "numeric" | "date" | null {
    if (period === null) return null;

    if (
        [
            DatePeriod.DAYS_IN_FUTURE, //
            DatePeriod.DAYS_PAST,
        ].includes(period)
    ) {
        return "numeric";
    }

    if (
        [
            DatePeriod.THE_DATE, //
        ].includes(period)
    ) {
        return "date";
    }

    return null;
}

function View({
    value, //
    placeholder,
    onApply,
    onCancel,
    onMenuClose,
}: Props) {
    const {
        handleSubmit,
        control,
        reset,
        resetField,
        formState: { errors },
    } = useForm<SchemaInput, unknown, SchemaOutput>({
        defaultValues: value ?? {
            dateType: null,
            periodFrom: null,
            valueFrom: null,
            periodTo: null,
            valueTo: null,
        },
        resolver: zodResolver(schema),
        mode: "onChange",
    });

    const { field: dateType } = useController({
        name: "dateType",
        control,
    });

    const {
        field: periodFrom,
        fieldState: { invalid: isPeriodFromInvalid },
    } = useController({
        name: "periodFrom",
        control,
    });

    const {
        field: valueFrom,
        fieldState: { invalid: isValueFromInvalid },
    } = useController({
        name: "valueFrom",
        control,
    });

    const {
        field: periodTo,
        fieldState: { invalid: isPeriodToInvalid },
    } = useController({
        name: "periodTo",
        control,
    });

    const {
        field: valueTo,
        fieldState: { invalid: isValueToInvalid },
    } = useController({
        name: "valueTo",
        control,
    });

    function onValid(values: SchemaOutput) {
        console.log("Values", values);

        if (Object.values(values).every((val: unknown) => val === null)) {
            onApply(null);

            return;
        }

        onApply(values);
    }

    function onInvalid(_: FieldErrors<SchemaOutput>) {
        console.log("Errors", _);
    }

    /**
     * Handle Date type change
     */
    function handleDateTypeChange(val: DateTypeOption | null) {
        const newDateType = val?.value ?? null;

        if (newDateType === null) {
            handleReset();

            return;
        }

        const isRange = isRangeDateTypeCheck(newDateType);

        if (isRange) {
            if (!isPeriodAllowedInRangeCheck(periodFrom.value)) {
                resetField("periodFrom", {
                    defaultValue: null,
                });

                resetField("valueFrom", {
                    defaultValue: null,
                });
            }

            if (!isPeriodAllowedInRangeCheck(periodTo.value)) {
                resetField("periodTo", {
                    defaultValue: null,
                });

                resetField("valueTo", {
                    defaultValue: null,
                });
            }
        }

        if (!isRange) {
            resetField("periodTo", {
                defaultValue: null,
            });

            resetField("valueTo", {
                defaultValue: null,
            });
        }

        dateType.onChange(newDateType);
    }

    /**
     * Handle Date period "From" change
     */
    function handlePeriodFromChange(val: DatePeriodOption | null) {
        const newPeriodFrom = val?.value ?? null;

        if (newPeriodFrom === null) {
            resetField("valueFrom", {
                defaultValue: null,
            });
        } else if (getPeriodType(newPeriodFrom) !== getPeriodType(periodFrom.value)) {
            resetField("valueFrom", {
                defaultValue: null,
            });
        }

        periodFrom.onChange(newPeriodFrom);
    }

    /**
     * Handle Date period "From" value change
     */
    function handleValueFromChange(val: number | Date | null) {
        valueFrom.onChange(val);
    }

    /**
     * Handle Date period "To" change
     */
    function handlePeriodToChange(val: DatePeriodOption | null) {
        const newPeriodTo = val?.value ?? null;

        if (newPeriodTo === null) {
            resetField("valueTo", {
                defaultValue: null,
            });
        } else if (getPeriodType(newPeriodTo) !== getPeriodType(periodTo.value)) {
            resetField("valueTo", {
                defaultValue: null,
            });
        }

        periodTo.onChange(newPeriodTo);
    }

    /**
     * Handle Date period "To" value change
     */
    function handleValueToChange(val: number | Date | null) {
        valueTo.onChange(val);
    }

    /**
     * Set all inputs values to null
     */
    function handleReset() {
        reset({
            dateType: null,
            periodFrom: null,
            valueFrom: null,
            periodTo: null,
            valueTo: null,
        });
    }

    const isRangeView = isRangeDateTypeCheck(dateType.value);

    return (
        <BasicPopup
            onClose={onMenuClose}
            style={{
                maxHeight: "100%",
                maxWidth: "100%",
                marginBottom: "auto",
                marginTop: 74,
            }}
        >
            <div className={cx("date-picker-menu")}>
                <div className={cx("header")}>
                    <PopupHeader
                        title={placeholder}
                        onClose={onMenuClose}
                    />
                </div>

                <div className={cx("form")}>
                    {
                        // Date type part
                    }
                    <div className={cx("part")}>
                        <div className={cx("part-title")}>Type</div>

                        <div className={cx("input")}>
                            <BasicSelect<DateTypeOption>
                                options={dateTypeOptions}
                                value={
                                    dateTypeOptions.find((option: DateTypeOption) => {
                                        return option.value === dateType.value;
                                    }) ?? null
                                }
                                isClearable
                                isSearchable={false}
                                placeholder="Select type"
                                onChange={handleDateTypeChange}
                            />
                        </div>
                    </div>

                    {
                        // Period part
                    }
                    <div className={cx("part")}>
                        <div className={cx("part-title")}>Period</div>

                        {
                            // Period "From"
                        }
                        {dateType.value !== null ? (
                            <div className={cx("input")}>
                                <BasicSelect<DatePeriodOption>
                                    options={isRangeView ? datePeriodRangeOptions : datePeriodOptions}
                                    value={
                                        datePeriodOptions.find((option: DatePeriodOption) => {
                                            return option.value === periodFrom.value;
                                        }) ?? null
                                    }
                                    isClearable
                                    isSearchable={false}
                                    placeholder="Select period"
                                    isError={isPeriodFromInvalid}
                                    onChange={handlePeriodFromChange}
                                />
                                <InputErrorMessage
                                    name="periodFrom"
                                    errors={errors}
                                />
                            </div>
                        ) : (
                            <div className={cx("input-placeholder")} />
                        )}

                        {
                            // Period "To"
                        }
                        {isRangeView ? (
                            <div className={cx("input")}>
                                <BasicSelect<DatePeriodOption>
                                    options={datePeriodRangeOptions}
                                    value={
                                        datePeriodOptions.find((option: DatePeriodOption) => {
                                            return option.value === periodTo.value;
                                        }) ?? null
                                    }
                                    isClearable
                                    isSearchable={false}
                                    placeholder="Select period"
                                    isError={isPeriodToInvalid}
                                    onChange={handlePeriodToChange}
                                />
                                <InputErrorMessage
                                    name="periodTo"
                                    errors={errors}
                                />
                            </div>
                        ) : (
                            <div className={cx("input-placeholder")} />
                        )}
                    </div>

                    {
                        // Value part
                    }
                    <div className={cx("part")}>
                        <div className={cx("part-title")}>Value</div>

                        {
                            // Value "From"
                        }
                        {getPeriodType(periodFrom.value) === "numeric" ? (
                            <div className={cx("input")}>
                                <BasicNumberInput
                                    value={valueFrom.value as number | null}
                                    isError={isValueFromInvalid}
                                    onChange={handleValueFromChange}
                                />
                                <InputErrorMessage
                                    name="valueFrom"
                                    errors={errors}
                                />
                            </div>
                        ) : getPeriodType(periodFrom.value) === "date" ? (
                            <div className={cx("input")}>
                                <DatePickerSingle
                                    value={valueFrom.value as Date | null}
                                    popperPlacement="left-end"
                                    isError={isValueFromInvalid}
                                    onChange={handleValueFromChange}
                                />
                                <InputErrorMessage
                                    name="valueFrom"
                                    errors={errors}
                                />
                            </div>
                        ) : (
                            <div className={cx("input-placeholder")} />
                        )}

                        {
                            // Value "To"
                        }
                        {getPeriodType(periodTo.value) === "numeric" ? (
                            <div className={cx("input")}>
                                <BasicNumberInput
                                    value={valueTo.value as number | null}
                                    isError={isValueToInvalid}
                                    onChange={handleValueToChange}
                                />
                                <InputErrorMessage
                                    name="valueTo"
                                    errors={errors}
                                />
                            </div>
                        ) : getPeriodType(periodTo.value) === "date" ? (
                            <div className={cx("input")}>
                                <DatePickerSingle
                                    value={valueTo.value as Date | null}
                                    popperPlacement="left-end"
                                    isError={isValueToInvalid}
                                    onChange={handleValueToChange}
                                />
                                <InputErrorMessage
                                    name="valueTo"
                                    errors={errors}
                                />
                            </div>
                        ) : (
                            <div className={cx("input-placeholder")} />
                        )}
                    </div>
                </div>

                <div className={cx("footer")}>
                    <ButtonWrapper shift="right">
                        <BasicButton
                            title="Apply"
                            style={{ width: "134px" }}
                            onClick={() => {
                                void handleSubmit(onValid, onInvalid)();
                            }}
                        />
                        <GhostButton
                            title="Reset"
                            style={{ width: "76px", marginLeft: "16px" }}
                            onClick={handleReset}
                        />
                        <GhostButton
                            title="Cancel"
                            style={{ width: "76px", marginLeft: "16px" }}
                            onClick={onCancel}
                        />
                    </ButtonWrapper>
                </div>
            </div>
        </BasicPopup>
    );
}

interface Props {
    value: ReportFilterDateChoices;
    placeholder: string;
    onApply: (value: ReportFilterDateChoices) => void;
    onCancel: () => void;
    onMenuClose: () => void;
}

export const DatePickerMenu = React.memo(View);
