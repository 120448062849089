import React from "react";
import classnames from "classnames/bind";

import styles from "./BasicSelect.module.scss";
import type { SingleValue, Props as SelectProps } from "react-select";
import Select from "react-select";
import type { AbstractSelectOption } from "../../types";
import { ClearIndicatorBase, DropdownIndicatorBase, NoOptionsMessageBase } from "../../base";
import { selectBaseStyles } from "../../config";

const cx = classnames.bind(styles);

function View<T extends AbstractSelectOption<unknown>>({
    options, //
    value,
    onChange,
    isError,
    ...attributes
}: Props<T>) {
    function handleChange(newValue: SingleValue<T>) {
        onChange(newValue);
    }

    return (
        <div className={cx("basic-select")}>
            <Select<T, false>
                options={options}
                value={value}
                onChange={handleChange}
                unstyled
                components={{
                    IndicatorSeparator: () => null,
                    NoOptionsMessage: NoOptionsMessageBase,
                    ClearIndicator: ClearIndicatorBase,
                    DropdownIndicator: DropdownIndicatorBase,
                }}
                classNames={{
                    control: () => {
                        return cx("control", {
                            error: isError,
                        });
                    },
                }}
                styles={selectBaseStyles}
                {...attributes}
            />
        </div>
    );
}

interface Props<T extends AbstractSelectOption<unknown>> extends Partial<SelectProps<T, false>> {
    options: T[];
    value: SingleValue<T>;
    onChange: (options: SingleValue<T>) => void;
    isError?: boolean;
}

export const BasicSelect = React.memo(View) as typeof View;
