import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { CreateReportParams, UpdateReportParams } from "../services";

export type ConfirmSaveReportParams =
    | {
          mode: "create";
          args: CreateReportParams["args"];
      }
    | {
          mode: "edit";
          args: UpdateReportParams["args"];
      };

interface PopupsState {
    createReportPopup: {
        isOpen: boolean;
    };
    createReportColumnsPopup: {
        isOpen: boolean;
    };
    createReportFiltersPopup: {
        isOpen: boolean;
    };
    editReportPopup: {
        isOpen: boolean;
    };
    editReportColumnsPopup: {
        isOpen: boolean;
    };
    editReportFiltersPopup: {
        isOpen: boolean;
    };
    draftFiltersPopup: {
        isOpen: boolean;
    };
    copyReportPopup: {
        isOpen: boolean;
    };
    deleteReportPopup: {
        isOpen: boolean;
    };
    confirmSaveReportPopup: {
        isOpen: boolean;
        params: ConfirmSaveReportParams | null;
    };
    notReportCreatorWarningPopup: {
        isOpen: boolean;
    };
}

const initialState: PopupsState = {
    createReportPopup: {
        isOpen: false,
    },
    createReportColumnsPopup: {
        isOpen: false,
    },
    createReportFiltersPopup: {
        isOpen: false,
    },
    editReportPopup: {
        isOpen: false,
    },
    editReportColumnsPopup: {
        isOpen: false,
    },
    editReportFiltersPopup: {
        isOpen: false,
    },
    draftFiltersPopup: {
        isOpen: false,
    },
    copyReportPopup: {
        isOpen: false,
    },
    deleteReportPopup: {
        isOpen: false,
    },
    confirmSaveReportPopup: {
        isOpen: false,
        params: null,
    },
    notReportCreatorWarningPopup: {
        isOpen: false,
    },
};

export const popupsSlice = createSlice({
    name: "popups-slice",
    initialState,

    reducers: {
        /**
         * Create report popup
         */
        setCreateReportPopupOpen: (state: Draft<PopupsState>) => {
            state.createReportPopup.isOpen = true;
            state.createReportColumnsPopup.isOpen = false;
            state.createReportFiltersPopup.isOpen = false;
        },
        setCreateReportPopupClose: (state: Draft<PopupsState>) => {
            state.createReportPopup.isOpen = false;
        },

        /**
         * Create report columns popup
         */
        setCreateReportColumnsPopupOpen: (state: Draft<PopupsState>) => {
            state.createReportPopup.isOpen = false;
            state.createReportColumnsPopup.isOpen = true;
            state.createReportFiltersPopup.isOpen = false;
        },
        setCreateReportColumnsPopupClose: (state: Draft<PopupsState>) => {
            state.createReportColumnsPopup.isOpen = false;
        },

        /**
         * Create report filters popup
         */
        setCreateReportFiltersPopupOpen: (state: Draft<PopupsState>) => {
            state.createReportPopup.isOpen = false;
            state.createReportColumnsPopup.isOpen = false;
            state.createReportFiltersPopup.isOpen = true;
        },
        setCreateReportFiltersPopupClose: (state: Draft<PopupsState>) => {
            state.createReportFiltersPopup.isOpen = false;
        },

        /**
         * Edit report popup
         */
        setEditReportPopupOpen: (state: Draft<PopupsState>) => {
            state.editReportPopup.isOpen = true;
            state.editReportColumnsPopup.isOpen = false;
            state.editReportFiltersPopup.isOpen = false;
        },
        setEditReportPopupClose: (state: Draft<PopupsState>) => {
            state.editReportPopup.isOpen = false;
        },

        /**
         * Edit report columns popup
         */
        setEditReportColumnsPopupOpen: (state: Draft<PopupsState>) => {
            state.editReportPopup.isOpen = false;
            state.editReportColumnsPopup.isOpen = true;
            state.editReportFiltersPopup.isOpen = false;
        },
        setEditReportColumnsPopupClose: (state: Draft<PopupsState>) => {
            state.editReportColumnsPopup.isOpen = false;
        },

        /**
         * Edit report filters popup
         */
        setEditReportFiltersPopupOpen: (state: Draft<PopupsState>) => {
            state.editReportPopup.isOpen = false;
            state.editReportColumnsPopup.isOpen = false;
            state.editReportFiltersPopup.isOpen = true;
        },
        setEditReportFiltersPopupClose: (state: Draft<PopupsState>) => {
            state.editReportFiltersPopup.isOpen = false;
        },

        /**
         * Draft filters popup
         */
        setDraftFiltersPopupOpen: (state: Draft<PopupsState>) => {
            state.draftFiltersPopup.isOpen = true;
        },
        setDraftFiltersPopupClose: (state: Draft<PopupsState>) => {
            state.draftFiltersPopup.isOpen = false;
        },

        /**
         * Copy report popup
         */
        setCopyReportPopupOpen: (state: Draft<PopupsState>) => {
            state.copyReportPopup.isOpen = true;
        },
        setCopyReportPopupClose: (state: Draft<PopupsState>) => {
            state.copyReportPopup.isOpen = false;
        },

        /**
         * Delete report popup
         */
        setDeleteReportPopupOpen: (state: Draft<PopupsState>) => {
            state.deleteReportPopup.isOpen = true;
        },
        setDeleteReportPopupClose: (state: Draft<PopupsState>) => {
            state.deleteReportPopup.isOpen = false;
        },

        /**
         * Confirm save report popup
         */
        setConfirmSaveReportPopupOpen: (state: Draft<PopupsState>, action: PayloadAction<ConfirmSaveReportParams>) => {
            state.confirmSaveReportPopup.isOpen = true;
            state.confirmSaveReportPopup.params = action.payload;
        },
        setConfirmSaveReportPopupClose: (state: Draft<PopupsState>) => {
            state.confirmSaveReportPopup.isOpen = false;
            state.confirmSaveReportPopup.params = null;
        },

        /**
         * Not report creator warning popup
         */
        setNotReportCreatorWarningPopupOpen: (state: Draft<PopupsState>) => {
            state.notReportCreatorWarningPopup.isOpen = true;
        },
        setNotReportCreatorWarningPopupClose: (state: Draft<PopupsState>) => {
            state.notReportCreatorWarningPopup.isOpen = false;
        },
    },
});
