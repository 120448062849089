/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { SourceColumn, ReportFilter } from "src/core/entities";

export const reportFilterMapper = {
    toValue: (sourceColumn: SourceColumn): ReportFilter => {
        if (sourceColumn.type === "SELECT") {
            return {
                id: sourceColumn.id,
                value: {
                    type: "SELECT",
                    choices: [],
                },
                pinned: true,
                position: Number.MAX_SAFE_INTEGER,
            };
        }

        if (sourceColumn.type === "NUMERIC") {
            return {
                id: sourceColumn.id,
                value: {
                    type: "NUMERIC",
                    choices: null,
                },
                pinned: true,
                position: Number.MAX_SAFE_INTEGER,
            };
        }

        if (sourceColumn.type === "DATE") {
            return {
                id: sourceColumn.id,
                value: {
                    type: "DATE",
                    choices: null,
                },
                pinned: true,
                position: Number.MAX_SAFE_INTEGER,
            };
        }

        if (sourceColumn.type === "CHECKBOX") {
            return {
                id: sourceColumn.id,
                value: {
                    type: "CHECKBOX",
                    choices: [],
                },
                pinned: true,
                position: Number.MAX_SAFE_INTEGER,
            };
        }

        throw new Error("Column type not found");
    },
};
