import React from "react";
import type { AbstractSelectOption } from "../../types";
import type { ValueContainerProps } from "react-select";
import { components } from "react-select";

import classnames from "classnames/bind";

import styles from "./ValueContainerMulti.module.scss";

const cx = classnames.bind(styles);

export function ValueContainerMulti<T extends AbstractSelectOption<unknown>>({
    children,
    selectProps,
    getValue,
    ...rest
}: ValueContainerProps<T, true>) {
    const value = getValue();

    const formatted = value.map((o: T) => o.label).join(", ");

    return (
        <components.ValueContainer
            getValue={getValue}
            selectProps={selectProps}
            className={cx("value-container-multi")}
            {...rest}
        >
            {children}

            {value.length > 0 ? (
                <>
                    <div className={cx("values")}>{formatted}</div>
                    <div className={cx("counter")}>{"(" + value.length + ")"}</div>
                </>
            ) : (
                <div className={cx("values", "empty")}>
                    <span className={cx("placeholder")}>{selectProps.placeholder}</span>
                </div>
            )}
        </components.ValueContainer>
    );
}
