import React from "react";

import classnames from "classnames/bind";

import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-left.svg";

import styles from "./CollapseButton.module.scss";

const cx = classnames.bind(styles);

function View({
    collapsed, //
    onClick,
    ...attributes
}: Props) {
    return (
        <button
            type="button"
            className={cx("collapse-button", {
                collapsed,
            })}
            onClick={onClick}
            {...attributes}
        >
            <ArrowIcon className={cx("icon")} />
        </button>
    );
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    collapsed: boolean;
    onClick: () => void;
}

export const CollapseButton = React.memo(View);
