import { DatePeriod, DateType, ReportType, SourceColumnType } from "src/core/enums";
import type { RefinementCtx } from "zod";
import { z } from "zod";

export const reportColumnSchema = z.object({
    id: z.number(),
    position: z.number(),

    showTotals: z.boolean().optional(),
    showAverages: z.boolean().optional(),
});

export const reportFilterSchema = z.object({
    id: z.number(),
    position: z.number(),
    pinned: z.boolean(),
    value: z.discriminatedUnion("type", [
        z.object({
            type: z.literal(SourceColumnType.SELECT),
            choices: z.array(z.string()),
        }),

        z.object({
            type: z.literal(SourceColumnType.NUMERIC),
            choices: z
                .object({
                    min: z.number(),
                    max: z.number(),
                })
                .nullable(),
        }),

        z.object({
            type: z.literal(SourceColumnType.DATE),
            choices: z
                .object({
                    dateType: z.nativeEnum(DateType).nullable(),
                    periodFrom: z.nativeEnum(DatePeriod).nullable(),
                    valueFrom: z.union([z.number(), z.coerce.date()]).nullable(),
                    periodTo: z.nativeEnum(DatePeriod).nullable(),
                    valueTo: z.union([z.number(), z.coerce.date()]).nullable(),
                })
                .nullable(),
        }),

        z.object({
            type: z.literal(SourceColumnType.CHECKBOX),
            choices: z.array(z.boolean()),
        }),
    ]),
});

export const reportFormSchema = {
    name: z.string().trim().min(1, "Report Title is required").max(255, "Name should be at most 255 characters long"),
    sourceId: z
        .string()
        .nullable()
        .transform((val: string | null, ctx: RefinementCtx) => {
            if (val === null) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Data Source is required",
                });

                return z.NEVER;
            }

            return val;
        }),
    type: z
        .nativeEnum(ReportType)
        .nullable()
        .transform((val: ReportType | null, ctx: RefinementCtx) => {
            if (val === null) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Report Type is required",
                });

                return z.NEVER;
            }

            return val;
        }),
    creatorEmail: z.string().email(),
    columns: z.array(reportColumnSchema).min(1, "Please select at least one column to proceed"),
    filters: z.array(reportFilterSchema),
    API_URL: z.string().nullable(),
};

const schema = z.object(reportFormSchema);

export type ReportFormInput = z.input<typeof schema>;
export type ReportFormOutput = z.output<typeof schema>;
