import type { ButtonHTMLAttributes, ReactNode } from "react";
import React from "react";

import classnames from "classnames/bind";

import styles from "./BasicButton.module.scss";
import { DotSpinner } from "../../spinners";

const cx = classnames.bind(styles);

function View({
    title, //
    onClick,
    disabled,
    isLoading = false,
    icon = null,
    style,
    ...attributes
}: Props) {
    function handleClick() {
        if (!disabled) {
            void onClick();
        }
    }

    return (
        <div
            style={style}
            className={cx("basic-button", {
                disabled,
                "is-loading": isLoading,
            })}
        >
            {isLoading ? (
                <DotSpinner
                    size="medium"
                    color="white"
                />
            ) : (
                <button
                    type="button"
                    onClick={handleClick}
                    disabled={disabled}
                    {...attributes}
                >
                    {icon && <div className={cx("icon")}>{icon}</div>}
                    {title && <span className={cx("title")}>{title}</span>}
                </button>
            )}
        </div>
    );
}

export const BasicButton = React.memo(View);

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    onClick: () => Promise<void> | void;
    isLoading?: boolean;
    icon?: ReactNode;
}
