import type { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { reportsApi } from "../../store/services";
import { useLayoutEffect } from "react";
import type { Report } from "../../core/entities";
import { useReports } from "./useReports";
import { hasReportPermissionsCheck } from "../../utils/common";
import invariant from "tiny-invariant";

function createHook() {
    return function usePersistedData() {
        const { reportId } = useAppSelector((state: RootState) => state.persistence);
        const { user } = useAppSelector((state: RootState) => state.auth);

        const { actions: reportsActions } = useReports();

        const dispatch = useAppDispatch();

        useLayoutEffect(() => {
            async function restore() {
                invariant(user, "User should be specified");

                try {
                    const reports = await dispatch(reportsApi.endpoints.findAll.initiate()).unwrap();

                    const foundReport = reports.find((report: Report) => {
                        return (
                            report.id === reportId &&
                            [
                                hasReportPermissionsCheck(report, user), //
                                report.type === "Public",
                            ].some(Boolean)
                        );
                    });

                    if (foundReport) {
                        reportsActions.setReport(foundReport);
                    } else {
                        reportsActions.setReport(null);
                    }
                } catch (error: unknown) {
                    console.log(error);
                }
            }

            if (reportId) {
                void restore();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [reportsActions, user, dispatch]);
    };
}

/**
 * Restore persisted data
 */
export const usePersistedData = createHook();
