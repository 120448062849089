import React, { Fragment } from "react";

import classnames from "classnames/bind";

import styles from "./SideMenuReports.module.scss";
import type { Report, ReportsGroup, ReportUserPair } from "src/core/entities";

import { ReactComponent as CaretDownIcon } from "src/assets/icons/caret-down-icon.svg";
import { ReactComponent as InfoIcon } from "src/assets/icons/info-icon.svg";
import { ReactComponent as PrivateIcon } from "src/assets/icons/lock-icon.svg";
import { ReactComponent as PublicIcon } from "src/assets/icons/public-icon.svg";

import { InfoTooltip, ReportDetailsTooltip } from "../../../tooltips";

const cx = classnames.bind(styles);

const REPORT_TYPE_TOOLTIP_ID = "report-type-tooltip-id";

export function SideMenuReports({
    groups, //
    openedGroups,
    onGroupClick,
    onReportSelect,
    selectedReportId,
}: Props) {
    function handleGroupsToggle(sourceId: string) {
        onGroupClick(sourceId);
    }

    return (
        <div className={cx("side-menu-reports")}>
            {groups.length === 0 ? (
                <div className={cx("no-reports")}>No reports</div>
            ) : (
                groups.map(({ sourceId, pairs }: ReportsGroup) => {
                    return (
                        <Fragment key={sourceId}>
                            <div
                                role="button"
                                tabIndex={0}
                                className={cx("group-item")}
                                onClick={() => {
                                    handleGroupsToggle(sourceId);
                                }}
                            >
                                <span className={cx("group-name")}>{sourceId}</span>
                                <button
                                    type="button"
                                    className={cx("group-open-button")}
                                    aria-label="open-report"
                                >
                                    <CaretDownIcon
                                        className={cx({
                                            "group-icon-rotated": openedGroups.has(sourceId),
                                        })}
                                    />
                                </button>
                            </div>

                            {pairs
                                .filter(() => {
                                    return openedGroups.has(sourceId);
                                })
                                .map(({ report, user }: ReportUserPair) => {
                                    const infoTooltipId = report.id.toString();

                                    return (
                                        <Fragment key={report.id}>
                                            <div
                                                role="button"
                                                tabIndex={0}
                                                className={cx("detail-item", {
                                                    active: report.id === selectedReportId,
                                                })}
                                                onClick={() => {
                                                    onReportSelect(report);
                                                }}
                                            >
                                                <span className={cx("report-name")}>{report.name}</span>
                                                <div className={cx("icons")}>
                                                    <div className={cx("report-type-icon")}>
                                                        {report.type === "Private" ? (
                                                            <PrivateIcon
                                                                data-tooltip-id={REPORT_TYPE_TOOLTIP_ID}
                                                                data-tooltip-content="Private reports are only accessible to the report creator and are not shown in the left sidebar for any users, apart to Private report creator."
                                                                width={16}
                                                                height={16}
                                                            />
                                                        ) : (
                                                            <PublicIcon
                                                                data-tooltip-id={REPORT_TYPE_TOOLTIP_ID}
                                                                data-tooltip-content="Only the report creator can modify the Public report, but any user can duplicate report and update any report settings on the copy only.  Any user can apply filters to Public report, but that will be not saved to the report settings."
                                                                width={18}
                                                                height={18}
                                                            />
                                                        )}
                                                    </div>
                                                    <div
                                                        data-tooltip-id={infoTooltipId}
                                                        className={cx("info-icon")}
                                                    >
                                                        <InfoIcon />
                                                    </div>
                                                </div>
                                            </div>

                                            <ReportDetailsTooltip
                                                tooltipId={infoTooltipId}
                                                details={{
                                                    reportName: report.name,
                                                    reportType: report.type,
                                                    creator: user?.fullName ?? report.creatorEmail,
                                                    department: user?.role ?? null,
                                                    dateCreated: report.dateCreated,
                                                    dateUpdated: report.dateModified,
                                                }}
                                            />
                                        </Fragment>
                                    );
                                })}
                        </Fragment>
                    );
                })
            )}

            <InfoTooltip
                id={REPORT_TYPE_TOOLTIP_ID}
                place="right-end"
                borders={[8, 8, 8, 0]}
                style={{
                    maxWidth: 250,
                }}
                positionStrategy="fixed"
            />
        </div>
    );
}

interface Props {
    groups: ReportsGroup[];
    openedGroups: Set<string>;
    onGroupClick: (sourceId: string) => void;
    onReportSelect: (report: Report) => void;
    selectedReportId: number | null;
}
