import { useLayoutEffect, useState } from "react";
import { useAppDispatch } from "src/store/store";
import { usersApi } from "src/store/services";
import { setUser } from "src/store/slices";
import type { User } from "src/core/entities";
import { z } from "zod";
import { useIframe } from "./useIframe";

const schema = z.object({
    name: z.literal("report-builder-current-user"),
    email: z.string().trim(),
});

const DEFAULT_EMAIL = "test@test.com";

function getDefaultUser(email: string) {
    const user: User = {
        id: -1,
        role: "System Administrator",
        fullName: "bschulz",
        email,
        teamLeadId: null,
        logo: null,
    };

    return user;
}

function createHook() {
    return function useAuth() {
        const [permissionAllowed, setPermissionAllowed] = useState(false);
        const [isAuthorization, setAuthorization] = useState(true);

        const { isIframe } = useIframe();

        const dispatch = useAppDispatch();

        useLayoutEffect(() => {
            async function findUserByEmail(email: string | null): Promise<User | null> {
                if (email === null) return null;

                try {
                    const users = await dispatch(usersApi.endpoints.findAll.initiate()).unwrap();

                    return users.find((user: User) => user.email.toLowerCase() === email.toLowerCase()) ?? null;
                } catch (error: unknown) {
                    console.log(error);
                }

                return null;
            }

            async function authorize(email: string | null) {
                const user = await findUserByEmail(email);

                if (user) {
                    dispatch(setUser(user));
                } else {
                    dispatch(setUser(getDefaultUser(DEFAULT_EMAIL)));
                }

                setPermissionAllowed(true);
                setAuthorization(false);
            }

            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());

            if (params["token"] !== "Kw047YzjbwoQuA2MQ0toDSQ") {
                setAuthorization(false);

                return;
            }

            if (isIframe) {
                const handler = (event: MessageEvent<unknown>) => {
                    const parsed = schema.safeParse(event.data);

                    if (parsed.success) {
                        const { email } = parsed.data;

                        void authorize(email || null);
                    }
                };

                window.addEventListener("message", handler, false);
                window.parent.postMessage("report-builder-request-current-user", {
                    targetOrigin: "*",
                });

                return () => {
                    window.removeEventListener("message", handler, false);
                };
            } else {
                void authorize(params["email"] ?? null);
            }

            return;
        }, [isIframe, dispatch]);

        return {
            isAuthorization,
            permissionAllowed,
        };
    };
}

export const useAuth = createHook();
