import React from "react";
import type { RootState } from "../../../../../store/store";
import { useAppSelector } from "../../../../../store/store";
import { CreateReportFormGroup } from "../../../form-groups/CreateReportFormGroup";
import { EditReportFormGroup } from "../../../form-groups/EditReportFormGroup";
import { CopyReportFormGroup } from "../../../form-groups/CopyReportFormGroup";
import { DeleteReportPopup } from "../../delete";
import { DraftFormGroup } from "../../../form-groups/DraftFormGroup";
import { ConfirmSaveReportPopup } from "../../confirm";
import { NotReportCreatorWarningPopup } from "../../warning";

export function PopupsContainer() {
    const {
        createReportPopup, //
        createReportColumnsPopup,
        createReportFiltersPopup,

        editReportPopup,
        editReportColumnsPopup,
        editReportFiltersPopup,

        draftFiltersPopup,

        copyReportPopup,

        deleteReportPopup,

        confirmSaveReportPopup,

        notReportCreatorWarningPopup,
    } = useAppSelector((state: RootState) => state.popups);

    return (
        <>
            <CreateReportFormGroup
                isCreateReport={createReportPopup.isOpen}
                isCreateReportColumns={createReportColumnsPopup.isOpen}
                isCreateReportFilters={createReportFiltersPopup.isOpen}
            />

            <EditReportFormGroup
                isEditReport={editReportPopup.isOpen}
                isEditReportColumns={editReportColumnsPopup.isOpen}
                isEditReportFilters={editReportFiltersPopup.isOpen}
            />

            <DraftFormGroup isDraftFilters={draftFiltersPopup.isOpen} />

            <CopyReportFormGroup isCopyReport={copyReportPopup.isOpen} />

            {deleteReportPopup.isOpen && <DeleteReportPopup />}

            {confirmSaveReportPopup.isOpen && <ConfirmSaveReportPopup />}

            {notReportCreatorWarningPopup.isOpen && <NotReportCreatorWarningPopup />}
        </>
    );
}
