import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import type { ReportFormInput, ReportFormOutput } from "src/validation";
import type { RootState } from "../../../../store/store";
import { useAppSelector } from "../../../../store/store";
import invariant from "tiny-invariant";
import { CopyReportPopup } from "../../popups/copy";

function Provider({ children }: ChildrenProps) {
    const { report } = useAppSelector((state: RootState) => state.reports);

    invariant(report);

    /**
     * Create new report name for copy
     */
    const reportName = (() => {
        const isCopy = /^.*Copy$/.test(report.name);

        if (isCopy) {
            return report.name + " - 2";
        }

        const isCopyWithNumber = /^.*Copy\s*-\s*([0-9]+)$/.test(report.name);

        if (isCopyWithNumber) {
            return report.name.replace(/([0-9]+)$/, (_: string, num: string) => {
                return (Number(num) + 1).toString();
            });
        }

        return report.name + " Copy";
    })();

    const methods = useForm<ReportFormInput, unknown, ReportFormOutput>({
        defaultValues: {
            name: reportName,
            sourceId: report.sourceId,
            type: report.type,
            creatorEmail: report.creatorEmail,
            columns: report.columns,
            filters: report.filters,
            API_URL: report.API_URL,
        },
        mode: "onChange",
    });

    return <FormProvider {...methods}>{children}</FormProvider>;
}

function View({
    isCopyReport, //
}: Props) {
    if (!isCopyReport) return null;

    return (
        <Provider>
            <CopyReportPopup />
        </Provider>
    );
}

interface Props {
    isCopyReport: boolean;
}

export const CopyReportFormGroup = React.memo(View);
