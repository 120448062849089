const BASE_URL = "https://compass.apexdigital.online/secure/api/v2/90539";

export const Endpoints = {
    API_CUSTOM_REPORT: `${BASE_URL}/C1C0B52B7998429DA8B71CDF3F713293/Custom Report/`,
    API_REPORT_COLUMN: `${BASE_URL}/C1C0B52B7998429DA8B71CDF3F713293/Report Column/`,
    API_FILTERS_DATA: `${BASE_URL}/21010A68F2704355A624B6D0CE776A2D/`,
    API_USER_DATA: `${BASE_URL}/21010A68F2704355A624B6D0CE776A2D/User/`,
} as const;

export type Endpoints = (typeof Endpoints)[keyof typeof Endpoints];
