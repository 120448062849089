import React from "react";

import classnames from "classnames/bind";

import styles from "./EditReportColumnsPopup.module.scss";
import { BasicPopup } from "../../base";
import { PopupHeader } from "src/view/components/mix/PopupHeader/PopupHeader.com";
import type { ReportColumnsFormOutput } from "../../../forms";
import { ReportColumnsForm } from "../../../forms";
import { usePopups } from "src/view/hooks";
import invariant from "tiny-invariant";
import type { RootState } from "src/store/store";
import { useAppSelector } from "src/store/store";

const cx = classnames.bind(styles);

export function EditReportColumnsPopup() {
    const { actions: popupsActions } = usePopups();

    const { report } = useAppSelector((state: RootState) => state.reports);

    function handleNext() {
        popupsActions.editReportFiltersPopup.open();
    }

    function handleSubmit(values: ReportColumnsFormOutput) {
        invariant(report, "Report should be specified");

        popupsActions.confirmSaveReportPopup.open({
            mode: "edit",
            args: {
                id: report.id,
                name: values.name,
                type: values.type,
                columns: values.columns,
                filters: values.filters,
                API_URL: values.API_URL,
            },
        });
    }

    function handleBack() {
        popupsActions.editReportPopup.open();
    }

    function handleClose() {
        popupsActions.editReportColumnsPopup.close();
    }

    return (
        <BasicPopup
            onClose={handleClose}
            style={{
                height: "100%",
            }}
        >
            <div className={cx("edit-report-columns-popup")}>
                <div className={cx("popup-header")}>
                    <PopupHeader
                        title="Edit Set of Report Columns and Filters"
                        onClose={handleClose}
                    />
                </div>

                <ReportColumnsForm
                    mode="edit"
                    onSubmit={handleSubmit}
                    onNext={handleNext}
                    onBack={handleBack}
                />
            </div>
        </BasicPopup>
    );
}
