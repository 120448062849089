import type { RefObject } from "react";
import React, { createContext, useRef } from "react";
import type { AgGridReact } from "@ag-grid-community/react";
import type { SourceItem } from "../../../core/entities";

export const DataTableContext = createContext<RefObject<AgGridReact<SourceItem<unknown>>>>({
    current: null,
});

export function DataTableProvider({ children }: ChildrenProps) {
    const value = useRef<AgGridReact<SourceItem<unknown>>>(null);

    return <DataTableContext.Provider value={value}>{children}</DataTableContext.Provider>;
}
