import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CreateReportColumnsPopup, CreateReportFiltersPopup, CreateReportPopup } from "../../popups/create";
import type { ReportFormInput, ReportFormOutput } from "src/validation";
import type { RootState } from "src/store/store";
import { useAppSelector } from "src/store/store";
import invariant from "tiny-invariant";
import { FormContextProvider } from "../FormContextProvider";

function Provider({ children }: ChildrenProps) {
    const { user } = useAppSelector((state: RootState) => state.auth);

    invariant(user, "User should be specified");

    const methods = useForm<ReportFormInput, unknown, ReportFormOutput>({
        defaultValues: {
            name: "",
            sourceId: null,
            type: null,
            creatorEmail: user.email,
            columns: [],
            filters: [],
            API_URL: null,
        },
        mode: "onChange",
    });

    return <FormProvider {...methods}>{children}</FormProvider>;
}

function View({
    isCreateReport, //
    isCreateReportColumns,
    isCreateReportFilters,
}: Props) {
    let popup: React.ReactNode;

    if (isCreateReport) {
        popup = <CreateReportPopup />;
    }

    if (isCreateReportColumns) {
        popup = <CreateReportColumnsPopup />;
    }

    if (isCreateReportFilters) {
        popup = <CreateReportFiltersPopup />;
    }

    return <FormContextProvider Provider={Provider}>{popup}</FormContextProvider>;
}

interface Props {
    isCreateReport: boolean;
    isCreateReportColumns: boolean;
    isCreateReportFilters: boolean;
}

export const CreateReportFormGroup = React.memo(View);
