import React, { useCallback, useId, useLayoutEffect, useRef, useState } from "react";

import classnames from "classnames/bind";

import styles from "./BasicMultiSelect.module.scss";

import type {
    CSSObjectWithLabel,
    GroupBase,
    InputActionMeta,
    MenuListProps,
    MenuProps,
    MultiValue,
    OptionProps,
    SelectInstance,
} from "react-select";
import Select, { components, createFilter } from "react-select";
import { FakeCheckbox } from "../../../checkbox";
import { ClearIndicatorBase, DropdownIndicatorBase, NoOptionsMessageBase } from "../../base";
import type { AbstractSelectOption, Visibility } from "../../types";
import { MenuListMulti, MenuMulti, ValueContainerMulti } from "../../multi";
import { selectBaseStyles } from "../../config";

const cx = classnames.bind(styles);

export function BasicMultiSelect<T extends AbstractSelectOption<unknown>>({
    options, //
    value,
    placeholder,
    onChange,
    isSearchable,
    showAllCheckbox,
    maxOptionsToSelect,
}: Props<T>) {
    const [innerValue, setInnerValue] = useState<MultiValue<T>>(value);
    const [inputValue, setInputValue] = useState("");
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [visibility, setVisibility] = useState<Visibility>("all");

    const ref = useRef<SelectInstance<T, true, GroupBase<T>> | null>(null);

    const id = useId();

    useLayoutEffect(() => {
        setInnerValue(value);
    }, [value]);

    const handleMenuClose = useCallback(() => {
        setInnerValue([]);
        setInputValue("");
        setVisibility("all");
        setMenuOpen(false);

        ref.current?.blur();
    }, []);

    /**
     * Handle click outside
     */
    useLayoutEffect(() => {
        function handleMouseDown(e: MouseEvent) {
            const target = e.target as Element | null;

            if (target) {
                const isInside = target.closest("[data-id]")?.getAttribute("data-id") === id;

                if (!isInside) {
                    onChange([...innerValue]);

                    handleMenuClose();
                }
            }
        }

        if (isMenuOpen) {
            document.addEventListener("mousedown", handleMouseDown);

            return () => {
                document.removeEventListener("mousedown", handleMouseDown);
            };
        }

        return;
    }, [id, innerValue, onChange, isMenuOpen, handleMenuClose]);

    /**
     * Handle control click
     */
    useLayoutEffect(() => {
        const control = ref.current?.controlRef;

        function handleMouseDown(event: MouseEvent) {
            const target = event.target as Element | null;

            if (target) {
                const isClear = target.closest("[data-button]")?.getAttribute("data-button") === "clear";

                if (isClear) {
                    onChange([]);

                    handleMenuClose();

                    return;
                }
            }

            if (isMenuOpen) {
                event.stopPropagation();

                onChange([...innerValue]);

                handleMenuClose();
            }
        }

        if (control) {
            control.addEventListener("mousedown", handleMouseDown);

            return () => {
                control.removeEventListener("mousedown", handleMouseDown);
            };
        }

        return;
    }, [isMenuOpen, innerValue, onChange, handleMenuClose]);

    function handleChange(val: MultiValue<T>) {
        if (typeof maxOptionsToSelect === "undefined" || val.length <= maxOptionsToSelect) {
            setInnerValue(val);
        }
    }

    function handleInputChange(newValue: string, actionMeta: InputActionMeta) {
        if (actionMeta.action === "input-change") {
            setInputValue(newValue);
        }
    }

    const MemoizedMenuMulti = useCallback(
        ({ children, ...props }: MenuProps<T, true>) => {
            return (
                <MenuMulti
                    isSearchable={isSearchable}
                    visibility={visibility}
                    onVisibilityChange={(val: Visibility) => {
                        setVisibility(val);
                    }}
                    maxOptionsToSelect={maxOptionsToSelect}
                    {...props}
                >
                    {children}
                </MenuMulti>
            );
        },
        [visibility, setVisibility, maxOptionsToSelect, isSearchable],
    );

    const MemoizedOptionMulti = useCallback(
        ({ ...props }: OptionProps<T, true>) => {
            const { isSelected } = props;

            const { onMouseMove, onMouseOver, ...rest } = props.innerProps;

            const newProps = { ...props, innerProps: rest };

            const hidden = !isSelected && visibility === "selected";

            return (
                <components.Option
                    className={cx("option", {
                        hidden,
                        selected: isSelected,
                    })}
                    {...newProps}
                >
                    <FakeCheckbox
                        checked={props.isSelected}
                        label={props.label}
                    />
                </components.Option>
            );
        },
        [visibility],
    );

    const MemoizedMenuListMulti = useCallback(
        ({ children, ...props }: MenuListProps<T, true>) => {
            return (
                <MenuListMulti
                    showAllCheckbox={showAllCheckbox}
                    {...props}
                >
                    {children}
                </MenuListMulti>
            );
        },
        [showAllCheckbox],
    );

    return (
        <div
            data-id={id}
            className={cx("basic-multi-select")}
        >
            <Select<T, true>
                ref={ref}
                options={options}
                value={innerValue}
                placeholder={placeholder}
                inputValue={inputValue}
                onChange={handleChange}
                onInputChange={handleInputChange}
                filterOption={createFilter({ ignoreAccents: true })}
                onFocus={() => {
                    setMenuOpen(true);
                }}
                menuIsOpen={isMenuOpen}
                isMulti
                unstyled
                isSearchable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                backspaceRemovesValue={false}
                components={{
                    IndicatorSeparator: () => null,
                    Input: () => null,
                    Placeholder: () => null,
                    MultiValue: () => null,
                    NoOptionsMessage: NoOptionsMessageBase,
                    ClearIndicator: ClearIndicatorBase,
                    DropdownIndicator: DropdownIndicatorBase,
                    ValueContainer: ValueContainerMulti,
                    Menu: MemoizedMenuMulti,
                    MenuList: MemoizedMenuListMulti,
                    Option: MemoizedOptionMulti,
                }}
                styles={{
                    ...selectBaseStyles,
                    option(base: CSSObjectWithLabel, props: OptionProps<T, true>) {
                        return {
                            ...base,
                            backgroundColor: "white",
                            padding: "12px 16px",
                            color: "#ADB4BD",
                            cursor: props.isDisabled ? "not-allowed" : "pointer",
                        };
                    },
                }}
                classNames={{
                    menu: () => cx("menu"),
                }}
            />
        </div>
    );
}

interface Props<T extends AbstractSelectOption<unknown>> {
    options: T[];
    value: T[];
    placeholder: string;
    onChange: (options: T[]) => void;
    isSearchable: boolean;
    showAllCheckbox: boolean;
    maxOptionsToSelect?: number;
}
