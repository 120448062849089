import React, { useLayoutEffect, useState } from "react";

export function FormContextProvider({
    Provider, //
    children,
}: Props) {
    const [enabled, setEnabled] = useState(false);

    /**
     * Unmount form provider if all popups closed
     */
    useLayoutEffect(() => {
        if (children) {
            setEnabled(true);

            return;
        }

        const timer = setTimeout(() => {
            setEnabled(false);
        }, 200);

        return () => {
            clearTimeout(timer);
        };
    }, [children]);

    if (enabled) {
        return <Provider>{children}</Provider>;
    }

    return null;
}

interface Props extends ChildrenProps {
    Provider: React.ElementType<ChildrenProps>;
}
