import React, { useCallback, useMemo } from "react";

import classnames from "classnames/bind";

import styles from "./ReportFilters.module.scss";
import type { RootState } from "src/store/store";
import { useAppSelector } from "src/store/store";
import invariant from "tiny-invariant";
import type { ColumnFilterPair, ReportFilter } from "src/core/entities";
import { useReportData, useReports } from "src/view/hooks";
import { DotSpinner } from "../../../../basic/spinners";
import { FilterItem } from "../FilterItem";
import type { OverflowContainerProvided } from "src/packages/overflowed";
import { Overflow, OverflowContainer } from "src/packages/overflowed";

const cx = classnames.bind(styles);

function View() {
    const { actions: reportsActions } = useReports();

    const { draft } = useAppSelector((state: RootState) => state.reports);

    invariant(draft, "Draft should be specified");

    const { pairs, isColumnsFetching } = useReportData(draft);

    const filteredPais = useMemo(() => {
        return pairs.filter((pair: ColumnFilterPair) => pair.filter.pinned);
    }, [pairs]);

    const handleFilterChange = useCallback(
        (filter: ReportFilter) => {
            reportsActions.updateDraftFilter(filter);
        },
        [reportsActions],
    );

    if (isColumnsFetching) {
        return (
            <div className={cx("loading")}>
                <DotSpinner />
            </div>
        );
    }

    if (filteredPais.length === 0) {
        return <div className={cx("no-filters")}>No filters</div>;
    }

    return (
        <div className={cx("report-filters")}>
            <OverflowContainer
                gap={20}
                count={filteredPais.length}
                renderMore={(count: number) => {
                    return <div className={cx("more-filters-label")}>{"... " + count + " more filter(s)"}</div>;
                }}
            >
                {(provided: OverflowContainerProvided) => {
                    return filteredPais.map((pair: ColumnFilterPair, index: number) => {
                        const key = pair.column.id;

                        return (
                            <Overflow
                                key={key}
                                index={index}
                                {...provided}
                            >
                                <FilterItem
                                    pair={pair}
                                    handleFilterChange={handleFilterChange}
                                />
                            </Overflow>
                        );
                    });
                }}
            </OverflowContainer>
        </div>
    );
}

export const ReportFilters = React.memo(View);
