import React from "react";

import classnames from "classnames/bind";

import styles from "./DraftFiltersPopup.module.scss";
import { PopupHeader } from "../../../mix";
import { SidePopup } from "../../base";
import { ReportFiltersForm } from "../../../forms";
import type { ReportFormOutput } from "../../../../../validation";
import type { RootState } from "../../../../../store/store";
import { useAppSelector } from "../../../../../store/store";
import invariant from "tiny-invariant";
import { usePopups, useReports } from "src/view/hooks";

const cx = classnames.bind(styles);

export function DraftFiltersPopup() {
    const { actions: popupsActions } = usePopups();
    const { actions: reportsActions } = useReports();

    const { draft } = useAppSelector((state: RootState) => state.reports);

    function handleSubmit(values: ReportFormOutput) {
        invariant(draft, "Draft should be specifies");

        reportsActions.setDraft({
            ...draft,
            filters: values.filters,
        });

        popupsActions.draftFiltersPopup.close();
    }

    function handleClose() {
        popupsActions.draftFiltersPopup.close();
    }

    return (
        <SidePopup onClose={handleClose}>
            <div className={cx("draft-filters-popup")}>
                <div className={cx("popup-header")}>
                    <PopupHeader
                        title="All Filters"
                        description="Please note: If you want to permanently change report filters (effective for all users and scheduled reports), please use “Edit Report“ instead."
                        onClose={handleClose}
                    />
                </div>

                <ReportFiltersForm
                    mode="all-filters"
                    onSubmit={handleSubmit}
                />
            </div>
        </SidePopup>
    );
}
