import type { FormEvent } from "react";
import React, { useCallback, useDeferredValue, useLayoutEffect, useRef, useState } from "react";

import classnames from "classnames/bind";

import styles from "./ReportFiltersForm.module.scss";
import type { ReportFormInput, ReportFormOutput } from "src/validation";
import { reportFormSchema } from "src/validation";
import type { FieldErrors } from "react-hook-form";
import { useController, useFormContext } from "react-hook-form";
import { ButtonWrapper } from "../../mix";
import { BasicButton, GhostButton, HiddenFormButton } from "../../basic/buttons";
import { DraggableFiltersList } from "./elements/DraggableFiltersList";
import type { Report, ReportFilter } from "../../../../core/entities";
import { DotSpinner } from "../../basic/spinners";
import { useReportData } from "../../../hooks";
import { SearchInput } from "../../basic/inputs/input";
import { getApiUrl } from "../../../../utils/common";

const cx = classnames.bind(styles);

/**
 * Sort filters by prev positions and set new positions from 0 to ...
 */
function updateFiltersPositions(filters: ReportFilter[]) {
    if (filters.length === 0) {
        return filters;
    }

    const sorted = [...filters];

    sorted.sort((a: ReportFilter, b: ReportFilter) => {
        return a.position - b.position;
    });

    return sorted.map((filter: ReportFilter, index: number) => ({
        ...filter,
        position: index,
    }));
}

export function ReportFiltersForm({
    mode, //
    onSubmit,
    onBack,
}: Props) {
    const [isSubmitting, setSubmitting] = useState(false);

    const [searchValue, setSearchValue] = useState("");
    const deferredSearchValue = useDeferredValue(searchValue);

    const formButtonRef = useRef<HTMLButtonElement>(null);

    const {
        control, //
        handleSubmit,
        getValues,
        setValue,
    } = useFormContext<ReportFormInput, unknown, ReportFormOutput>();

    const { columns, pairs, isColumnsLoading, isSourceDataLoading } = useReportData(getValues() as Report, {
        ignoreFilters: true,
    });

    useLayoutEffect(
        () => {
            filters.onChange(updateFiltersPositions(getValues().filters));
        },
        [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const { field: filters } = useController({
        name: "filters",
        control,
        rules: {
            validate: (value: ReportFilter[]) => {
                const parsed = reportFormSchema.filters.safeParse(value);

                if (!parsed.success) {
                    return parsed.error.issues[0]?.message;
                }

                return;
            },
        },
    });

    function handleSearchChange(value: string) {
        setSearchValue(value);
    }

    async function handleSubmitAsync(values: ReportFormOutput) {
        setSubmitting(true);

        try {
            const API_URL = getApiUrl({
                sourceId: values.sourceId,
                columns,
                pairs,
            });

            setValue("API_URL", API_URL);

            await onSubmit({
                ...values,
                API_URL,
            });
        } catch {
            //
        }

        setSubmitting(false);
    }

    const handleReset = useCallback(() => {
        filters.onChange(
            filters.value.map((filter: ReportFilter) => {
                if (filter.value.type === "SELECT" || filter.value.type === "CHECKBOX") {
                    const selectFilter: ReportFilter = {
                        ...filter,
                        pinned: false,
                        value: {
                            ...filter.value,
                            choices: [],
                        },
                    };

                    return selectFilter;
                }

                const anotherFilter: ReportFilter = {
                    ...filter,
                    pinned: false,
                    value: {
                        ...filter.value,
                        choices: null,
                    },
                };

                return anotherFilter;
            }),
        );
    }, [filters]);

    const handleBack = useCallback(() => {
        onBack && onBack();
    }, [onBack]);

    function onValid(values: ReportFormOutput) {
        const updated = updateFiltersPositions(values.filters);

        setValue("filters", updated);

        void handleSubmitAsync({
            ...values,
            filters: updated,
        });
    }

    function onInvalid(errors: FieldErrors<ReportFormOutput>) {
        console.log(errors);
    }

    const handleFiltersOrderChange = useCallback(
        (newFilters: ReportFilter[]) => {
            filters.onChange(newFilters);
        },
        [filters],
    );

    const handleFilterChange = useCallback(
        (filter: ReportFilter) => {
            const foundFilterIndex = filters.value.findIndex((fil: ReportFilter) => fil.id === filter.id);

            if (foundFilterIndex !== -1) {
                const newFilters = [...filters.value];

                newFilters.splice(foundFilterIndex, 1, filter);

                filters.onChange(newFilters);
            }
        },
        [filters],
    );

    const submitButtonTitle = (() => {
        if (mode === "create") {
            return "Create report";
        }

        if (mode === "edit") {
            return "Save report";
        }

        return "Save filters";
    })();

    const isLoading = isColumnsLoading || isSourceDataLoading;

    return (
        <div className={cx("report-filters-form")}>
            <form
                onSubmit={(event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault();

                    void handleSubmit(onValid, onInvalid)(event);
                }}
            >
                <div className={cx("form-header")}>
                    <div className={cx("search-wrapper")}>
                        <SearchInput
                            value={searchValue}
                            placeholder="Search by Filter"
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>

                <div className={cx("filters")}>
                    {isLoading ? (
                        <div className={cx("loading")}>
                            <DotSpinner />
                        </div>
                    ) : (
                        <DraggableFiltersList
                            searchValue={deferredSearchValue}
                            pairs={pairs}
                            onFiltersOrderChange={handleFiltersOrderChange}
                            onFilterChange={handleFilterChange}
                        />
                    )}
                </div>

                <HiddenFormButton ref={formButtonRef} />

                <div className={cx("form-footer")}>
                    <ButtonWrapper shift="left">
                        <BasicButton
                            title={submitButtonTitle}
                            style={{ width: "134px" }}
                            isLoading={isSubmitting}
                            onClick={() => {
                                formButtonRef.current?.click();
                            }}
                        />
                        <GhostButton
                            title="Reset all"
                            style={{ width: "86px", marginLeft: "16px" }}
                            onClick={handleReset}
                        />

                        {mode !== "all-filters" && (
                            <GhostButton
                                title="Back"
                                style={{ width: "86px", marginLeft: "16px" }}
                                onClick={handleBack}
                            />
                        )}
                    </ButtonWrapper>
                </div>
            </form>
        </div>
    );
}

interface Props {
    mode: "create" | "edit" | "all-filters";
    onSubmit: (values: ReportFormOutput) => Promise<void> | void;
    onBack?: () => void;
}
