import Excel from "exceljs";
import fs from "file-saver";
import type { SourceItem, User } from "../../core/entities";
import { formatDateView } from "../helpers";
import { INDEX_COLUMN_NAME } from "../../core/constants";
import type { PinnedRow } from "src/view/hooks";

function autoWidth(worksheet: Excel.Worksheet, minimalWidth: number = 10): void {
    worksheet.columns.forEach((column: Partial<Excel.Column>) => {
        let maxColumnLength = 0;

        if (column.eachCell) {
            column.eachCell({ includeEmpty: true }, (cell: Excel.Cell) => {
                maxColumnLength = Math.max(
                    maxColumnLength, //
                    minimalWidth,
                    cell.value ? String(cell.value).length : 0,
                );
            });

            column.width = maxColumnLength + 4; // eslint-disable-line no-param-reassign
        }
    });
}

interface AppliedFilter {
    name: string;
    values: string[];
}

interface Column {
    name: string;
}

interface Params {
    name: string;
    user: User;
    filters: AppliedFilter[];
    columns: Column[];
    rows: SourceItem<unknown>[];
    bottomRows: PinnedRow[];
}

export async function exportExel(params: Params): Promise<void> {
    const { user, filters, columns, rows, name, bottomRows } = params;

    const today = formatDateView(new Date());

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    /**
     * Title
     */
    const titleRow = worksheet.addRow([name]);

    titleRow.font = {
        bold: true,
        size: 12,
    };

    worksheet.addRow(null);

    /**
     * Info
     */
    worksheet.addRow(["Creator:", user.fullName]);
    worksheet.addRow(["Department:", user.role]);
    worksheet.addRow(["Date Created:", today]);

    worksheet.addRow(null);

    /**
     * Filters
     */
    if (filters.length > 0) {
        const appliedFiltersTitle = worksheet.addRow(["Applied filters:"]);

        appliedFiltersTitle.font = {
            bold: true,
        };

        const filtersNames = filters.map((filter: AppliedFilter) => filter.name);

        const filtersHeaderRow = worksheet.addRow(filtersNames);

        filtersHeaderRow.font = {
            bold: true,
        };

        const filtersRows: Record<string, string>[] = [];

        filters.forEach((filter: AppliedFilter) => {
            filter.values.forEach((value: string, index: number) => {
                if (!filtersRows[index]) {
                    filtersRows.push({});
                }

                filtersRows[index]![filter.name] = value;
            });
        });

        filtersRows.forEach((row: Record<string, string>) => {
            worksheet.addRow(
                filtersNames.map((key: string) => {
                    return row[key];
                }),
            );
        });

        worksheet.addRow(null);
    }

    /**
     * Data
     */
    const columnsNames = columns.map((col: Column) => col.name);

    const columnsHeaderRow = worksheet.addRow(columnsNames);

    /**
     * Rows
     */
    rows.forEach((row: SourceItem<unknown>, index: number) => {
        const dataRow = worksheet.addRow(
            columnsNames.map((key: string) => {
                if (key === INDEX_COLUMN_NAME) {
                    return index + 1;
                }

                return row[key]?.value ?? "";
            }),
        );

        dataRow.alignment = {
            horizontal: "left",
        };
    });

    /**
     * Bottom rows
     */
    bottomRows.forEach((row: PinnedRow) => {
        const dataRow = worksheet.addRow(
            columnsNames.map((key: string) => {
                const value = row[key]?.value;

                if (typeof value !== "string") {
                    return "";
                }

                const parsed = parseFloat(value.replace(/[^0-9.]/g, ""));

                if (Number.isNaN(parsed)) {
                    return value;
                }

                return parsed;
            }),
        );

        dataRow.alignment = {
            horizontal: "left",
        };
    });

    columnsHeaderRow.font = {
        bold: true,
    };

    worksheet.addRow(null);

    /**
     * Footer
     */
    worksheet.addRow(["Marriott Proprietary & Confidential Information"]);

    autoWidth(worksheet);

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const fileName = "Report " + name + " " + today;

    fs.saveAs(blob, fileName);
}
