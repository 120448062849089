import React, { useCallback, useState } from "react";

import classnames from "classnames/bind";

import { DatePickerControl, DatePickerMenu } from "./elements";
import type { ReportFilterDateChoices } from "src/core/entities";

import styles from "./DatePickerExtended.module.scss";

const cx = classnames.bind(styles);

function View({
    value, //
    onChange,
    placeholder,
}: Props) {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const handleMenuOpen = useCallback(() => {
        setMenuOpen(true);
    }, []);

    const handleMenuClose = useCallback(() => {
        setMenuOpen(false);
    }, []);

    const handleApply = useCallback(
        (val: ReportFilterDateChoices) => {
            onChange(val);

            setMenuOpen(false);
        },
        [onChange],
    );

    const handleClear = useCallback(() => {
        onChange(null);
    }, [onChange]);

    return (
        <div className={cx("date-picker-extended")}>
            <DatePickerControl
                value={value}
                placeholder={placeholder}
                isMenuOpen={isMenuOpen}
                onMenuOpen={handleMenuOpen}
                onClear={handleClear}
            />

            {isMenuOpen && (
                <DatePickerMenu
                    value={value}
                    placeholder={placeholder ?? "Select date"}
                    onApply={handleApply}
                    onCancel={handleMenuClose}
                    onMenuClose={handleMenuClose}
                />
            )}
        </div>
    );
}

interface Props {
    value: ReportFilterDateChoices;
    onChange: (value: ReportFilterDateChoices) => void;
    placeholder?: string;
}

export const DatePickerExtended = React.memo(View);
