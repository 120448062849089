import React from "react";

import styles from "./CheckboxIcon.module.scss";
import classnames from "classnames/bind";

import { ReactComponent as CheckIcon } from "src/assets/icons/checkbox-icon.svg";

const cx = classnames.bind(styles);

export function CheckboxIcon({
    checked, //
    disabled = false,
}: Props) {
    return (
        <div
            className={cx("checkbox-icon", {
                "checked": checked === true,
                "unchecked": checked === false,
                "half-checked": checked === "half-checked",
                disabled,
            })}
        >
            {checked === "half-checked" ? <div className={cx("unchecked-view")} /> : <CheckIcon />}
        </div>
    );
}

interface Props {
    checked: boolean | "half-checked";
    disabled?: boolean;
}
