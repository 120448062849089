import React from "react";

import classnames from "classnames/bind";

import styles from "./Popup.module.scss";
import ReactDOM from "react-dom";

const cx = classnames.bind(styles);

const portal = document.getElementById("popups");

export function Popup({
    onClose, //
    children,
}: Props) {
    return ReactDOM.createPortal(
        <div className={cx("popup")}>
            <div
                role="button"
                tabIndex={0}
                className={cx("bg")}
                onClick={onClose}
            />
            <div
                id="event-confirm-dialog"
                className={cx("event-confirm-dialog")}
            />

            {children}
        </div>,
        portal!,
    );
}

interface Props extends ChildrenProps {
    onClose: () => void;
}
