import React, { useState } from "react";

import classnames from "classnames/bind";

import styles from "./ColumnList.module.scss";
import type { ReportColumn, ReportFilter, SourceColumn } from "src/core/entities";
import { BasicCheckbox, SlideCheckbox } from "../../../../basic/inputs/checkbox";
import { SearchInput } from "../../../../basic/inputs/input";
import { InfoTooltip } from "../../../../tooltips";

import { ReactComponent as CheckIcon } from "src/assets/icons/checkbox-icon.svg";
import { ReactComponent as DeselectIcon } from "src/assets/icons/deselect-icon.svg";

const cx = classnames.bind(styles);

const TOOLTIP_ID = "column-selector-tooltip-id";

export function ColumnList({
    options, //
    values,
    onColumnChange,
    onFilterChange,
    onShowTotalsChange,
    onShowAveragesChange,
    onCheckAll,
}: Props) {
    const [searchValue, setSearchValue] = useState<string>("");

    const filteredOptions = options.filter((column: SourceColumn) => {
        return column.name.toLowerCase().includes(searchValue.trim().toLowerCase());
    });

    function handleColumnChange(checked: boolean, column: SourceColumn) {
        onColumnChange(checked, column);
    }

    function handleFilterChange(checked: boolean, column: SourceColumn) {
        onFilterChange(checked, column);
    }

    function handleShowTotalsChange(checked: boolean, column: SourceColumn) {
        onShowTotalsChange(checked, column);
    }

    function handleShowAveragesChange(checked: boolean, column: SourceColumn) {
        onShowAveragesChange(checked, column);
    }

    function handleSelectAllVisible() {
        const newColumns: SourceColumn[] = [];

        for (const column of options) {
            const foundPrevCheckedColumn = values.columns.find((col: ReportColumn) => {
                return col.id === column.id;
            });

            const foundColumnToCheck = filteredOptions.find((col: SourceColumn) => {
                return col.id === column.id;
            });

            if (foundPrevCheckedColumn !== undefined || foundColumnToCheck !== undefined) {
                newColumns.push(column);
            }
        }

        onCheckAll(newColumns);
    }

    function handleDeselectAllVisible() {
        const prevColumns: SourceColumn[] = [];

        for (const column of options) {
            const foundPrevCheckedColumn = values.columns.find((col: ReportColumn) => {
                return col.id === column.id;
            });

            if (foundPrevCheckedColumn) {
                prevColumns.push(column);
            }
        }

        const newColumns = prevColumns.filter((column: SourceColumn) => {
            const foundColumnToUncheck = filteredOptions.find((col: SourceColumn) => {
                return col.id === column.id;
            });

            return !foundColumnToUncheck;
        });

        onCheckAll(newColumns);
    }

    return (
        <div className={cx("column-list")}>
            <div className={cx("header-wrapper")}>
                <div className={cx("header")}>
                    <div className={cx("search-wrapper")}>
                        <SearchInput
                            value={searchValue}
                            placeholder="Search by Column"
                            onChange={(val: string) => {
                                setSearchValue(val);
                            }}
                        />
                    </div>
                    <div className={cx("selectors")}>
                        {filteredOptions.length > 0 && (
                            <>
                                <button
                                    type="button"
                                    className={cx("select-all")}
                                    onClick={handleSelectAllVisible}
                                >
                                    <div className={cx("icon-wrapper")}>
                                        <CheckIcon
                                            width={12}
                                            height={12}
                                        />
                                    </div>
                                    Select all visible
                                </button>
                                <button
                                    type="button"
                                    className={cx("deselect-all")}
                                    onClick={handleDeselectAllVisible}
                                >
                                    <div className={cx("icon-wrapper")}>
                                        <DeselectIcon
                                            width={13}
                                            height={12}
                                        />
                                    </div>
                                    Deselect all visible
                                </button>
                            </>
                        )}
                    </div>
                </div>

                {filteredOptions.length > 0 ? (
                    <div className={cx("column-list-header")}>
                        <div className={cx("side")}>
                            <span>Columns</span>
                            <span>Filters</span>
                        </div>

                        {filteredOptions.length > 1 && (
                            <div className={cx("side")}>
                                <span>Columns</span>
                                <span>Filters</span>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={cx("no-columns")}>No columns found</div>
                )}
            </div>

            <div className={cx("list-wrapper")}>
                <div className={cx("list")}>
                    {filteredOptions.map((column: SourceColumn) => {
                        const key = column.id;

                        const isColumnChecked = values.columns.some((col: ReportColumn) => col.id === column.id);
                        const isFilterChecked = values.filters.some((fil: ReportFilter) => fil.id === column.id);

                        const isShowTotals = !!values.columns.find(
                            (col: ReportColumn) => col.id === column.id && col.showTotals === true,
                        );
                        const isShowAverages = !!values.columns.find(
                            (col: ReportColumn) => col.id === column.id && col.showAverages === true,
                        );

                        return (
                            <div
                                data-tooltip-id={TOOLTIP_ID}
                                data-tooltip-content={column.name}
                                key={key}
                                className={cx("column")}
                            >
                                <div className={cx("main-part")}>
                                    <div className={cx("column-checkbox")}>
                                        <BasicCheckbox
                                            checked={isColumnChecked}
                                            renderLabel={() => {
                                                return <div className={cx("column-name")}>{column.name}</div>;
                                            }}
                                            onChange={(checked: boolean) => {
                                                handleColumnChange(checked, column);
                                            }}
                                        />
                                    </div>

                                    {column.filterable && (
                                        <div className={cx("filter-checkbox")}>
                                            <SlideCheckbox
                                                checked={isFilterChecked}
                                                onChange={(checked: boolean) => {
                                                    handleFilterChange(checked, column);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className={cx("extra-part")}>
                                    {column.type === "NUMERIC" && isColumnChecked && (
                                        <div className={cx("numeric-options")}>
                                            <div className={cx("numeric-option-checkbox")}>
                                                <BasicCheckbox
                                                    checked={isShowTotals}
                                                    renderLabel={() => {
                                                        return (
                                                            <div className={cx("numeric-option-checkbox-label")}>
                                                                Show Totals
                                                            </div>
                                                        );
                                                    }}
                                                    onChange={(checked: boolean) => {
                                                        handleShowTotalsChange(checked, column);
                                                    }}
                                                />
                                            </div>
                                            <div className={cx("numeric-option-checkbox")}>
                                                <BasicCheckbox
                                                    checked={isShowAverages}
                                                    renderLabel={() => {
                                                        return (
                                                            <div className={cx("numeric-option-checkbox-label")}>
                                                                Show Averages
                                                            </div>
                                                        );
                                                    }}
                                                    onChange={(checked: boolean) => {
                                                        handleShowAveragesChange(checked, column);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <InfoTooltip
                id={TOOLTIP_ID}
                place="top-start"
                borders={[8, 8, 8, 0]}
                offset={2}
            />
        </div>
    );
}

interface Props {
    options: SourceColumn[];
    values: {
        columns: ReportColumn[];
        filters: ReportFilter[];
    };
    onColumnChange: (checked: boolean, column: SourceColumn) => void;
    onFilterChange: (checked: boolean, column: SourceColumn) => void;
    onShowTotalsChange: (checked: boolean, column: SourceColumn) => void;
    onShowAveragesChange: (checked: boolean, column: SourceColumn) => void;
    onCheckAll: (columns: SourceColumn[]) => void;
}
