import React, { useCallback } from "react";

import classnames from "classnames/bind";

import styles from "./ReportBuilderHeader.module.scss";
import { ReportFilters } from "./elements/ReportFilters";
import { ReportMenu } from "./elements/ReportMenu";
import { SearchInput } from "../../basic/inputs/input";
import { BasicButton, FiltersButton } from "../../basic/buttons";
import { InfoTooltip } from "../../tooltips";

import { ReactComponent as GetFilePDFIcon } from "src/assets/icons/get-file-pdf-icon.svg";
import { ReactComponent as ExcelTableIcon } from "src/assets/icons/excel-table-icon.svg";

import { useExports, usePopups } from "src/view/hooks";

const cx = classnames.bind(styles);

const TOOLTIP_ID = "all-filters";

function View({
    searchValue, //
    onSearchChange,
}: Props) {
    const { actions: popupsActions } = usePopups();
    const { services: exportsServices } = useExports();

    const handleFiltersClick = useCallback(() => {
        popupsActions.draftFiltersPopup.open();
    }, [popupsActions]);

    const handleExportExcel = useCallback(() => {
        exportsServices.exportToExcel();
    }, [exportsServices]);

    const handleExportPdf = useCallback(() => {
        exportsServices.exportToPdf();
    }, [exportsServices]);

    return (
        <div className={cx("report-builder-header")}>
            <ReportFilters />

            <div className={cx("controls")}>
                <SearchInput
                    value={searchValue}
                    placeholder="Search by keyword"
                    onChange={(value: string) => {
                        onSearchChange(value);
                    }}
                />

                <FiltersButton
                    data-tooltip-id={TOOLTIP_ID}
                    data-tooltip-content="Show and change filters"
                    onClick={handleFiltersClick}
                />

                <ReportMenu />

                <BasicButton
                    data-tooltip-id={TOOLTIP_ID}
                    data-tooltip-content="Export to PDF"
                    icon={
                        <GetFilePDFIcon
                            width={21}
                            height={21}
                        />
                    }
                    title=""
                    style={{
                        width: 40,
                    }}
                    onClick={handleExportPdf}
                />

                <BasicButton
                    data-tooltip-id={TOOLTIP_ID}
                    data-tooltip-content="Export to Excel"
                    icon={
                        <ExcelTableIcon
                            width={21}
                            height={21}
                        />
                    }
                    title=""
                    style={{
                        width: 40,
                    }}
                    onClick={handleExportExcel}
                />
            </div>

            <InfoTooltip
                id={TOOLTIP_ID}
                place="bottom-end"
                borders={[8, 0, 8, 8]}
                offset={6}
            />
        </div>
    );
}

interface Props {
    searchValue: string;
    onSearchChange: (value: string) => void;
}

export const ReportBuilderHeader = React.memo(View);
