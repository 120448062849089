import React from "react";
import styles from "./PopupHeader.module.scss";
import classnames from "classnames/bind";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";

const cx = classnames.bind(styles);

interface IProps {
    title: string;
    onClose?: () => void;
    description?: string;
}

function View({ title, onClose, description }: IProps) {
    return (
        <div className={cx("popup-header")}>
            <div className={cx("popup-title-wrapper")}>
                <span className={cx("popup-title")}>{title}</span>
                <button
                    type="button"
                    onClick={onClose}
                >
                    <CloseIcon />
                </button>
            </div>
            {description && (
                <span className={cx("popup-description")}>
                    {description}
                </span>
            )}
        </div>
    );
}

export const PopupHeader = React.memo(View);
