import React, { useId } from "react";
import classnames from "classnames/bind";
import styles from "./SlideCheckbox.module.scss";

const cx = classnames.bind(styles);

export function SlideCheckbox({ checked, disabled, onChange, ...attributes }: Props) {
    const inputId = useId();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!disabled) {
            onChange(event.target.checked, event);
        }
    };

    return (
        <div className={cx("slide-checkbox")}>
            <label
                htmlFor={inputId}
                className={cx({
                    disabled,
                })}
            >
                <input
                    id={inputId}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                    {...attributes}
                />

                <div className={cx("checkbox-wrapper")}>
                    <div className={cx("circle")} />
                </div>
            </label>
        </div>
    );
}

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    checked: boolean;
    onChange: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
}
