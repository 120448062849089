import React from "react";

import { ToastContainer } from "react-toastify";

export function Notificator() {
    return (
        <ToastContainer
            position="top-right"
            autoClose={10000}
            hideProgressBar
            closeOnClick
            pauseOnFocusLoss
            draggable
            bodyStyle={{
                fontSize: 12,
                lineHeight: 1.5,
                whiteSpace: "pre-line",
            }}
            pauseOnHover
        />
    );
}
