import React from "react";

import classnames from "classnames/bind";

import styles from "./CreateReportColumnsPopup.module.scss";
import { BasicPopup } from "../../base";
import type { ReportColumnsFormOutput } from "../../../forms";
import { ReportColumnsForm } from "../../../forms";
import { PopupHeader } from "../../../mix";
import { usePopups } from "src/view/hooks";

const cx = classnames.bind(styles);

export function CreateReportColumnsPopup() {
    const { actions: popupsActions } = usePopups();

    function handleNext() {
        popupsActions.createReportFiltersPopup.open();
    }

    function handleSubmit(values: ReportColumnsFormOutput) {
        popupsActions.confirmSaveReportPopup.open({
            mode: "create",
            args: {
                name: values.name,
                sourceId: values.sourceId,
                type: values.type,
                creatorEmail: values.creatorEmail,
                columns: values.columns,
                filters: values.filters,
                API_URL: values.API_URL,
            },
        });
    }

    function handleBack() {
        popupsActions.createReportPopup.open();
    }

    function handleClose() {
        popupsActions.createReportColumnsPopup.close();
    }

    return (
        <BasicPopup
            onClose={handleClose}
            style={{
                height: "100%",
            }}
        >
            <div className={cx("create-report-columns-popup")}>
                <div className={cx("popup-header")}>
                    <PopupHeader
                        title="Column Selector"
                        onClose={handleClose}
                    />
                </div>

                <ReportColumnsForm
                    mode="create"
                    onSubmit={handleSubmit}
                    onNext={handleNext}
                    onBack={handleBack}
                />
            </div>
        </BasicPopup>
    );
}
