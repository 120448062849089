import type { ButtonHTMLAttributes, MouseEventHandler } from "react";
import React from "react";

import classnames from "classnames/bind";

import styles from "./TextButton.module.scss";

const cx = classnames.bind(styles);

function View({
    label, //
    onClick,
    className,
    ...attributes
}: Props) {
    return (
        <button
            type="button"
            className={cx("text-button", className)}
            onClick={onClick}
            {...attributes}
        >
            {label}
        </button>
    );
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

export const TextButton = React.memo(View);
