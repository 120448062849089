import type { Report, ReportColumn, ReportFilter } from "src/core/entities";
import { useAppDispatch } from "src/store/store";
import { reportsSlice } from "../../store/slices";
import { useMemo } from "react";

function createHook() {
    const {
        setReport, //
        setDraft,
        setReportColumns,
        setDraftColumns,
        updateDraftColumn,
        updateDraftFilter,
    } = reportsSlice.actions;

    return function useReports() {
        const dispatch = useAppDispatch();

        const actions = useMemo(
            () => ({
                setReport: (report: Report | null) => {
                    dispatch(setReport(report));
                },
                setDraft: (report: Report | null) => {
                    dispatch(setDraft(report));
                },
                setReportColumns: (columns: ReportColumn[]) => {
                    dispatch(setReportColumns(columns));
                },
                setDraftColumns: (columns: ReportColumn[]) => {
                    dispatch(setDraftColumns(columns));
                },
                updateDraftColumn: (column: ReportColumn) => {
                    dispatch(updateDraftColumn(column));
                },
                updateDraftFilter: (filter: ReportFilter) => {
                    dispatch(updateDraftFilter(filter));
                },
            }),
            [dispatch],
        );

        return {
            actions,
        };
    };
}

export const useReports = createHook();
