import { useElementSize } from "../hooks";
import React, { useCallback, useMemo, useState } from "react";
import type { OverflowContainerProvided } from "../types";

interface Data {
    width: number;
    index: number;
}

export function OverflowContainer({
    gap, //
    count,
    renderMore,
    children,
}: Props) {
    const [data, setData] = useState<Data[]>([]);

    const { elementRef: containerRef, size: containerSize } = useElementSize();
    const { elementRef: moreRef, size: moreSize } = useElementSize();

    const getData = useCallback(
        (width: number, index: number) => {
            setData((prev: Data[]) => {
                const list = [...prev];

                list.length = count;

                list[index] = {
                    width,
                    index,
                };

                return list;
            });
        },
        [count],
    );

    const items = useMemo(() => {
        const newItems: boolean[] = [];

        let totalWidth = moreSize.width;

        for (let i = 0; i < data.length; i++) {
            totalWidth += data[i]!.width + gap;

            const visible = totalWidth <= containerSize.width;

            if (visible) {
                newItems[i] = true;
            }
        }

        return newItems;
    }, [data, containerSize, moreSize, gap]);

    const more = count - items.length;

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                gap,
            }}
            ref={containerRef}
        >
            {children({
                getData,
                items,
            })}

            <div
                style={{
                    width: "fit-content",
                }}
                ref={moreRef}
            >
                {more > 0 && renderMore(more, items.length)}{" "}
            </div>
        </div>
    );
}

interface Props {
    children: (provided: OverflowContainerProvided) => React.ReactNode;
    gap: number;
    count: number;
    renderMore: (count: number, total: number) => React.ReactNode;
}
