import { useMemo } from "react";

import type { ToastOptions } from "react-toastify";
import { toast } from "react-toastify";

function createNotificationsHook() {
    return function useNotifications() {
        const notify = useMemo(() => {
            return {
                info: (message: string, options?: ToastOptions) => {
                    toast.info(message, options);
                },
                warn: (message: string, options?: ToastOptions) => {
                    toast.warn(message, options);
                },
                error: (message: string, options?: ToastOptions) => {
                    toast.error(message, options);
                },
            };
        }, []);

        return {
            notify,
        };
    };
}

export const useNotifications = createNotificationsHook();
