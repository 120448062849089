import { jsPDF } from "jspdf";
import type { SourceItem, User } from "src/core/entities";
import type { Styles } from "jspdf-autotable";
import AutoTable from "jspdf-autotable";
import { formatDateView } from "../helpers";
import { INDEX_COLUMN_NAME } from "../../core/constants";
import type { PinnedRow } from "src/view/hooks";

const MAX_COLUMNS_PER_PAGE = 15;
const DEFAULT_FONT_SIZE = 8;
const MIN_COLUMN_WIDTH = 17;

const MARGINS = {
    left: 8,
    right: 8,
};

interface AppliedFilter {
    name: string;
    values: string[];
}

interface Column {
    name: string;
}

interface Params {
    name: string;
    user: User;
    filters: AppliedFilter[];
    columns: Column[];
    rows: SourceItem<unknown>[];
    bottomRows: PinnedRow[];
}

function calculateColumnWidth(doc: jsPDF, numColumns: number): number {
    const pageWidth = doc.internal.pageSize.width;

    const columnWidth = (pageWidth - MARGINS.left - MARGINS.right) / numColumns;

    return Math.max(columnWidth, MIN_COLUMN_WIDTH);
}

function splitArray<T>(arr: T[], chunkSize: number) {
    const chunks = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);

        chunks.push(chunk);
    }

    return chunks;
}

function createTitle(doc: jsPDF, positionY: number) {
    //

    return (name: string) => {
        const top = positionY + 16;

        doc.setFontSize(14);

        doc.text(name, doc.internal.pageSize.width / 2, top, {
            align: "center",
        });

        doc.setFontSize(DEFAULT_FONT_SIZE);

        return (
            top +
            doc.getTextDimensions(name, {
                fontSize: 14,
            }).h
        );
    };
}

function createInfo(doc: jsPDF, positionY: number) {
    interface InfoRecord {
        key: string;
        value: string;
    }

    return (user: User) => {
        let newPositionY = positionY;

        const today = formatDateView(new Date());

        const margin = 4;

        const { width: pageWidth } = doc.internal.pageSize;

        doc.setFontSize(DEFAULT_FONT_SIZE);

        let blockWidth = 0;

        [
            {
                key: "Creator:",
                value: user.fullName,
            },
            {
                key: "Department:",
                value: user.role,
            },
            {
                key: "Date created:",
                value: today,
            },
        ].forEach((info: InfoRecord) => {
            const text = info.key + " " + info.value;

            doc.setFont("helvetica", "bold");
            const { w: keyWidth } = doc.getTextDimensions(info.key, {
                fontSize: DEFAULT_FONT_SIZE,
            });

            doc.setFont("helvetica", "normal");
            const { w: valueWidth } = doc.getTextDimensions(info.value, {
                fontSize: DEFAULT_FONT_SIZE,
            });

            const fullWidth = keyWidth + 2 + valueWidth;

            if (fullWidth > blockWidth) {
                blockWidth = fullWidth;
            }

            doc.text(info.value, pageWidth - MARGINS.right - valueWidth, newPositionY);

            doc.setFont("helvetica", "bold");
            doc.text(info.key, pageWidth - MARGINS.right - fullWidth, newPositionY);

            doc.setFont("helvetica", "normal");

            newPositionY +=
                doc.getTextDimensions(text, {
                    fontSize: DEFAULT_FONT_SIZE,
                }).h + margin;
        });

        return {
            positionY: newPositionY - margin,
            blockWidth,
        };
    };
}

function createFilters(doc: jsPDF, positionY: number, infoBlockWidth: number) {
    type SplitText = string | string[];

    return (filters: AppliedFilter[]) => {
        if (filters.length === 0) return positionY;

        let newPositionY = positionY;

        const margin = 4;

        const { width: pageWidth } = doc.internal.pageSize;

        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);

        doc.text("Applied filters:", MARGINS.left, newPositionY);

        newPositionY +=
            doc.getTextDimensions("Applied filters:", {
                fontSize: 10,
            }).h + margin;

        doc.setFont("helvetica", "normal");
        doc.setFontSize(DEFAULT_FONT_SIZE);

        filters.forEach((filter: AppliedFilter) => {
            const filterName = filter.name + ":";
            const filterValue = filter.values.join(", ");

            doc.setFont("helvetica", "bold");

            const { w: nameWidth } = doc.getTextDimensions(filterName, {
                fontSize: DEFAULT_FONT_SIZE,
            });

            doc.text(filterName, MARGINS.left, newPositionY);

            doc.setFont("helvetica", "normal");

            const splitFilterValue: unknown = doc.splitTextToSize(
                filterValue,
                pageWidth - MARGINS.left - MARGINS.right - infoBlockWidth - nameWidth - 2 - 10,
            );

            if (typeof splitFilterValue === "string" || Array.isArray(splitFilterValue)) {
                doc.text(splitFilterValue as SplitText, MARGINS.left + nameWidth + 2, newPositionY);

                newPositionY += doc.getTextDimensions(splitFilterValue as string).h + margin;
            }
        });

        return newPositionY - margin;
    };
}

function createTable(doc: jsPDF, positionY: number) {
    //

    return (columns: Column[], rows: SourceItem<unknown>[], bottomRows: PinnedRow[]) => {
        const { width: pageWidth, height: pageHeight } = doc.internal.pageSize;

        const columnParts = splitArray(columns, MAX_COLUMNS_PER_PAGE);

        columnParts.forEach((chunk: Column[], index: number) => {
            if (index > 0) {
                doc.addPage();
            }

            const headers = chunk.map((col: Column) => col.name);

            const body = rows.map((row: SourceItem<unknown>, i: number) => {
                return chunk.map((col: Column) => {
                    if (col.name === INDEX_COLUMN_NAME) {
                        return i + 1;
                    }

                    return row[col.name]?.value ?? "";
                });
            });

            bottomRows.forEach((row: PinnedRow) => {
                body.push(
                    chunk.map((col: Column) => {
                        return row[col.name]?.value ?? "";
                    }),
                );
            });

            const columnWidth = calculateColumnWidth(doc, chunk.length);

            const columnStyles: Record<string, Partial<Styles>> = {};

            chunk.forEach((_: Column, k: number) => {
                columnStyles[k] = {
                    cellWidth: columnWidth,
                };
            });

            AutoTable(doc, {
                startY: index > 0 ? 10 : positionY,
                styles: { fontSize: 6 },
                columnStyles,
                headStyles: { fillColor: [0, 164, 180], textColor: 255 },
                head: [headers],
                margin: {
                    top: 10,
                    left: MARGINS.left,
                    right: MARGINS.right,
                },
                body,
                didDrawPage: () => {
                    doc.setFontSize(6);

                    const part = index + 1;
                    const total = columnParts.length;

                    const text = `Part ${part} of ${total}`;

                    const textWidth = doc.getTextDimensions(text, {
                        fontSize: 6,
                    }).w;

                    doc.text(text, pageWidth - MARGINS.right - textWidth, pageHeight - 10);
                },
            });
        });
    };
}

function createFooter(doc: jsPDF) {
    const { width: pageWidth, height: pageHeight } = doc.internal.pageSize;

    const count = doc.getNumberOfPages();

    for (let i = 1; i <= count; i++) {
        doc.setPage(i);
        doc.setFontSize(6);

        doc.text(`Page ${i}`, pageWidth / 2, pageHeight - 10, {
            align: "center",
        });

        doc.setFontSize(5);
        doc.text("Marriott Proprietary & Confidential Information", pageWidth / 2, pageHeight - 7, {
            align: "center",
        });
    }
}

export function exportPDF(params: Params): void {
    const { name, user, filters, columns, rows, bottomRows } = params;

    const today = formatDateView(new Date());

    const doc = new jsPDF({
        orientation: "landscape",
    });

    doc.setFont("helvetica", "normal");

    let positionY = 0;

    /**
     * Title
     */
    positionY = createTitle(doc, positionY)(name);

    positionY += 6;

    /**
     * Info
     */
    const { positionY: infoPositionY, blockWidth: infoBlockWidth } = createInfo(doc, positionY)(user);

    /**
     * Filters
     */
    const filtersPositionY = createFilters(doc, positionY, infoBlockWidth)(filters);

    positionY = Math.max(infoPositionY, filtersPositionY) + 4;

    /**
     * Data
     */
    createTable(doc, positionY)(columns, rows, bottomRows);

    /**
     * Footer
     */
    createFooter(doc);

    const fileName = "Report " + name + " " + today;

    doc.save(fileName);
}
