import type { Source } from "src/core/entities/Source";
import { SourceType } from "src/core/enums";

const sourceByType: Record<string, Source> = {
    [SourceType.PROJECT]: {
        name: SourceType.PROJECT,
        table: "Project",
        view: "API Data Warehouse",
    },
    [SourceType.SITE]: {
        name: SourceType.SITE,
        table: "Site",
        view: "API View",
    },
    [SourceType.TRAVEL_REQUEST]: {
        name: SourceType.TRAVEL_REQUEST,
        table: "Travel Request",
        view: "API View",
    },
    [SourceType.TICKET]: {
        name: SourceType.TICKET,
        table: "Ticket",
        view: "API View",
    },
    [SourceType.TASKS]: {
        name: SourceType.TASKS,
        table: "Project Task",
        view: "API",
    },
};

export function getReportSource(sourceId: string): Source {
    const source = sourceByType[sourceId];

    if (!source) {
        throw new Error("Source is not defined");
    }

    return source;
}
