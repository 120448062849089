import React from "react";
import type { MenuListProps, OnChangeValue } from "react-select";
import { components } from "react-select";
import { FakeCheckbox } from "../../../checkbox";
import type { AbstractSelectOption } from "../../types";

import classnames from "classnames/bind";

import styles from "./MenuListMulti.module.scss";

const cx = classnames.bind(styles);

export function MenuListMulti<T extends AbstractSelectOption<unknown>>({
    children,
    showAllCheckbox,
    ...props
}: Props<T>) {
    const isAllChecked = props.getValue().length === props.options.length;
    const isAllUnchecked = props.getValue().length === 0;

    return (
        <components.MenuList {...props}>
            {showAllCheckbox && props.options.length > 0 && (
                <div
                    role="button"
                    tabIndex={0}
                    className={cx("all-checkbox", {
                        selected: !isAllUnchecked,
                    })}
                    onClick={() => {
                        if (isAllChecked) {
                            props.clearValue();

                            return;
                        }

                        props.setValue(props.options as OnChangeValue<T, true>, "select-option");
                    }}
                >
                    <FakeCheckbox
                        checked={isAllChecked ? true : isAllUnchecked ? false : "half-checked"}
                        label="All"
                    />
                </div>
            )}

            {children}
        </components.MenuList>
    );
}

interface Props<T extends AbstractSelectOption<unknown>> extends MenuListProps<T, true> {
    showAllCheckbox: boolean;
}
