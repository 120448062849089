import { iframeSlice } from "../../store/slices";
import { inIframe } from "../../utils/helpers";
import { useAppDispatch } from "../../store/store";
import { useMemo } from "react";

function createHook() {
    const { setFullscreen } = iframeSlice.actions;

    return function useIframe() {
        const dispatch = useAppDispatch();

        const actions = useMemo(
            () => ({
                toggleFullscreen: (isFullscreen: boolean) => {
                    dispatch(setFullscreen(isFullscreen));
                },
            }),
            [dispatch],
        );

        return {
            isIframe: inIframe(),
            actions,
        };
    };
}

export const useIframe = createHook();
