import type { ChangeEvent, InputHTMLAttributes } from "react";
import React, { useId } from "react";

import classnames from "classnames/bind";

import styles from "./BasicNumberInput.module.scss";

const cx = classnames.bind(styles);

const pattern = /^\d*$/;

export function BasicNumberInput({
    value, //
    onChange,
    label,
    isError,
    ...attributes
}: Props) {
    const inputId = useId();

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const { target } = event;

        if (target.value.trim() === "") {
            onChange(null);

            return;
        }

        if (pattern.test(target.value)) {
            onChange(+target.value);
        }
    }

    return (
        <div className={cx("basic-number-input")}>
            <label htmlFor={inputId}>
                {label && <div className={cx("input-label")}>{label}</div>}

                <input
                    id={inputId}
                    type="text"
                    className={cx({
                        error: isError,
                    })}
                    value={value ?? ""}
                    onChange={handleChange}
                    autoComplete="off"
                    aria-autocomplete="none"
                    {...attributes}
                />
            </label>
        </div>
    );
}

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
    value: number | null;
    onChange: (value: number | null) => void;
    label?: string;
    isError?: boolean;
}
