export const DatePeriod = {
    CURRENT_DAY: "CURRENT_DAY",
    THIS_WEEK: "THIS_WEEK",
    THIS_MONTH: "THIS_MONTH",
    THIS_QUARTER: "THIS_QUARTER",
    THIS_YEAR: "THIS_YEAR",
    LAST_WEEK: "LAST_WEEK",
    LAST_MONTH: "LAST_MONTH",
    LAST_QUARTER: "LAST_QUARTER",
    LAST_YEAR: "LAST_YEAR",
    NEXT_WEEK: "NEXT_WEEK",
    NEXT_MONTH: "NEXT_MONTH",
    NEXT_QUARTER: "NEXT_QUARTER",
    NEXT_YEAR: "NEXT_YEAR",
    DAYS_IN_FUTURE: "DAYS_IN_FUTURE",
    DAYS_PAST: "DAYS_PAST",
    THE_DATE: "THE_DATE",
} as const;

export type DatePeriod = (typeof DatePeriod)[keyof typeof DatePeriod];
