import React, { useLayoutEffect } from "react";

import classnames from "classnames/bind";

import styles from "./IframeFullscreenButton.module.scss";

import { ReactComponent as FullscreenIcon } from "src/assets/icons/fullscreen-icon.svg";
import { ReactComponent as FullscreenExitIcon } from "src/assets/icons/fullscreen-exit-icon.svg";
import type { RootState } from "src/store/store";
import { useAppSelector } from "src/store/store";
import { z } from "zod";
import { useIframe } from "src/view/hooks";

const cx = classnames.bind(styles);

const schema = z.object({
    name: z.literal("iframe-fullscreen-state"),
    state: z.boolean(),
});

export function IframeFullscreenButton() {
    const { isIframe, actions: iframeActions } = useIframe();

    const { isFullscreen } = useAppSelector((state: RootState) => state.iframe);

    useLayoutEffect(() => {
        if (!isIframe) return;

        function handler(event: MessageEvent<unknown>) {
            const parsed = schema.safeParse(event.data);

            if (parsed.success) {
                iframeActions.toggleFullscreen(parsed.data.state);
            }
        }

        window.addEventListener("message", handler, false);

        return () => {
            window.removeEventListener("message", handler, false);
        };
    }, [isIframe, iframeActions]);

    function handleClick() {
        if (isFullscreen) {
            window.parent.postMessage("iframe-fullscreen-exit", {
                targetOrigin: "*",
            });
        } else {
            window.parent.postMessage("iframe-fullscreen-enter", {
                targetOrigin: "*",
            });
        }
    }

    if (!isIframe) return null;

    return (
        <button
            type="button"
            className={cx("iframe-fullscreen-button")}
            onClick={handleClick}
        >
            {isFullscreen ? (
                <FullscreenExitIcon
                    width={31}
                    height={31}
                />
            ) : (
                <FullscreenIcon
                    width={31}
                    height={31}
                />
            )}
        </button>
    );
}
