import type { ReactNode } from "react";
import React from "react";

import classNames from "classnames/bind";

import { FakeCheckbox } from "src/view/components/basic/inputs/checkbox";

import styles from "./MultipleCustomOption.module.scss";

const cx = classNames.bind(styles);

export function MultipleCustomOption({
    children, //
    onSelect,
    checked,
}: Props) {
    return (
        <div
            className={cx("wrapper", {
                "selected": checked === true,
                "half-checked": checked === "half-checked",
            })}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();

                onSelect();
            }}
            role="button"
            tabIndex={0}
        >
            <FakeCheckbox
                checked={checked}
                label=""
            />
            {children}
        </div>
    );
}

interface Props {
    checked: boolean | "half-checked";
    onSelect: () => void;
    children: ReactNode;
}
