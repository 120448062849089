import React from "react";
import { EditReportColumnsPopup, EditReportFiltersPopup, EditReportPopup } from "../../popups/edit";
import type { ReportFormInput, ReportFormOutput } from "../../../../validation";
import { FormProvider, useForm } from "react-hook-form";
import type { RootState } from "../../../../store/store";
import { useAppSelector } from "../../../../store/store";
import invariant from "tiny-invariant";
import { FormContextProvider } from "../FormContextProvider";

function Provider({ children }: ChildrenProps) {
    const { draft } = useAppSelector((state: RootState) => state.reports);

    invariant(draft, "Draft should be specified");

    const methods = useForm<ReportFormInput, unknown, ReportFormOutput>({
        defaultValues: {
            name: draft.name,
            sourceId: draft.sourceId,
            type: draft.type,
            creatorEmail: draft.creatorEmail,
            columns: draft.columns,
            filters: draft.filters,
            API_URL: draft.API_URL,
        },
        mode: "onChange",
    });

    return <FormProvider {...methods}>{children}</FormProvider>;
}

function View({
    isEditReport, //
    isEditReportColumns,
    isEditReportFilters,
}: Props) {
    let popup: React.ReactNode;

    if (isEditReport) {
        popup = <EditReportPopup />;
    }

    if (isEditReportColumns) {
        popup = <EditReportColumnsPopup />;
    }

    if (isEditReportFilters) {
        popup = <EditReportFiltersPopup />;
    }

    return <FormContextProvider Provider={Provider}>{popup}</FormContextProvider>;
}

interface Props {
    isEditReport: boolean;
    isEditReportColumns: boolean;
    isEditReportFilters: boolean;
}

export const EditReportFormGroup = React.memo(View);
