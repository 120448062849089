import React from "react";

import classnames from "classnames/bind";

import type { MenuItemModifiers } from "@szhsin/react-menu";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

import { ReactComponent as DotsIcon } from "src/assets/icons/dots-three-vertical.svg";
import { ReactComponent as EditIcon } from "src/assets/icons/edit-report-icon.svg";
import { ReactComponent as CopyIcon } from "src/assets/icons/copy-report-icon.svg";
import { ReactComponent as DeleteIcon } from "src/assets/icons/delete-report-icon.svg";

import styles from "./ReportMenu.module.scss";
import type { RootState } from "src/store/store";
import { useAppSelector } from "src/store/store";
import invariant from "tiny-invariant";
import { hasReportPermissionsCheck } from "src/utils/common";
import { usePopups } from "src/view/hooks";

const cx = classnames.bind(styles);

const menuStyles: React.CSSProperties = {
    background: "#2E3138",
    borderRadius: "8px 0 8px 8px",
    padding: "12px 0",
    overflow: "hidden",
    minWidth: "unset",
    boxShadow: "none",
};

export function ReportMenu() {
    const { actions: popupsActions } = usePopups();

    const { report } = useAppSelector((state: RootState) => state.reports);
    const { user } = useAppSelector((state: RootState) => state.auth);

    invariant(report);
    invariant(user);

    const hasPermission = hasReportPermissionsCheck(report, user);

    const handleEditReport = () => {
        if (!hasPermission) return;

        popupsActions.editReportPopup.open();
    };

    const handleCopyReport = () => {
        popupsActions.copyReportPopup.open();
    };

    const handleDeleteReport = () => {
        if (!hasPermission) return;

        popupsActions.deleteReportPopup.open();
    };

    return (
        <div className={cx("report-menu")}>
            <Menu
                menuStyle={menuStyles}
                menuButton={
                    <MenuButton>
                        <div className={cx("menu-button")}>
                            <DotsIcon />
                        </div>
                    </MenuButton>
                }
                align="end"
                direction="bottom"
                gap={10}
                position="anchor"
            >
                <MenuItem
                    className={cx("menu-item-wrapper")}
                    disabled={!hasPermission}
                    onClick={handleEditReport}
                >
                    {({ hover, disabled }: MenuItemModifiers) => (
                        <div
                            className={cx("menu-item", {
                                hovered: hover,
                                disabled,
                            })}
                        >
                            <EditIcon />
                            <span>Edit Report</span>
                        </div>
                    )}
                </MenuItem>
                <MenuItem
                    className={cx("menu-item-wrapper")}
                    onClick={handleCopyReport}
                >
                    {({ hover, disabled }: MenuItemModifiers) => (
                        <div
                            className={cx("menu-item", {
                                hovered: hover,
                                disabled,
                            })}
                        >
                            <CopyIcon />
                            <span>Copy Report</span>
                        </div>
                    )}
                </MenuItem>
                <MenuItem
                    className={cx("menu-item-wrapper")}
                    disabled={!hasPermission}
                    onClick={handleDeleteReport}
                >
                    {({ hover, disabled }: MenuItemModifiers) => (
                        <div
                            className={cx("menu-item", {
                                hovered: hover,
                                disabled,
                            })}
                        >
                            <DeleteIcon />
                            <span>Delete Report</span>
                        </div>
                    )}
                </MenuItem>
            </Menu>
        </div>
    );
}
