import type {
    ColumnFilterPair,
    RawSourceItem,
    Report,
    ReportColumn,
    ReportFilter,
    SourceColumn,
} from "src/core/entities";
import { useLayoutEffect, useMemo } from "react";
import { useFindSourceColumnsQuery, useLazyFindSourceDataQuery } from "../../store/services";
import { getReportSource, getReportSourceParams } from "src/utils/common";

const DEFAULT_PAIRS: ColumnFilterPair[] = [];
const DEFAULT_COLUMNS: SourceColumn[] = [];
const DEFAULT_SOURCE_DATA: RawSourceItem[] = [];

interface Options {
    ignoreFilters?: boolean;
}

function createHook() {
    return function useReportData(report: Report, options?: Options) {
        const { filters: reportFilters, columns: reportColumns } = report;

        const {
            data: columns = DEFAULT_COLUMNS, //
            isLoading: isColumnsLoading,
            isFetching: isColumnsFetching,
        } = useFindSourceColumnsQuery(getReportSource(report.sourceId), {
            skip: reportColumns.length === 0,
        });

        const [
            fetchSourceData,
            {
                data: sourceData = DEFAULT_SOURCE_DATA, //
                isLoading: isSourceDataLoading,
                isFetching: isSourceDataFetching,
            },
        ] = useLazyFindSourceDataQuery();

        /**
         * Append values to columns choices from source data items
         */
        const richColumns = useMemo(() => {
            if (columns.length === 0) return DEFAULT_COLUMNS;

            if (sourceData.length === 0) return columns;

            return columns.map((sourceColumn: SourceColumn) => {
                if (sourceColumn.type === "SELECT" && sourceColumn.choices === null) {
                    const set = new Set<string>(
                        sourceData
                            .map((data: RawSourceItem) => {
                                return data[sourceColumn.name] ? String(data[sourceColumn.name]) : "";
                            })
                            .filter(Boolean),
                    );

                    return {
                        ...sourceColumn,
                        choices: Array.from(set).map((value: string) => ({
                            text: value,
                            value,
                        })),
                    };
                }

                return sourceColumn;
            });
        }, [columns, sourceData]);

        /**
         * Get report column/filter pairs list
         */
        const pairsData = useMemo(() => {
            if (reportFilters.length === 0) return DEFAULT_PAIRS;

            return reportFilters
                .map((filter: ReportFilter) => {
                    const foundColumn = richColumns.find((column: SourceColumn) => column.id === filter.id);

                    if (foundColumn) {
                        return {
                            column: foundColumn,
                            filter,
                        };
                    }

                    return null;
                })
                .filter(Boolean);
        }, [richColumns, reportFilters]);

        /**
         * Get sorted report columns list
         */
        const columnsData = useMemo(() => {
            if (reportColumns.length === 0) return DEFAULT_COLUMNS;

            const sorted = [...reportColumns];

            sorted.sort((a: ReportColumn, b: ReportColumn) => {
                return a.position - b.position;
            });

            // TODO unshift index column ?

            return sorted
                .map((reportColumn: ReportColumn) => {
                    const foundSourceColumn = richColumns.find((sourceColumn: SourceColumn) => {
                        return sourceColumn.id === reportColumn.id;
                    });

                    return foundSourceColumn ?? null;
                })
                .filter(Boolean);
        }, [richColumns, reportColumns]);

        const skipSourceDataFetching = isColumnsFetching || columnsData.length === 0;

        const queryParams = getReportSourceParams(
            {
                columns: columnsData,
                pairs: pairsData,
            },
            {
                ignoreFilters: options?.ignoreFilters ?? false,
            },
        ).toString();

        /**
         * Fetch report source data
         */
        useLayoutEffect(() => {
            if (skipSourceDataFetching) return;

            const controller = fetchSourceData(
                {
                    source: getReportSource(report.sourceId),
                    queryParams,
                },
                true,
            );

            return () => {
                controller.abort();
            };
        }, [fetchSourceData, skipSourceDataFetching, report.sourceId, queryParams]);

        return {
            pairs: pairsData,
            columns: columnsData,
            sourceData,
            isColumnsLoading,
            isColumnsFetching,
            isSourceDataLoading,
            isSourceDataFetching,
        };
    };
}

export const useReportData = createHook();
