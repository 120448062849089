import React, { useId } from "react";

import classnames from "classnames/bind";

import styles from "./BasicInput.module.scss";

const cx = classnames.bind(styles);

export function BasicInput({
    label = defaultProps.label, //
    type = defaultProps.type,
    value,
    disabled,
    onChange,
    ...attributes
}: Props) {
    const inputId = useId();

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!disabled) {
            onChange(event.target.value, event);
        }
    }

    return (
        <div className={cx("basic-input")}>
            <label htmlFor={inputId}>
                {label && <div className={cx("input-label")}>{label}</div>}

                <input
                    id={inputId}
                    type="text"
                    className={cx({
                        error: type === "error",
                    })}
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                    autoComplete="off"
                    aria-autocomplete="none"
                    {...attributes}
                />
            </label>
        </div>
    );
}

const defaultProps = {
    label: "",
    type: "default",
} as const;

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    value: string;
    onChange: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    type?: "default" | "error";
}
