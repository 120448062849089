import React, { useCallback, useMemo } from "react";

import classnames from "classnames/bind";

import styles from "./DraggableFiltersList.module.scss";
import type { DraggableProvided, DraggableStateSnapshot, DroppableProvided, DropResult } from "@hello-pangea/dnd";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { DraggableFilter } from "../DraggableFilter";
import type { ColumnFilterPair, ReportFilter } from "src/core/entities";

const cx = classnames.bind(styles);

/**
 * Reorder column/filter pairs list
 */
function reorder(list: ColumnFilterPair[], start: number, end: number) {
    const newList = [...list];

    const [removed] = newList.splice(start, 1);

    if (removed) {
        newList.splice(end, 0, removed);
    }

    return newList;
}

export function DraggableFiltersList({
    searchValue, //
    pairs,
    onFiltersOrderChange,
    onFilterChange,
}: Props) {
    /**
     * Get list of visible pairs ids
     */
    const visiblePairsIds = useMemo(() => {
        return pairs
            .filter((pair: ColumnFilterPair) => {
                return pair.column.name.toLowerCase().includes(searchValue.trim().toLowerCase());
            })
            .map((pair: ColumnFilterPair) => pair.column.id);
    }, [pairs, searchValue]);

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) return;

            const newPairs = reorder(pairs, result.source.index, result.destination.index);

            onFiltersOrderChange(
                newPairs.map((pair: ColumnFilterPair, index: number) => {
                    return {
                        ...pair.filter,
                        position: index,
                    };
                }),
            );
        },
        [pairs, onFiltersOrderChange],
    );

    const handleFilterChange = useCallback(
        (filter: ReportFilter) => {
            onFilterChange(filter);
        },
        [onFilterChange],
    );

    if (visiblePairsIds.length === 0) {
        return <div className={cx("empty")}>No filters</div>;
    }

    return (
        <div className={cx("draggable-filters-list")}>
            <div className={cx("filters-list")}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="report-filters-droppable">
                        {(dropProvided: DroppableProvided) => {
                            return (
                                <div
                                    ref={dropProvided.innerRef}
                                    {...dropProvided.droppableProps}
                                    className={cx("drop-list")}
                                >
                                    {pairs.map((pair: ColumnFilterPair, index: number) => {
                                        const key = pair.column.id;

                                        const visible = visiblePairsIds.includes(pair.column.id);

                                        return (
                                            <Draggable
                                                key={key}
                                                draggableId={key.toString()}
                                                index={index}
                                            >
                                                {(
                                                    dragProvided: DraggableProvided,
                                                    snapshot: DraggableStateSnapshot,
                                                ) => {
                                                    return (
                                                        <div
                                                            ref={dragProvided.innerRef}
                                                            {...dragProvided.draggableProps}
                                                        >
                                                            <div
                                                                className={cx("drag-item", {
                                                                    hidden: !visible,
                                                                })}
                                                            >
                                                                <DraggableFilter
                                                                    pair={pair}
                                                                    onFilterChange={handleFilterChange}
                                                                    dragHandleProps={dragProvided.dragHandleProps}
                                                                    isDragging={snapshot.isDragging}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })}

                                    {dropProvided.placeholder}
                                </div>
                            );
                        }}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
}

interface Props {
    searchValue: string;
    pairs: ColumnFilterPair[];
    onFiltersOrderChange: (filters: ReportFilter[]) => void;
    onFilterChange: (filter: ReportFilter) => void;
}
