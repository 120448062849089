import React from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "src/store/store";
import { DataTableProvider } from "./DataTable.provider";

export function RootProvider({ children }: ChildrenProps) {
    return (
        <Provider store={store}>
            <PersistGate
                loading={null}
                persistor={persistor}
            >
                <DataTableProvider>{children}</DataTableProvider>
            </PersistGate>
        </Provider>
    );
}
