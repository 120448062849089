import React, { useCallback } from "react";

import classnames from "classnames/bind";

import style from "./DraggableFilter.module.scss";
import type { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import type {
    ColumnFilterPair,
    ReportFilter,
    ReportFilterCheckboxChoices,
    ReportFilterDateChoices,
    ReportFilterNumericChoices,
    ReportFilterSelectChoices,
} from "src/core/entities";

import { ReactComponent as DnDIcon } from "src/assets/icons/drag-and-drop-icon.svg";
import { ReactComponent as BookmarkIcon } from "src/assets/icons/bookmark-icon.svg";
import { ReactComponent as ColumnInfoIcon } from "src/assets/icons/column-info-icon.svg";
import { InfoTooltip } from "../../../../tooltips";
import { InputSelector } from "../../../../mix";

const cx = classnames.bind(style);

function View({
    pair, //
    onFilterChange,
    dragHandleProps,
    isDragging,
}: Props) {
    const { column, filter } = pair;

    function handlePin() {
        onFilterChange({
            ...filter,
            pinned: !filter.pinned,
        });
    }

    const handleSelectChange = useCallback(
        (choices: ReportFilterSelectChoices) => {
            if (filter.value.type === "SELECT") {
                onFilterChange({
                    ...filter,
                    value: {
                        ...filter.value,
                        choices,
                    },
                });
            }
        },
        [filter, onFilterChange],
    );

    const handleNumericChange = useCallback(
        (choices: ReportFilterNumericChoices) => {
            if (filter.value.type === "NUMERIC") {
                onFilterChange({
                    ...filter,
                    value: {
                        ...filter.value,
                        choices,
                    },
                });
            }
        },
        [filter, onFilterChange],
    );

    const handleDateChange = useCallback(
        (choices: ReportFilterDateChoices) => {
            if (filter.value.type === "DATE") {
                onFilterChange({
                    ...filter,
                    value: {
                        ...filter.value,
                        choices,
                    },
                });
            }
        },
        [filter, onFilterChange],
    );

    const handleCheckboxChange = useCallback(
        (choices: ReportFilterCheckboxChoices) => {
            if (filter.value.type === "CHECKBOX") {
                onFilterChange({
                    ...filter,
                    value: {
                        ...filter.value,
                        choices,
                    },
                });
            }
        },
        [filter, onFilterChange],
    );

    return (
        <>
            <div
                className={cx("draggable-filter", {
                    "is-dragging": isDragging,
                })}
            >
                <div className={cx("item-header")}>
                    <div
                        {...dragHandleProps}
                        className={cx("dnd-icon")}
                        data-tooltip-id={column.id.toString()}
                        data-tooltip-content="Click and pull down or top to change the order"
                    >
                        <DnDIcon />
                    </div>
                    <button
                        type="button"
                        className={cx("pin-icon", {
                            pinned: filter.pinned,
                        })}
                        onClick={handlePin}
                        data-tooltip-id={column.id.toString()}
                        data-tooltip-content="Click to pin it to the report main view"
                    >
                        <BookmarkIcon />
                    </button>
                    <div className={cx("name")}>{column.name}</div>

                    {column.description && (
                        <div
                            className={cx("description")}
                            data-tooltip-id={column.id.toString() + " - description"}
                            data-tooltip-content={column.description}
                        >
                            <ColumnInfoIcon />
                        </div>
                    )}
                </div>

                <div className={cx("item-body")}>
                    <InputSelector
                        pair={pair}
                        onSelectChange={handleSelectChange}
                        onNumericChange={handleNumericChange}
                        onDateChange={handleDateChange}
                        onCheckboxChange={handleCheckboxChange}
                    />
                </div>
            </div>

            <InfoTooltip
                id={column.id.toString()}
                place="top-start"
                borders={[8, 8, 8, 0]}
                positionStrategy="fixed"
                offset={0}
            />

            <InfoTooltip
                id={column.id.toString() + " - description"}
                place="top-end"
                borders={[8, 8, 0, 8]}
                positionStrategy="fixed"
                offset={4}
            />
        </>
    );
}

interface Props {
    pair: ColumnFilterPair;
    onFilterChange: (filter: ReportFilter) => void;
    dragHandleProps: DraggableProvidedDragHandleProps | null;
    isDragging: boolean;
}

export const DraggableFilter = React.memo(View);
