import type { Column, IRowNode } from "@ag-grid-community/core";
import { useContext, useMemo } from "react";
import { DataTableContext } from "../context/providers/DataTable.provider";
import type { SourceItem, SourceItemValue } from "../../core/entities";
import { formatBooleanView, formatDateView, formatNumberView, formatUserView } from "../../utils/helpers";

export type PinnedRow = Record<string, { value: unknown }>;

interface GetRowsParams {
    formatNumbers: boolean;
}

function createHook() {
    return function useDataTable() {
        const ref = useContext(DataTableContext);

        const services = useMemo(() => {
            function getInstance() {
                return ref.current;
            }

            return {
                getColumns: (): Column<SourceItem<unknown>>[] => {
                    const instance = getInstance();

                    return (instance?.api.getAllDisplayedColumns() ?? []) as Column<SourceItem<unknown>>[];
                },
                getRows: (params: GetRowsParams): SourceItem<unknown>[] => {
                    const instance = getInstance();

                    if (instance) {
                        const { formatNumbers } = params;

                        const rows: SourceItem<unknown>[] = [];

                        instance.api.forEachNodeAfterFilterAndSort((rowNode: IRowNode<SourceItem<unknown>>) => {
                            if (rowNode.data) {
                                rows.push(rowNode.data);
                            }
                        });

                        return rows.map((row: SourceItem<unknown>) => {
                            const newRow: SourceItem<unknown> = {};

                            Object.entries(row).forEach(([key, source]: [string, SourceItemValue<unknown>]) => {
                                if (source.column.type === "DATE" && source.value instanceof Date) {
                                    newRow[key] = {
                                        column: source.column,
                                        value: formatDateView(source.value),
                                    } as SourceItemValue<string>;

                                    return;
                                }

                                if (source.column.originalType === "User" && typeof source.value === "string") {
                                    newRow[key] = {
                                        column: source.column,
                                        value: formatUserView(source.value),
                                    } as SourceItemValue<string>;

                                    return;
                                }

                                if (source.column.originalType === "Numeric" && typeof source.value === "number") {
                                    newRow[key] = {
                                        column: source.column,
                                        value: formatNumbers
                                            ? formatNumberView(source.value, source.column.name)
                                            : source.value,
                                    } as SourceItemValue<string>;

                                    return;
                                }

                                if (source.column.originalType === "Autonumber" && typeof source.value === "string") {
                                    newRow[key] = {
                                        column: source.column,
                                        value: Number(source.value),
                                    } as SourceItemValue<number>;

                                    return;
                                }

                                if (source.column.type === "CHECKBOX" && typeof source.value === "boolean") {
                                    newRow[key] = {
                                        column: source.column,
                                        value: formatBooleanView(source.value),
                                    };

                                    return;
                                }

                                newRow[key] = source;
                            });

                            return newRow;
                        });
                    }

                    return [];
                },
                getPinnedBottomRows: (): PinnedRow[] => {
                    const instance = getInstance();

                    if (instance) {
                        const rows: PinnedRow[] = [];

                        for (let i = 0; i < instance.api.getPinnedBottomRowCount(); i++) {
                            const data = instance.api.getPinnedBottomRow(i)?.data as PinnedRow | undefined;

                            if (data) {
                                rows.push(data);
                            }
                        }

                        return rows;
                    }

                    return [];
                },
            };
        }, [ref]);

        return {
            ref,
            services,
        };
    };
}

export const useDataTable = createHook();
