export const SourceColumnOriginalType = {
    AUTONUMBER: "Autonumber",
    CHECKBOX: "Checkbox",
    HTML: "Html",
    NUMERIC: "Numeric",
    TEXT: "Text",
    URL: "URL",
    DATE: "Date",
    USER: "User",
    PHONE: "Phone",
    TIMESTAMP: "Timestamp",
    EMAIL: "Email",
    MULTILINE: "Multiline",
    LOCATION: "Location",
    RECORDSET: "RecordSet",
    DURATION: "Duration",
} as const;

export type SourceColumnOriginalType = (typeof SourceColumnOriginalType)[keyof typeof SourceColumnOriginalType];

// "Attachment",

// "Autonumber",
// "Checkbox",
// "Html",
// "Numeric",
// "Text",
// "URL",
// "Date",
// "User",
// "Phone",
// "Timestamp",
// "Email",
// "Multiline",
// "Location",
// "RecordSet",
// "Duration",
