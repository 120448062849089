import React from "react";

import classnames from "classnames/bind";

import styles from "./DatePickerControl.module.scss";

import { ReactComponent as CalendarIcon } from "src/assets/icons/calendar-icon.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import type { ReportFilterDateChoices } from "src/core/entities";
import { formatDateFilterChoicesView } from "src/utils/helpers";

const cx = classnames.bind(styles);

function View({
    value, //
    placeholder,
    isMenuOpen,
    onMenuOpen,
    onClear,
}: Props) {
    return (
        <div
            className={cx("date-picker-control", {
                focus: isMenuOpen,
                empty: value === null,
            })}
        >
            <div className={cx("input")}>
                <button
                    type="button"
                    className={cx("input-button")}
                    onClick={onMenuOpen}
                >
                    <div className={cx("icon")}>
                        <CalendarIcon />
                    </div>
                    {value !== null ? (
                        <div className={cx("value")}>{formatDateFilterChoicesView(value)}</div>
                    ) : (
                        <div className={cx("placeholder")}>{placeholder ?? "Select Date"}</div>
                    )}
                </button>
            </div>

            {value !== null && (
                <button
                    type="button"
                    className={cx("clear-button")}
                    onClick={onClear}
                >
                    <CloseIcon />
                </button>
            )}
        </div>
    );
}

interface Props {
    value: ReportFilterDateChoices;
    placeholder?: string;
    isMenuOpen: boolean;
    onMenuOpen: () => void;
    onClear: () => void;
}

export const DatePickerControl = React.memo(View);
