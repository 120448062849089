import type { ChangeEvent, InputHTMLAttributes } from "react";
import React, { useId } from "react";

import classnames from "classnames/bind";

import styles from "./SearchInput.module.scss";

import { ReactComponent as SearchIcon } from "src/assets/icons/search-icon.svg";
import { ReactComponent as ClearIcon } from "src/assets/icons/close-icon.svg";

const cx = classnames.bind(styles);

export function SearchInput({
    label, //
    value,
    onChange,
    ...attributes
}: Props) {
    const inputId = useId();

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        onChange(event.target.value, event);
    }

    return (
        <div className={cx("search-input")}>
            <label htmlFor={inputId}>
                {label && <div className={cx("input-label")}>{label}</div>}

                <div className={cx("input")}>
                    <div className={cx("search-icon")}>
                        <SearchIcon />
                    </div>

                    <input
                        id={inputId}
                        type="text"
                        value={value}
                        onChange={handleChange}
                        autoComplete="off"
                        aria-autocomplete="none"
                        {...attributes}
                    />

                    {value.length > 0 && (
                        <button
                            type="button"
                            className={cx("clear-icon")}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.stopPropagation();

                                onChange("");
                            }}
                        >
                            <ClearIcon />
                        </button>
                    )}
                </div>
            </label>
        </div>
    );
}

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    label?: string;
    value: string;
    onChange: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
}
