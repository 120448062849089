import styles from "./SideMenuFooter.module.scss";
import classnames from "classnames/bind";
import React, { useCallback } from "react";
import { BasicButton } from "../../../basic/buttons";
import { usePopups } from "src/view/hooks";

const cx = classnames.bind(styles);

function View() {
    const { actions: popupsActions } = usePopups();

    const handleBuildNewReport = useCallback(() => {
        popupsActions.createReportPopup.open();
    }, [popupsActions]);

    return (
        <div className={cx("side-menu-footer")}>
            <BasicButton
                title="Build New Report"
                style={{
                    height: 52,
                }}
                onClick={handleBuildNewReport}
            />
        </div>
    );
}

export const SideMenuFooter = React.memo(View);
