import React from "react";

import classnames from "classnames/bind";

import { AgGridReact } from "@ag-grid-community/react";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import styles from "./DataTable.module.scss";
import type {
    ColDef,
    ColumnMovedEvent,
    GridColumnsChangedEvent,
    GridReadyEvent,
    RowHeightParams,
    SortChangedEvent,
} from "@ag-grid-community/core";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { DotSpinner } from "../../basic/spinners";
import { useDataTable } from "../../../hooks";
import type { SourceItem } from "../../../../core/entities";

const cx = classnames.bind(styles);

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const defaultColDef: ColDef = {
    minWidth: 150,
    maxWidth: 800,
};

function View({
    rowData, //
    columnDefs,
    pinnedBottomRowData,
    isLoading,
    onColumnMoved,
    onSortChanged,
}: Props) {
    const { ref } = useDataTable();

    function handleGridReady(event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
    }

    function handleGridColumnsChanged(event: GridColumnsChangedEvent) {
        // TODO Hmmm...
        event.api.sizeColumnsToFit();
        event.api.resetColumnState();
    }

    function handleColumnMoved(event: ColumnMovedEvent) {
        if (event.finished && event.column && event.toIndex !== undefined) {
            onColumnMoved(event.column.getColId(), event.toIndex);
        }
    }

    return (
        <div className={cx("data-table", "ag-theme-alpine")}>
            {isLoading && (
                <div className={cx("loading")}>
                    <DotSpinner />
                </div>
            )}

            {columnDefs.length === 0 ? (
                <div className={cx("no-columns")}>No Columns To Show</div>
            ) : (
                <>
                    <AgGridReact<SourceItem<unknown>>
                        ref={ref}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        pinnedBottomRowData={pinnedBottomRowData}
                        headerHeight={40}
                        getRowHeight={(params: RowHeightParams<SourceItem<unknown>>) => {
                            if (params.node.rowPinned) {
                                return 30;
                            }

                            return 60;
                        }}
                        suppressRowDrag
                        suppressDragLeaveHidesColumns
                        suppressCellFocus
                        animateRows={false}
                        allowDragFromColumnsToolPanel={false}
                        onGridReady={handleGridReady}
                        onGridColumnsChanged={handleGridColumnsChanged}
                        onColumnMoved={handleColumnMoved}
                        onSortChanged={onSortChanged}
                    />

                    <div className={cx("total")}>{"Total records: " + rowData.length}</div>
                </>
            )}
        </div>
    );
}

interface Props {
    rowData: SourceItem<unknown>[];
    columnDefs: ColDef<SourceItem<unknown>>[];
    pinnedBottomRowData: Record<string, unknown>[];
    isLoading: boolean;
    onColumnMoved: (colId: string, toIndex: number) => void;
    onSortChanged: (event: SortChangedEvent<SourceItem<unknown>>) => void;
}

export const DataTable = React.memo(View);
