import React from "react";

import classnames from "classnames/bind";

import { InputSelector } from "../../../../mix";
import type {
    ColumnFilterPair,
    ReportFilter,
    ReportFilterCheckboxChoices,
    ReportFilterDateChoices,
    ReportFilterNumericChoices,
    ReportFilterSelectChoices,
} from "src/core/entities";

import styles from "./FilterItem.module.scss";

const cx = classnames.bind(styles);

function View({
    pair, //
    handleFilterChange,
}: Props) {
    return (
        <div
            className={cx("filter-item", {
                select: pair.column.type === "SELECT",
                numeric: pair.column.type === "NUMERIC",
                date: pair.column.type === "DATE",
                checkbox: pair.column.type === "CHECKBOX",
            })}
        >
            <div className={cx("filter-name")}>{pair.column.name}</div>
            <InputSelector
                pair={pair}
                onSelectChange={(choices: ReportFilterSelectChoices) => {
                    const { filter } = pair;

                    if (filter.value.type === "SELECT") {
                        handleFilterChange({
                            ...filter,
                            value: {
                                ...filter.value,
                                choices,
                            },
                        });
                    }
                }}
                onNumericChange={(choices: ReportFilterNumericChoices) => {
                    const { filter } = pair;

                    if (filter.value.type === "NUMERIC") {
                        handleFilterChange({
                            ...filter,
                            value: {
                                ...filter.value,
                                choices,
                            },
                        });
                    }
                }}
                onDateChange={(choices: ReportFilterDateChoices) => {
                    const { filter } = pair;

                    if (filter.value.type === "DATE") {
                        handleFilterChange({
                            ...filter,
                            value: {
                                ...filter.value,
                                choices,
                            },
                        });
                    }
                }}
                onCheckboxChange={(choices: ReportFilterCheckboxChoices) => {
                    const { filter } = pair;

                    if (filter.value.type === "CHECKBOX") {
                        handleFilterChange({
                            ...filter,
                            value: {
                                ...filter.value,
                                choices,
                            },
                        });
                    }
                }}
            />
        </div>
    );
}

interface Props {
    pair: ColumnFilterPair;
    handleFilterChange: (filter: ReportFilter) => void;
}

export const FilterItem = React.memo(View);
