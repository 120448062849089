import type { BaseQueryApi, BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Endpoints } from "../../core/constants/endpoint.constants";
import type { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { z } from "zod";
import type { SourceColumn, SourceColumnChoice, RawSourceItem } from "../../core/entities";
import { isNameExcluded } from "../../utils/helpers";
import { SourceColumnOriginalType, SourceColumnType } from "src/core/enums";
import type { Source } from "src/core/entities/Source";
import { catchError, EMPTY, expand, from, lastValueFrom, map, of, reduce } from "rxjs";
import type { AxiosResponse } from "axios";
import axios, { CanceledError } from "axios";

const MAX_TOTAL_RECORDS = 500;

interface SourceItemsDataChunk {
    data: RawSourceItem[];
    totalCount: number;
}

async function getSourceItemsDataChunk(params: FindSourceDataParams, page: number, signal?: AbortSignal) {
    const skip = MAX_TOTAL_RECORDS * (page - 1);

    const url =
        `${Endpoints.API_FILTERS_DATA}${params.source.table}/select.json?` + params.queryParams + `&skip=${skip}`;

    return lastValueFrom(
        from(
            axios.get(url, {
                signal,
            }),
        )
            .pipe(
                map((response: AxiosResponse<RawSourceItem[]>) => ({
                    data: response.data,
                    totalCount: response.data.length,
                })),
            )
            .pipe(
                catchError((error: unknown) => {
                    if (error instanceof CanceledError) {
                        return of({
                            data: [],
                            totalCount: 0,
                        });
                    }

                    throw new Error("Can't get source data");
                }),
            ),
    );
}

async function getSourceItemsDataFn(params: FindSourceDataParams, signal?: AbortSignal) {
    let page = 1;

    const data = await lastValueFrom(
        from(getSourceItemsDataChunk(params, page++, signal)).pipe(
            expand((response: SourceItemsDataChunk) => {
                return response.totalCount === MAX_TOTAL_RECORDS
                    ? getSourceItemsDataChunk(params, page++, signal)
                    : EMPTY;
            }),
            reduce((acc: RawSourceItem[], current: SourceItemsDataChunk) => {
                return acc.concat(current.data);
            }, []),
            map((items: RawSourceItem[]) => {
                return items.map((item: RawSourceItem) => {
                    const newItem: RawSourceItem = {};

                    /**
                     * Some source data conversions
                     */
                    Object.entries(item).forEach(([key, value]: [string, unknown]) => {
                        if (key === "@row.id") return;

                        if (
                            typeof value === "string" &&
                            Number.isNaN(+value) &&
                            new Date(value).toString() !== "Invalid Date"
                        ) {
                            const [withoutT] = value.split("T");

                            const date = new Date(withoutT + "T00:00:00");

                            if (date.toString() !== "Invalid Date") {
                                newItem[key] = date;

                                return;
                            }
                        }

                        newItem[key] = value;
                    });

                    return newItem;
                });
            }),
        ),
    );

    return {
        data,
    };
}

const sourceColumnSchema = z.object({
    id: z.number(),
    name: z.string(),
    type: z.nativeEnum(SourceColumnOriginalType),
    description: z
        .string()
        .nullish()
        .transform((val: string | null | undefined) => {
            if (val === undefined || val === "") return null;

            return val;
        }),
    choices: z
        .array(
            z.object({
                value: z.string(),
                text: z.string(),
            }),
        )
        .optional()
        .transform((val: SourceColumnChoice[] | undefined) => {
            if (val === undefined) return null;

            return val;
        }),
});

type SourceColumnSchemaInput = z.input<typeof sourceColumnSchema>;
type SourceColumnSchemaOutput = z.output<typeof sourceColumnSchema>;

interface SourceView {
    name: string;
    columns: string[];
}

interface RawSourceInfo {
    columns: SourceColumnSchemaInput[];
    // TODO validate
    views: SourceView[];
}

interface FindSourceDataParams {
    source: Source;
    queryParams: string;
}

function getColumnType(originalType: SourceColumnOriginalType): SourceColumnType {
    if (
        [
            SourceColumnOriginalType.NUMERIC, //
        ].includes(originalType)
    ) {
        return SourceColumnType.NUMERIC;
    }

    if (
        [
            SourceColumnOriginalType.DATE, //
            SourceColumnOriginalType.TIMESTAMP,
        ].includes(originalType)
    ) {
        return SourceColumnType.DATE;
    }

    if (
        [
            SourceColumnOriginalType.CHECKBOX, //
        ].includes(originalType)
    ) {
        return SourceColumnType.CHECKBOX;
    }

    return SourceColumnType.SELECT;
}

function isFilterable(column: SourceColumnSchemaOutput) {
    return ![
        "Multiline", //
        "Duration",
    ].includes(column.type);
}

export const sourcesApi = createApi({
    reducerPath: "sources-api",
    baseQuery: fetchBaseQuery({
        baseUrl: Endpoints.API_FILTERS_DATA,
    }),
    endpoints: (builder: EndpointBuilder<BaseQueryFn, string, string>) => ({
        findSourceColumns: builder.query<SourceColumn[], Source>({
            query: (source: Source) => {
                return `${source.table}/describe.json`;
            },
            transformResponse: (raw: RawSourceInfo, _: unknown, source: Source): SourceColumn[] => {
                const { columns, views } = raw;

                const view = views.find((v: SourceView) => v.name === source.view);

                if (!view) return [];

                return columns
                    .map((column: SourceColumnSchemaInput) => {
                        const parsed = sourceColumnSchema.safeParse(column);

                        if (!parsed.success) {
                            return null;
                        }

                        if (isNameExcluded(parsed.data.name, source.table)) {
                            return null;
                        }

                        return parsed.data;
                    })
                    .filter(Boolean)
                    .filter((column: SourceColumnSchemaOutput) => {
                        return view.columns.includes(column.name);
                    })
                    .map((column: SourceColumnSchemaOutput) => ({
                        id: column.id,
                        name: column.name,
                        type: getColumnType(column.type),
                        originalType: column.type,
                        description: column.description,
                        choices: column.choices,
                        filterable: isFilterable(column),
                    }));
            },
        }),

        findSourceData: builder.query<RawSourceItem[], FindSourceDataParams>({
            queryFn: (params: FindSourceDataParams, { signal }: BaseQueryApi) => {
                return getSourceItemsDataFn(params, signal);
            },
        }),
    }),
});

export const {
    useFindSourceColumnsQuery, //
    useLazyFindSourceColumnsQuery,
    useLazyFindSourceDataQuery,
} = sourcesApi;
