import React from "react";
import { Authorization } from "./view/components/common/Authorization";
import { Main } from "./view/components/layout/Main";

import { RootProvider } from "./view/context/providers";
import { Notificator } from "./view/components/mix";

export function App() {
    return (
        <RootProvider>
            <Authorization>
                <Main />

                <Notificator />
            </Authorization>
        </RootProvider>
    );
}
