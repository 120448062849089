import React, { useCallback, useMemo } from "react";
import type { ChangeAction, Group, Option } from "./components/UniversalSelect";
import { UniversalSelect } from "./components/UniversalSelect";
import { Person } from "../../../../../mix";
import _ from "lodash";
import { useFindAllUsersQuery } from "../../../../../../../store/services";

export interface AbstractUser {
    id: number;
    role: string;
    fullName: string;
    logo: string | null;
}

interface IUserOptions {
    label: string;
    value: string;
    logoSrc: string | null;
    role: string;
}

interface IRoleOptions {
    value: string;
    label: string;
}

type IUserByRole = Record<string, AbstractUser[]>;

interface ISelectedRolesOptions {
    halfSelected: boolean;
    value: string;
    label: string;
    groupKey?: string | undefined;
}

type RoleOption = Option & { halfSelected?: boolean };
type PersonOption = Option & { logoSrc?: string | null; role?: string };

interface OptionProps {
    value: PersonOption;
    groupKey?: string;
}

interface IProps {
    selectedResources: AbstractUser[];
    onChange: (newResources: AbstractUser[]) => void;
    extractData: AbstractUser[];
}

export function RolesPersonsSelect({ selectedResources, onChange, extractData }: IProps) {
    const { data: users = [] } = useFindAllUsersQuery();

    // An array of unique role strings.
    const roles = useMemo(() => _.uniq(_.map(users, (item: AbstractUser) => item.role)), [users]);

    // Computes a mapping of resources by their roles.
    // An object where each key is a role and the value is an array of resources with that role.
    const resourcesByRole: IUserByRole = useMemo(() => {
        return _.reduce(
            roles,
            (acc: Record<string, AbstractUser[]>, role: string) => {
                const newAcc = { ...acc };

                newAcc[role] = _.filter(users, { role });

                return newAcc;
            },
            {},
        );
    }, [roles, users]);

    // An array of objects where each object represents a role option with `value` and `label` properties.
    const roleOptions: IRoleOptions[] = useMemo(
        () => _.map(roles, (role: string) => ({ value: role, label: role })),
        [roles],
    );

    // Creates an array of resource options for use in a select component.
    const resourcesOptions: IUserOptions[] = useMemo(
        () =>
            _.map(users, (resource: AbstractUser) => ({
                label: resource.fullName,
                value: resource.id.toString(),
                logoSrc: resource.logo,
                role: resource.role,
            })),
        [users],
    );

    const rolesResourcesOptions: Group<Option>[] = useMemo(() => {
        return [
            {
                label: "Roles",
                key: "role",
                options: roleOptions,
            },
            {
                label: "Persons",
                key: "persons",
                options: resourcesOptions,
            },
        ];
    }, [roleOptions, resourcesOptions]);

    // An array of resource options that are currently selected.
    const selectedResourcesOptions: IUserOptions[] = useMemo(
        () =>
            _.filter<IUserOptions>(resourcesOptions, (option: PersonOption) =>
                _.some(
                    selectedResources,
                    (selectedResource: AbstractUser) => selectedResource.id.toString() === option.value,
                ),
            ),
        [selectedResources, resourcesOptions],
    );

    const selectedRolesOptions: ISelectedRolesOptions[] = useMemo(
        () =>
            roleOptions
                .filter((item: Option) =>
                    selectedResources.find((selectedResource: AbstractUser) =>
                        (resourcesByRole[item.value] ?? []).find(
                            (roleResource: AbstractUser) => roleResource.id === selectedResource.id,
                        ),
                    ),
                )
                .map((item: Option) => ({
                    ...item,
                    halfSelected:
                        !(resourcesByRole[item.value] ?? []).every((resource: AbstractUser) =>
                            selectedResources.find(
                                (selectedResource: AbstractUser) => selectedResource.id === resource.id,
                            ),
                        ) || false,
                })),
        [resourcesByRole, selectedResources, roleOptions],
    );

    const handleChange = useCallback(
        (value: PersonOption | PersonOption[] | null, { action, option }: ChangeAction<PersonOption>) => {
            if (option?.value === "all") {
                if (selectedResources.length === extractData.length) {
                    onChange([]);
                    return;
                }

                onChange(extractData);
                return;
            }

            const changedRole = _.find(roleOptions, { value: option?.value });

            if (changedRole) {
                const roleResources = resourcesByRole[changedRole.value];

                if (roleResources) {
                    if (action === "select-option") {
                        const newRoleResources = _.differenceBy(roleResources, selectedResources, "id");

                        onChange([...selectedResources, ...newRoleResources]);
                        return;
                    } else {
                        const newResources = _.differenceBy(selectedResources, roleResources, "id");

                        onChange(newResources);
                        return;
                    }
                }
            }

            onChange(
                _.filter(extractData, (item: AbstractUser) =>
                    _.some(
                        value as PersonOption[],
                        (selectedOption: PersonOption) => item.id.toString() === selectedOption.value,
                    ),
                ),
            );
        },
        [onChange, extractData, resourcesByRole, roleOptions, selectedResources],
    );

    const getLabel = (): string => {
        let label = `${selectedResources.length} Persons`;
        const fullSelectedRoles = _.filter(selectedRolesOptions, (item: Option) => !item.halfSelected);

        if (fullSelectedRoles.length) {
            label += `, ${fullSelectedRoles.length} Roles`;
        }

        return label;
    };

    return (
        // @ts-expect-error TODO fix it later
        <UniversalSelect<PersonOption & RoleOption>
            options={rolesResourcesOptions}
            placeholder="Created by (Role & Persons)"
            isMultiple
            value={[...selectedResourcesOptions, ...selectedRolesOptions]}
            onChange={handleChange}
            Option={CustomOption}
            getLabel={getLabel}
        />
    );
}

function CustomOption({ value: optionValue, groupKey }: OptionProps) {
    if (optionValue.value === "all") return <span>{optionValue.label}</span>;

    return groupKey === "persons" ? (
        <div
            style={{
                width: "160px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }}
        >
            <Person
                name={optionValue.label}
                logo={optionValue.logoSrc ?? ""}
                role={optionValue.role ?? ""}
            />
        </div>
    ) : (
        <div
            style={{
                width: "160px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }}
        >
            {optionValue.label}
        </div>
    );
}
