import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import classnames from "classnames/bind";
import type { RootState } from "src/store/store";
import { useAppSelector } from "src/store/store";
import { SideMenuHeader } from "./SideMenuHeader/SideMenuHeader.com";
import { SideMenuFooter } from "./SideMenuFooter/SideMenuFooter";
import type { Report, ReportsGroup, ReportUserPair, User } from "src/core/entities";
import { useFindAllUsersQuery } from "src/store/services";
import _ from "lodash";
import { DotSpinner } from "../../basic/spinners";

import styles from "./SideMenu.module.scss";
import { CollapseButton, IframeFullscreenButton } from "../../basic/buttons";
import { SideMenuReports } from "./SideMenuReports";
import { useReports, useVisibleReportsList } from "../../../hooks";

const cx = classnames.bind(styles);

function View() {
    const [isMenuCollapsed, setMenuCollapsed] = useState(false);
    const [openedGroups, setOpenedGroups] = useState<Set<string>>(new Set());
    const [searchValue, setSearchValue] = useState<string>("");
    const [usersIds, setUsersIds] = useState<number[]>([]);

    const { data: visibleReports, isLoading: isReportsLoading } = useVisibleReportsList();
    const { data: users, isLoading: isUsersLoading } = useFindAllUsersQuery();

    const { report: selectedReport } = useAppSelector((state: RootState) => state.reports);

    const { actions: reportsActions } = useReports();

    /**
     * Create report-user pairs, group and sort by report.sourceId
     */
    const groups = useMemo(() => {
        if (visibleReports && users) {
            const g = _.chain(visibleReports)
                .groupBy("sourceId")
                .mapValues((r: Report[]) => {
                    return r.map((report: Report) => {
                        const foundUser = users.find((u: User) => u.email === report.creatorEmail);

                        const pair: ReportUserPair = {
                            report,
                            user: foundUser ?? null,
                        };

                        return pair;
                    });
                })
                .toPairs()
                .map(([sourceId, pairs]: [string, ReportUserPair[]]) => ({ sourceId, pairs }))
                .value();

            g.sort((a: ReportsGroup, b: ReportsGroup) => {
                if (a.sourceId < b.sourceId) {
                    return -1;
                }

                if (a.sourceId > b.sourceId) {
                    return 1;
                }

                return 0;
            });

            return g;
        }

        return [];
    }, [visibleReports, users]);

    /**
     * Filter groups by search value and selected users
     */
    const filteredGroups = useMemo(() => {
        const trimSearch = searchValue.trim();

        if (trimSearch === "" && usersIds.length === 0) {
            return groups;
        }

        return groups
            .map((group: ReportsGroup) => {
                const filteredPairs = group.pairs.filter((pair: ReportUserPair) => {
                    const matchesSearch = trimSearch
                        ? pair.report.name.toLowerCase().includes(trimSearch.toLowerCase())
                        : true;

                    const matchesUsers =
                        usersIds.length > 0 //
                            ? pair.user
                                ? usersIds.includes(pair.user.id)
                                : false
                            : true;

                    return matchesSearch && matchesUsers;
                });

                return {
                    ...group,
                    pairs: filteredPairs,
                };
            })
            .filter((item: ReportsGroup) => item.pairs.length > 0);
    }, [groups, searchValue, usersIds]);

    /**
     * Open group that contains selected report
     */
    useLayoutEffect(() => {
        if (selectedReport) {
            setOpenedGroups((prev: Set<string>) => {
                const set = new Set(prev);

                set.add(selectedReport.sourceId);

                return set;
            });
        }
    }, [selectedReport]);

    const handleMenuToggle = useCallback(() => {
        setMenuCollapsed((val: boolean) => !val);
    }, []);

    function handleGroupsToggle(sourceId: string) {
        setOpenedGroups((prev: Set<string>) => {
            const set = new Set(prev);

            set.has(sourceId) ? set.delete(sourceId) : set.add(sourceId);

            return set;
        });
    }

    function handleReportSelect(report: Report) {
        if (selectedReport?.id !== report.id) {
            reportsActions.setReport(report);
        }
    }

    const isLoading = isReportsLoading || isUsersLoading;

    return (
        <div
            className={cx("side-menu", {
                "is-menu-collapsed": isMenuCollapsed,
            })}
        >
            <div className={cx("iframe-fullscreen")}>
                <IframeFullscreenButton />
            </div>

            <div className={cx("side-menu-wrapper")}>
                <div className={cx("collapse-menu-button-wrapper")}>
                    <CollapseButton
                        collapsed={isMenuCollapsed}
                        onClick={handleMenuToggle}
                    />
                </div>

                <div className={cx("side-menu-content")}>
                    <div className={cx("side-menu-header-wrapper")}>
                        <div className={cx("header-title")}>Created Reports</div>

                        <SideMenuHeader
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setResourceFilter={setUsersIds}
                        />
                    </div>

                    <div className={cx("side-menu-reports-wrapper")}>
                        <div className={cx("list")}>
                            {isLoading ? (
                                <div className={cx("reports-loading")}>
                                    <DotSpinner />
                                </div>
                            ) : (
                                <SideMenuReports
                                    groups={filteredGroups}
                                    openedGroups={openedGroups}
                                    onGroupClick={handleGroupsToggle}
                                    onReportSelect={handleReportSelect}
                                    selectedReportId={selectedReport?.id ?? null}
                                />
                            )}
                        </div>
                    </div>

                    <SideMenuFooter />
                </div>
            </div>
        </div>
    );
}

export const SideMenu = React.memo(View);
