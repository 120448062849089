import { reportsApi, sourcesApi, usersApi } from "../services";
import { authSlice, iframeSlice, persistenceSlice, popupsSlice, reportsSlice } from "../slices";

export const rootReducer = {
    // slices
    auth: authSlice.reducer,
    popups: popupsSlice.reducer,
    reports: reportsSlice.reducer,
    persistence: persistenceSlice.reducer,
    iframe: iframeSlice.reducer,

    // api
    [reportsApi.reducerPath]: reportsApi.reducer,
    [sourcesApi.reducerPath]: sourcesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
};
