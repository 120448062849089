import type { ActionReducerMapBuilder, Draft, PayloadAction } from "@reduxjs/toolkit";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { reportsSlice } from "./reports.slice";
import type { Report } from "../../core/entities";
import { reportsApi } from "../services";
import type { NoInfer } from "react-redux";

interface PersistenceState {
    reportId: number | null;
}

const initialState: PersistenceState = {
    reportId: null,
};

/**
 * Persist some data from another parts of store
 */
export const persistenceSlice = createSlice({
    name: "persistence-slice",
    initialState,
    reducers: {
        clear: (state: Draft<PersistenceState>) => {
            state.reportId = null;
        },
    },

    extraReducers: (builder: ActionReducerMapBuilder<NoInfer<PersistenceState>>) => {
        builder.addCase(
            reportsSlice.actions.setReport,
            (state: Draft<PersistenceState>, action: PayloadAction<Report | null>) => {
                state.reportId = action.payload?.id ?? null;
            },
        );

        builder.addMatcher(
            isAnyOf(
                reportsApi.endpoints.create.matchFulfilled,
                reportsApi.endpoints.update.matchFulfilled,
                reportsApi.endpoints.copy.matchFulfilled,
            ),
            (state: Draft<PersistenceState>, action: PayloadAction<Report>) => {
                state.reportId = action.payload.id;
            },
        );
    },
});

export const {
    clear: clearPersitenceStore, //
} = persistenceSlice.actions;
