import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";

import classnames from "classnames/bind";

import styles from "./ReportBuilderTable.module.scss";
import type { RootState } from "../../../../store/store";
import { useAppSelector } from "../../../../store/store";
import invariant from "tiny-invariant";
import { DataTable } from "../DataTable";
import { useUpdateReportMutation } from "src/store/services";
import { usePopups, useReportData, useReports } from "src/view/hooks";
import { DotSpinner } from "../../basic/spinners";
import type {
    ColDef,
    ICellRendererParams,
    SortChangedEvent,
    ValueFormatterParams,
    ValueGetterParams,
} from "@ag-grid-community/core";
import type { RawSourceItem, ReportColumn, SourceColumn, SourceItem, SourceItemValue } from "../../../../core/entities";
import { hasReportPermissionsCheck } from "src/utils/common";
import { formatDateView, formatNumberView, formatUserView } from "../../../../utils/helpers";
import { INDEX_COLUMN_NAME } from "src/core/constants";

const cx = classnames.bind(styles);

const INDEX_COLUMN_WITH = 80;

const INDEX_COLUMN: ColDef = {
    headerName: "#",
    field: INDEX_COLUMN_NAME,
    width: INDEX_COLUMN_WITH,
    minWidth: INDEX_COLUMN_WITH,
    maxWidth: INDEX_COLUMN_WITH,
    lockPinned: true,
    pinned: true,
    lockPosition: true,
    enableValue: false,
    sortable: false,
    resizable: false,
    cellClass: "lock-pinned",
    headerClass: "lock-header",
    cellStyle: {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 12,
    },
    valueGetter: (params: ValueGetterParams<SourceItem<unknown>, unknown>) => {
        if (!params.node?.isRowPinned()) {
            return (params.node?.rowIndex ?? 0) + 1;
        }

        return params.data?.[INDEX_COLUMN_NAME]?.value;
    },
};

function reorder(list: ReportColumn[], start: number, end: number) {
    const newList = [...list];

    const [removed] = newList.splice(start, 1);

    if (removed) {
        newList.splice(end, 0, removed);
    }

    return newList.map((column: ReportColumn, index: number) => ({
        ...column,
        position: index,
    }));
}

function View({ searchValue }: Props) {
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
    const [rowData, setRowData] = useState<SourceItem<unknown>[]>([]);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState<Record<string, unknown>[]>([]);

    const { actions: popupsActions } = usePopups();
    const { actions: reportsActions } = useReports();

    const { report, draft } = useAppSelector((state: RootState) => state.reports);
    const { user } = useAppSelector((state: RootState) => state.auth);

    const [updateReport] = useUpdateReportMutation();

    invariant(draft, "Draft should be specified");

    const COL_ID_SUFFIX = " - " + draft.id;

    const {
        columns, //
        sourceData,
        isColumnsLoading,
        isColumnsFetching,
        isSourceDataLoading,
        isSourceDataFetching,
    } = useReportData(draft);

    const isLoading = isColumnsLoading || isSourceDataLoading;
    const isFetching = isColumnsFetching || isSourceDataFetching;

    /**
     * Create and set columns definitions for data-table
     */
    useLayoutEffect(() => {
        if (isFetching) return;

        if (columns.length === 0) {
            setColumnDefs([]);

            return;
        }

        setColumnDefs(() => {
            return [
                INDEX_COLUMN,
                ...columns.map((col: SourceColumn): ColDef => {
                    const defaultField: ColDef = {
                        field: col.name,
                        colId: col.id.toString() + COL_ID_SUFFIX,
                        valueGetter: (params: ValueGetterParams<SourceItem<unknown>, unknown>) => {
                            return params.data?.[col.name]?.value;
                        },
                    };

                    if (col.type === "NUMERIC") {
                        return {
                            cellDataType: "number",
                            valueFormatter: (params: ValueFormatterParams<SourceItem<number>, number>) => {
                                if (params.node?.rowPinned) {
                                    return params.value?.toString() ?? "";
                                }

                                if (typeof params.value !== "number") return "-";

                                return formatNumberView(params.value, params.colDef.field as string);
                            },
                            ...defaultField,
                        };
                    }

                    if (col.type === "DATE") {
                        return {
                            cellDataType: "date",
                            valueFormatter: (params: ValueFormatterParams<SourceItem<Date>, Date>) => {
                                if (params.node?.rowPinned) return "";

                                if (!params.value) return "-";

                                return formatDateView(params.value);
                            },
                            ...defaultField,
                        };
                    }

                    if (col.type === "CHECKBOX") {
                        return {
                            cellDataType: "boolean",
                            ...defaultField,
                        };
                    }

                    if (col.originalType === "User") {
                        return {
                            valueFormatter: (params: ValueFormatterParams<SourceItem<string>, string>) => {
                                if (params.node?.rowPinned) return "";

                                if (!params.value) return "-";

                                return formatUserView(params.value);
                            },
                            ...defaultField,
                        };
                    }

                    if (col.originalType === "URL") {
                        return {
                            cellRenderer: (params: ICellRendererParams<SourceItem<string>, string>) => {
                                if (params.node.rowPinned) return "";

                                if (!params.value) return "-";

                                return (
                                    <a
                                        style={{
                                            color: "#19B3C6",
                                        }}
                                        href={params.value}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {params.value}
                                    </a>
                                );
                            },
                            ...defaultField,
                        };
                    }

                    return {
                        valueFormatter: (params: ValueFormatterParams<SourceItem<string>, string>) => {
                            if (params.node?.rowPinned) return "";

                            if (!params.value) return "-";

                            return params.value;
                        },
                        ...defaultField,
                    };
                }),
            ];
        });
    }, [COL_ID_SUFFIX, columns, isFetching]);

    /**
     * Set row data to data-table
     */
    useLayoutEffect(() => {
        if (isFetching) return;

        if (columns.length === 0) {
            setRowData([]);

            return;
        }

        const columnsMap = new Map<string, SourceColumn>();

        columns.forEach((column: SourceColumn) => {
            columnsMap.set(column.name, column);
        });

        setRowData(
            sourceData.map((raw: RawSourceItem): SourceItem<unknown> => {
                const item: SourceItem<unknown> = {};

                Object.entries(raw).forEach(([key, value]: [string, unknown]) => {
                    const column = columnsMap.get(key);

                    if (column) {
                        item[key] = {
                            column,
                            value,
                        };
                    }
                });

                return item;
            }),
        );
    }, [columns, sourceData, isFetching]);

    /**
     * Filter row data by search value
     */
    const filteredRowData = useMemo(() => {
        if (searchValue.trim() === "") return rowData;

        return rowData.filter((row: SourceItem<unknown>) => {
            return Object.values(row).some((source: SourceItemValue<unknown>) => {
                if (typeof source.value === "string" || typeof source.value === "number") {
                    return source.value.toString().toLowerCase().includes(searchValue.trim().toLowerCase());
                }

                return false;
            });
        });
    }, [rowData, searchValue]);

    /**
     * Set totals and averages
     */
    useLayoutEffect(() => {
        function getTotals(data: SourceItem<unknown>[], columnName: string) {
            return data.reduce((valueAcc: number, sourceItem: SourceItem<unknown>) => {
                const itemValue = sourceItem[columnName]?.value;

                if (typeof itemValue === "number") {
                    return valueAcc + itemValue;
                }

                return valueAcc;
            }, 0);
        }

        if (isFetching) return;

        if (columns.length === 0) {
            setPinnedBottomRowData([]);

            return;
        }

        const hasTotals = draft.columns.some((col: ReportColumn) => col.showTotals === true);
        const hasAverages = draft.columns.some((col: ReportColumn) => col.showAverages === true);

        if (!hasTotals && !hasAverages) {
            setPinnedBottomRowData([]);

            return;
        }

        const pinnedRows: Record<string, unknown>[] = [];

        if (hasTotals) {
            pinnedRows.push(
                columns.reduce(
                    (acc: Record<string, unknown>, val: SourceColumn) => {
                        const newAcc = { ...acc };

                        const foundReportColumn = draft.columns.find((col: ReportColumn) => {
                            return col.showTotals && col.id === val.id;
                        });

                        if (val.type === "NUMERIC" && foundReportColumn) {
                            const value = getTotals(filteredRowData, val.name);

                            newAcc[val.name] = { value: formatNumberView(+value.toFixed(2), val.name) };
                        }

                        return newAcc;
                    },
                    { [INDEX_COLUMN_NAME]: { value: "Totals" } },
                ),
            );
        }

        if (hasAverages) {
            pinnedRows.push(
                columns.reduce(
                    (acc: Record<string, unknown>, val: SourceColumn) => {
                        const newAcc = { ...acc };

                        const { length } = filteredRowData.filter(
                            (item: SourceItem<unknown>) => typeof item[val.name]?.value === "number",
                        );

                        const foundReportColumn = draft.columns.find((col: ReportColumn) => {
                            return col.showAverages && col.id === val.id;
                        });

                        if (val.type === "NUMERIC" && foundReportColumn) {
                            const value = getTotals(filteredRowData, val.name);

                            newAcc[val.name] = {
                                value: "Ø " + formatNumberView(length > 0 ? +(value / length).toFixed(2) : 0, val.name),
                            };
                        }

                        return newAcc;
                    },
                    { [INDEX_COLUMN_NAME]: { value: "Averages" } },
                ),
            );
        }

        setPinnedBottomRowData(pinnedRows);
    }, [draft, columns, filteredRowData, isFetching]);

    const handleColumnMoved = useCallback(
        (colId: string, toIndex: number) => {
            invariant(report, "Report should be specified");
            invariant(user, "User should be specified");

            /**
             * Because we have fixed index column
             */
            const toIndexFixed = toIndex - 1;

            if (columns.length === 0 || columns[toIndexFixed]?.id + COL_ID_SUFFIX === colId) return;

            const foundFromIndex = columns.findIndex((column: SourceColumn) => column.id + COL_ID_SUFFIX === colId);

            if (foundFromIndex === -1) return;

            const reorderedColumns = reorder(draft.columns, foundFromIndex, toIndexFixed);

            reportsActions.setDraftColumns(reorderedColumns);

            if (hasReportPermissionsCheck(draft, user)) {
                reportsActions.setReportColumns(reorderedColumns);

                void updateReport({
                    args: {
                        id: report.id,
                        name: report.name,
                        type: report.type,
                        columns: reorderedColumns,
                        filters: report.filters,
                        API_URL: report.API_URL,
                    },
                    options: {
                        silent: true,
                    },
                });
            } else {
                popupsActions.notReportCreatorWarningPopup.open();
            }
        },
        [COL_ID_SUFFIX, report, draft, columns, user, reportsActions, popupsActions, updateReport],
    );

    const handleSortChanged = useCallback((event: SortChangedEvent<SourceItem<unknown>>) => {
        event.api.refreshCells({
            columns: [INDEX_COLUMN_NAME],
        });
    }, []);

    if (isLoading) {
        return (
            <div className={cx("loading")}>
                <DotSpinner />
            </div>
        );
    }

    return (
        <div className={cx("report-builder-table")}>
            <DataTable
                rowData={filteredRowData}
                columnDefs={columnDefs}
                pinnedBottomRowData={pinnedBottomRowData}
                isLoading={isFetching}
                onColumnMoved={handleColumnMoved}
                onSortChanged={handleSortChanged}
            />
        </div>
    );
}

interface Props {
    searchValue: string;
}

export const ReportBuilderTable = React.memo(View);
