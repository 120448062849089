import React, { useLayoutEffect } from "react";

import classnames from "classnames/bind";

import styles from "./CopyReportPopup.module.scss";
import type { ReportBasicFormOutput } from "src/view/components/forms";
import { ReportBasicForm } from "src/view/components/forms";
import { BasicPopup } from "../../base";
import { PopupHeader } from "../../../mix";
import { useCopyReportMutation, useFindAllReportsQuery } from "src/store/services";
import type { RootState } from "../../../../../store/store";
import { useAppSelector } from "../../../../../store/store";
import invariant from "tiny-invariant";
import { useNotifications, usePopups } from "src/view/hooks";
import { isErrorWithMessage, isFetchBaseQueryError } from "src/utils/helpers";

const cx = classnames.bind(styles);

export function CopyReportPopup() {
    const { actions: popupsActions } = usePopups();
    const { notify } = useNotifications();

    const { report } = useAppSelector((state: RootState) => state.reports);
    const { user } = useAppSelector((state: RootState) => state.auth);

    const { isLoading: isReportsLoading } = useFindAllReportsQuery();

    const [copyReport, { error: copyReportError }] = useCopyReportMutation();

    useLayoutEffect(() => {
        if (isFetchBaseQueryError(copyReportError) && copyReportError.status === "FETCH_ERROR") {
            notify.error(copyReportError.error);
        }

        if (isErrorWithMessage(copyReportError)) {
            notify.error(copyReportError.message);
        }
    }, [copyReportError, notify]);

    async function handleSubmit(values: ReportBasicFormOutput) {
        invariant(report);
        invariant(user);

        const res = await copyReport({
            args: {
                id: report.id,
                name: values.name,
                type: values.type,
                creatorEmail: user.email,
            },
        });

        if ("data" in res) {
            popupsActions.copyReportPopup.close();
        }
    }
    function handleClose() {
        popupsActions.copyReportPopup.close();
    }

    return (
        <BasicPopup
            isLoading={isReportsLoading}
            onClose={handleClose}
        >
            <div className={cx("copy-report-popup")}>
                <div className={cx("popup-header")}>
                    <PopupHeader
                        title="Create New Report from Copy"
                        onClose={handleClose}
                    />
                </div>

                <ReportBasicForm
                    mode="copy"
                    onSubmit={handleSubmit}
                    onCancel={handleClose}
                />
            </div>
        </BasicPopup>
    );
}
