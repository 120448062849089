import type { Draft, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface IframeState {
    isFullscreen: boolean;
}

const initialState: IframeState = {
    isFullscreen: false,
};

export const iframeSlice = createSlice({
    name: "iframe-slice",
    initialState,
    reducers: {
        setFullscreen: (state: Draft<IframeState>, action: PayloadAction<boolean>) => {
            state.isFullscreen = action.payload;
        },
    },
});
