import { format } from "date-fns";
import type { ReportFilterDateChoices } from "../../core/entities";
import { DatePeriod, DateType } from "../../core/enums";
import { isYearColumn } from "./string.helpers";

function formatValue(value: number | Date | null): string {
    if (value === null) return "";

    if (typeof value === "number") {
        return value.toString();
    }

    return formatDateView(value);
}

function formatDatePeriod(period: DatePeriod | null): string {
    if (period === null) return "";

    switch (period) {
        case DatePeriod.CURRENT_DAY:
            return "current day";

        case DatePeriod.THIS_WEEK:
            return "this week";

        case DatePeriod.THIS_MONTH:
            return "this month";

        case DatePeriod.THIS_QUARTER:
            return "this quarter";

        case DatePeriod.THIS_YEAR:
            return "this year";

        case DatePeriod.LAST_WEEK:
            return "last week";

        case DatePeriod.LAST_MONTH:
            return "last month";

        case DatePeriod.LAST_QUARTER:
            return "last quarter";

        case DatePeriod.LAST_YEAR:
            return "last year";

        case DatePeriod.NEXT_WEEK:
            return "next week";

        case DatePeriod.NEXT_MONTH:
            return "next month";

        case DatePeriod.NEXT_QUARTER:
            return "next quarter";

        case DatePeriod.NEXT_YEAR:
            return "next year";

        case DatePeriod.DAYS_IN_FUTURE:
            return "days in future";

        case DatePeriod.DAYS_PAST:
            return "days past";

        case DatePeriod.THE_DATE:
            return "the date";
    }
}

function formatDatePeriodWithValue(choices: ReportFilterDateChoices): string {
    if (choices === null) return "";

    return [
        [
            formatDatePeriod(choices.periodFrom), //
            formatValue(choices.valueFrom),
        ]
            .filter(Boolean)
            .join(" "),
        [
            formatDatePeriod(choices.periodTo), //
            formatValue(choices.valueTo),
        ]
            .filter(Boolean)
            .join(" "),
    ]
        .filter(Boolean)
        .join(" and ");
}

export function formatDateFilterChoicesView(choices: ReportFilterDateChoices): string {
    if (choices === null) return "";

    switch (choices.dateType) {
        case DateType.IS:
            return "Is " + formatDatePeriodWithValue(choices);

        case DateType.IS_NOT:
            return "Is not " + formatDatePeriodWithValue(choices);

        case DateType.IS_AFTER:
            return "Is after " + formatDatePeriodWithValue(choices);

        case DateType.IS_ON_OR_AFTER:
            return "Is on or after " + formatDatePeriodWithValue(choices);

        case DateType.IS_BEFORE:
            return "Is before " + formatDatePeriodWithValue(choices);

        case DateType.IS_ON_OR_BEFORE:
            return "Is on or before " + formatDatePeriodWithValue(choices);

        case DateType.BETWEEN:
            return "Between " + formatDatePeriodWithValue(choices);

        case DateType.NOT_BETWEEN:
            return "Not between " + formatDatePeriodWithValue(choices);

        default:
            return "";
    }
}

export function formatDateView(date: Date): string {
    return format(date, "dd MMM yyyy");
}

export function formatUserView(name: string): string {
    return name.replace(/<.*?>/, "").trim();
}

export function formatMinMaxNumericView(min: number, max: number): string {
    return `${min} - ${max}`;
}

/**
 * Format to 42 114.43 like value
 */
export function formatNumberView(value: number, name: string): string {
    if (isYearColumn(name)) {
        return value.toString();
    }

    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}

export function formatBooleanView(value: boolean): string {
    return value ? "Yes" : "No";
}
