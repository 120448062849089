import React, { useCallback, useState } from "react";

import classnames from "classnames/bind";

import { BasicPopup } from "../../base";
import { ButtonWrapper, PopupHeaderEmpty } from "../../../mix";
import { BasicButton, GhostButton } from "../../../basic/buttons";

import styles from "./DialogPopup.module.scss";

const cx = classnames.bind(styles);

function View({
    titles, //
    onConfirm,
    onCancel,
    onClose,
    children,
}: Props) {
    const [isLoading, setLoading] = useState(false);

    const handleConfirm = useCallback(async () => {
        setLoading(true);

        try {
            await onConfirm();
        } catch {
            //
        }

        setLoading(false);
    }, [onConfirm]);

    return (
        <BasicPopup onClose={onClose}>
            <div className={cx("dialog-popup")}>
                <PopupHeaderEmpty onClose={onClose} />

                <div>{children}</div>

                <div className={cx("buttons")}>
                    <ButtonWrapper shift="center">
                        <BasicButton
                            title={titles.confirm}
                            onClick={handleConfirm}
                            style={{ width: "100px" }}
                            isLoading={isLoading}
                        />
                        <GhostButton
                            title={titles.cancel}
                            style={{ width: "100px", marginLeft: "16px" }}
                            onClick={onCancel}
                        />
                    </ButtonWrapper>
                </div>
            </div>
        </BasicPopup>
    );
}

interface Props extends ChildrenProps {
    titles: {
        confirm: string;
        cancel: string;
    };
    onConfirm: () => Promise<void> | void;
    onCancel: () => void;
    onClose: () => void;
}

export const DialogPopup = React.memo(View);
