import React from "react";

import classnames from "classnames/bind";

import styles from "./EditReportFiltersPopup.module.scss";
import { SidePopup } from "../../base";
import { PopupHeader } from "src/view/components/mix/PopupHeader/PopupHeader.com";
import { ReportFiltersForm } from "src/view/components/forms";
import type { ReportFormOutput } from "../../../../../validation";
import type { RootState } from "../../../../../store/store";
import { useAppSelector } from "../../../../../store/store";
import invariant from "tiny-invariant";
import { usePopups } from "src/view/hooks";

const cx = classnames.bind(styles);

export function EditReportFiltersPopup() {
    const { actions: popupsActions } = usePopups();

    const { report } = useAppSelector((state: RootState) => state.reports);

    function handleSubmit(values: ReportFormOutput) {
        invariant(report, "Report should be specified");

        popupsActions.confirmSaveReportPopup.open({
            mode: "edit",
            args: {
                id: report.id,
                name: values.name,
                type: values.type,
                columns: values.columns,
                filters: values.filters,
                API_URL: values.API_URL,
            },
        });
    }

    function handleBack() {
        popupsActions.editReportColumnsPopup.open();
    }

    function handleClose() {
        popupsActions.editReportFiltersPopup.close();
    }

    return (
        <SidePopup onClose={handleClose}>
            <div className={cx("edit-report-filters-popup")}>
                <div className={cx("popup-header")}>
                    <PopupHeader
                        title="Edit Filters"
                        description="Apply filters, set the order and select favorites to build report"
                        onClose={handleClose}
                    />
                </div>

                <ReportFiltersForm
                    mode="edit"
                    onSubmit={handleSubmit}
                    onBack={handleBack}
                />
            </div>
        </SidePopup>
    );
}
