import React from "react";

import classnames from "classnames/bind";

import styles from "./Description.module.scss";

const cx = classnames.bind(styles);

export function Description({ title, description }: Props) {
    return (
        <div className={cx("description")}>
            <span className={cx("description-title")}>{title}</span>
            <span className={cx("description-text")}>{description}</span>
        </div>
    );
}

interface Props {
    title: string;
    description: string;
}
