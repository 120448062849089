import { useFindAllReportsQuery } from "../../store/services";
import { useMemo } from "react";
import { hasReportPermissionsCheck } from "../../utils/common";
import type { Report } from "../../core/entities";
import type { RootState } from "src/store/store";
import { useAppSelector } from "src/store/store";
import invariant from "tiny-invariant";

function createHook() {
    return function useVisibleReportsList() {
        const { data, isLoading, isError } = useFindAllReportsQuery();
        const { user } = useAppSelector((state: RootState) => state.auth);

        invariant(user, "User should be specified");

        /**
         * Show only public or user-owned reports
         */
        const visibleReports = useMemo(() => {
            if (!data) return undefined;

            return data.filter((report: Report) => {
                return [
                    hasReportPermissionsCheck(report, user), //
                    report.type === "Public",
                ].some(Boolean);
            });
        }, [data, user]);

        return {
            data: visibleReports,
            isLoading,
            isError,
        };
    };
}

export const useVisibleReportsList = createHook();
