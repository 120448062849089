import type { ChangeEvent } from "react";
import React, { useCallback, useLayoutEffect, useState } from "react";

import classnames from "classnames/bind";

import styles from "./MinMaxInput.module.scss";

import { ReactComponent as CheckIcon } from "src/assets/icons/check-icon.svg";
import { ReactComponent as ClearIcon } from "src/assets/icons/clean-icon.svg";

const cx = classnames.bind(styles);

const pattern = /^\d*$/;

export function View({
    value, //
    onChange,
}: Props) {
    const [min, setMin] = useState<string>("");
    const [max, setMax] = useState<string>("");

    useLayoutEffect(() => {
        if (value === null) {
            setMin("");
            setMax("");

            return;
        }

        setMin(value.min.toString());
        setMax(value.max.toString());
    }, [value]);

    const handleApply = useCallback(() => {
        if (!min || !max) {
            setMin("");
            setMax("");

            onChange(null);

            return;
        }

        onChange({
            min: parseInt(min, 10), //
            max: parseInt(max, 10),
        });
    }, [min, max, onChange]);

    const handleClear = useCallback(() => {
        setMin("");
        setMax("");

        onChange(null);
    }, [onChange]);

    // TODO max should be greater then min

    function handleMinChange(event: ChangeEvent<HTMLInputElement>) {
        const { target } = event;

        if (pattern.test(target.value)) {
            setMin(target.value);
        }
    }

    function handleMaxChange(event: ChangeEvent<HTMLInputElement>) {
        const { target } = event;

        if (pattern.test(target.value)) {
            setMax(target.value);
        }
    }

    return (
        <div className={cx("min-max-input")}>
            <div className={cx("input-block-wrapper")}>
                <div className={cx("input-block")}>
                    <input
                        type="text"
                        value={min}
                        onChange={handleMinChange}
                    />
                </div>
                <div className={cx("divider")}>-</div>
                <div className={cx("input-block")}>
                    <input
                        type="text"
                        value={max}
                        onChange={handleMaxChange}
                    />
                </div>
            </div>
            <div className={cx("input-control")}>
                <button
                    type="button"
                    aria-label="check input"
                    onClick={handleApply}
                    className={cx("check-button")}
                >
                    <CheckIcon />
                </button>
                <button
                    type="button"
                    aria-label="clear input"
                    onClick={handleClear}
                    className={cx("clear-button")}
                >
                    <ClearIcon />
                </button>
            </div>
        </div>
    );
}

type InputValue = {
    min: number;
    max: number;
} | null;

interface Props {
    value: InputValue;
    onChange: (value: InputValue) => void;
}

export const MinMaxInput = React.memo(View);
