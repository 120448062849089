import React from "react";

import classNames from "classnames/bind";

import styles from "./NoOptionsMessageBase.module.scss";
import type { NoticeProps } from "react-select";
import { components } from "react-select";

const cx = classNames.bind(styles);

export function NoOptionsMessageBase<T, IsMulti extends boolean>({ children, ...props }: NoticeProps<T, IsMulti>) {
    return (
        <components.NoOptionsMessage
            className={cx("no-options-message-base")}
            {...props}
        >
            {children}
        </components.NoOptionsMessage>
    );
}
