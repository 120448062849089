import React from "react";

import classnames from "classnames/bind";

import styles from "./EditReportPopup.module.scss";
import { BasicPopup } from "../../base";
import { PopupHeader } from "../../../mix";
import { ReportBasicForm } from "src/view/components/forms";
import { useFindAllReportsQuery } from "src/store/services";
import type { RootState } from "../../../../../store/store";
import { useAppSelector } from "../../../../../store/store";
import invariant from "tiny-invariant";
import { usePopups } from "src/view/hooks";

const cx = classnames.bind(styles);

export function EditReportPopup() {
    const { actions: popupsActions } = usePopups();

    const { isLoading: isReportsLoading } = useFindAllReportsQuery();

    const { report } = useAppSelector((state: RootState) => state.reports);

    invariant(report, "Report should be specified");

    function handleSubmit() {
        popupsActions.editReportColumnsPopup.open();
    }

    function handleClose() {
        popupsActions.editReportPopup.close();
    }

    return (
        <BasicPopup
            isLoading={isReportsLoading}
            onClose={handleClose}
        >
            <div className={cx("edit-report-popup")}>
                <div className={cx("popup-header")}>
                    <PopupHeader
                        title={`Edit ${report.name}`}
                        onClose={handleClose}
                    />
                </div>

                <ReportBasicForm
                    mode="edit"
                    onSubmit={handleSubmit}
                    onCancel={handleClose}
                />
            </div>
        </BasicPopup>
    );
}
