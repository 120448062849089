import React from "react";
import { DatePickerBase } from "../../base";
import type { ReactDatePickerProps } from "react-datepicker";

export function DatePickerSingle({
    value, //
    onChange,
    popperPlacement,
    isError,
}: Props) {
    function handleClear() {
        onChange(null);
    }

    return (
        <DatePickerBase<false>
            selected={value}
            popperPlacement={popperPlacement}
            fixedHeight
            shouldCloseOnSelect
            isError={isError}
            onChange={onChange}
            onClear={handleClear}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                event.preventDefault();
            }}
        />
    );
}

interface Props {
    value: Date | null;
    onChange: (newDates: Date | null) => void;
    popperPlacement?: ReactDatePickerProps["popperPlacement"];
    isError?: boolean;
}
