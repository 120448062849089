import React, { useMemo } from "react";

import classnames from "classnames/bind";

import styles from "./TabRadio.module.scss";

const cx = classnames.bind(styles);

interface OptionData<T> {
    value: T;
    label: string;
}

function TabRadio<T>({
    options, //
    value,
    onChange,
    disabled,
    type,
    ...attributes
}: Props<T>) {
    const highlightPosition = useMemo(() => {
        const index = options.findIndex((tab: OptionData<T>) => {
            return tab.value === value;
        });

        return `${(100 / options.length) * index}%`;
    }, [options, value]);

    if (options.length === 0) {
        return <div className={cx("no-options")}>No options</div>;
    }

    const tabWidth = `calc(100% / ${options.length})`;

    return (
        <div
            className={cx("tab-radio", {
                disabled,
                error: type === "error",
            })}
        >
            <div
                style={{
                    left: highlightPosition,
                    width: tabWidth,
                }}
                className={cx("active-tab-highlight-container")}
            >
                <div className={cx("highlight")} />
            </div>

            {options.map((option: OptionData<T>) => {
                const key = option.value;

                const checked = option.value === value;

                return (
                    <label
                        key={String(key)}
                        style={{
                            width: tabWidth,
                        }}
                    >
                        <input
                            type="radio"
                            value={String(option.value)}
                            checked={checked}
                            disabled={disabled}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                if (!disabled) {
                                    onChange(option.value || null, event);
                                }
                            }}
                            {...attributes}
                        />
                        <div className={cx("option-item")}>{option.label}</div>
                    </label>
                );
            })}
        </div>
    );
}

interface Props<T> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
    options: OptionData<T>[];
    value: T | null;
    onChange: (option: T | null, event: React.ChangeEvent<HTMLInputElement>) => void;
    type?: "default" | "error";
}

export default TabRadio;
