import React, { useMemo } from "react";
import classnames from "classnames/bind";
import type {
    ActionMeta,
    CSSObjectWithLabel,
    GroupBase,
    MultiValue,
    MultiValueProps,
    OptionProps,
    SingleValue,
} from "react-select";
import ReactSelect, { components } from "react-select";
import { MultipleCustomOption } from "../MultipleCustomOption/MultipleCustomOption";
import styles from "./UniversalSelect.module.scss";
import _ from "lodash";
import {
    ClearIndicatorBase,
    DropdownIndicatorBase,
    NoOptionsMessageBase,
} from "src/view/components/basic/inputs/select/base";
import { selectBaseStyles } from "../../../../config";

const cx = classnames.bind(styles);

export function UniversalSelect<T extends Option & Record<string, unknown>>(props: Props<T>) {
    const {
        options,
        isClearable = true,
        isMultiple = false,
        isOptionDisabled,
        value,
        label,
        Option,
        onChange,
        selectLabel,
        getLabel,
        placeholder,
        ...rest
    } = props;

    const isGroup = useMemo<boolean>(
        () =>
            _.some(
                options,
                (option: T | Group<T>) => _.has(option, "options") && !_.isEmpty((option as Group<T>).options),
            ),
        [options],
    );

    const formattedOptions = useMemo(() => {
        // Deep clone the options to avoid mutating the original array.
        let result: (T | Group<T>)[] = _.cloneDeep(options);
        // If options are not grouped and multiple selection is allowed, add an 'All' option.
        if (!isGroup) {
            if (isMultiple) {
                const allOption: Option = { value: "all", label: `All ${label ?? ""}` };
                // Add 'All' option only if it's not already present.
                if (!_.some(result as T[], { value: "all" })) {
                    result = [allOption as unknown as T, ...result];
                }
            }
        } else {
            // If options are grouped, process each group.
            result = _.map(options as Group<T>[], (group: Group<T>) => {
                const allOption: Option = {
                    value: "all",
                    label: `All ${group.label}`,
                    groupKey: group.key,
                };
                // Combine 'All' option with the group's options, ensuring 'All' is not duplicated.
                const newOptions = _.concat(
                    _.some(group.options, { value: "all" }) ? [] : [allOption as unknown as T],
                    _.map(group.options, (option: T) => ({ ...option, groupKey: group.key })),
                );

                return { ...group, options: newOptions };
            });
        }
        // Limit the result to the first 100 items if it's an array.
        return _.isArray(result) ? _.slice(result, 0, 100) : result;
    }, [options, isGroup, isMultiple, label]);

    const filteredValue = useMemo(() => {
        if (isGroup) {
            // If options are grouped, filter out any items with 'halfSelected' property set to true.
            return _.filter(value as T[], (item: Option) => !item.halfSelected);
        }
        return value;
    }, [value, isGroup]);

    const allOptions = useMemo(() => {
        if (isGroup) {
            // If the options are grouped, create one flat array of options from all the groups.
            return _.flatMap(options as Group<T>[], (group: Group<T>) => group.options);
        } else {
            return options;
        }
    }, [isGroup, options]);

    const handleChange = (newValue: MultiValue<T> | SingleValue<T>, actionMeta: ActionMeta<T>) => {
        let calculatedNewValue: T | T[] = newValue as T | T[];

        if (actionMeta.option?.value === "all") {
            const isSelected = (value as Option[]).length === allOptions.length;

            if (isSelected) calculatedNewValue = [];
            else calculatedNewValue = allOptions as T | T[];
        }
        onChange(calculatedNewValue, {
            action: actionMeta.action,
            option: actionMeta.option,
        });
    };

    return (
        <div className={cx("wrapper")}>
            <ReactSelect<T, typeof isMultiple, GroupBase<T>>
                {...rest}
                classNamePrefix="select"
                isMulti={isMultiple}
                isClearable={isClearable}
                options={formattedOptions}
                onChange={handleChange}
                unstyled
                styles={{
                    ...selectBaseStyles,
                    groupHeading(base: CSSObjectWithLabel) {
                        return {
                            ...base,
                            fontWeight: "700",
                            lineHeight: "20px",
                            color: "#454A54",
                            padding: "8px 16px 4px 8px",
                        };
                    },
                }}
                isSearchable={false}
                isOptionDisabled={isOptionDisabled}
                placeholder={placeholder}
                menuShouldScrollIntoView={false}
                components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: ClearIndicatorBase,
                    NoOptionsMessage: NoOptionsMessageBase,
                    DropdownIndicator: DropdownIndicatorBase,
                    MultiValue: getMultiValue(getLabel),
                    Option: getCustomOption(Option, value),
                }}
                value={filteredValue as T | T[]}
                hideSelectedOptions={false}
                closeMenuOnSelect={!isMultiple}
                classNames={{
                    placeholder: () => cx("placeholder"),
                }}
            />
        </div>
    );
}

function getMultiValue<T>(getLabel?: () => string) {
    return function MultiValueComponent({ children, ...props }: MultiValueProps<T, boolean, GroupBase<T>>) {
        let label;

        if (getLabel) {
            label = getLabel();
        }

        if (props.getValue().length === 0 && !label) {
            return <components.MultiValue {...props}>{children}</components.MultiValue>;
        }

        if (props.getValue().length === 1) {
            return <components.SingleValue {...props}>{children}</components.SingleValue>;
        }

        return <span>{!props.index && label}</span>;
    };
}

function getCustomOption<T extends Option & Record<string, unknown>>(
    Option: Props<T>["Option"],
    value: Option[] | Option | undefined | "",
) {
    return function CustomOption(optionProps: OptionProps<T, boolean, GroupBase<T>>) {
        const { children, data: optionData, isSelected: isSelectedOption, options, getValue, isMulti } = optionProps;
        let isSelected = isSelectedOption;
        let currentGroup: Group<T> | undefined;
        let halfSelected = false;
        const selectedOptions = getValue();

        if (optionData.groupKey) {
            currentGroup = _.find(options as Group<T>[], {
                key: optionData.groupKey,
            });
        }

        if (optionData.value === "all") {
            const currentOptions = currentGroup ? currentGroup.options : (options as T[]);

            const withoutAllOptions = currentOptions.filter((item: T) => item.value !== optionData.value);

            isSelected = _.every(withoutAllOptions, (item: T) => {
                return _.some(selectedOptions, { value: item.value });
            });

            halfSelected = _.some(withoutAllOptions, (item: T) => {
                return _.some(selectedOptions, { value: item.value });
            });
        }

        if (Option) {
            if (isMulti) {
                const data = _.find(value as Option[], { value: optionData.value });

                return (
                    <MultipleCustomOption
                        onSelect={() => {
                            optionProps.selectOption(optionProps.data);
                        }}
                        checked={isSelected ? true : data?.halfSelected ?? halfSelected ? "half-checked" : false}
                    >
                        <Option
                            isSelected={isSelected}
                            value={optionData}
                            groupKey={currentGroup?.key}
                        />
                    </MultipleCustomOption>
                );
            }

            return (
                <div
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                        optionProps.selectOption(optionProps.data);
                    }}
                >
                    <Option
                        isSelected={optionProps.isSelected}
                        value={optionProps.data}
                    />
                </div>
            );
        }

        if (isMulti) {
            const data = _.find(value as Option[], { value: optionData.value });

            return (
                <MultipleCustomOption
                    onSelect={() => {
                        optionProps.selectOption(optionProps.data);
                    }}
                    checked={isSelected ? true : data?.halfSelected ?? halfSelected ? "half-checked" : false}
                >
                    <span>{optionData.label}</span>
                </MultipleCustomOption>
            );
        }

        return <components.Option {...optionProps}>{children}</components.Option>;
    };
}

export interface Option {
    value: string;
    label: string;
    groupKey?: string;
    halfSelected?: boolean;
}

export interface Group<T extends Option | Record<string, unknown>> {
    options: T[];
    label: string;
    key?: string;
}

export interface ChangeAction<T> {
    option: T | undefined;
    action: ActionMeta<T>["action"];
}

export interface Props<T extends Option & Record<string, unknown>> {
    value: Option | Option[] | undefined | "";
    onChange: (value: T | T[] | null, action: ChangeAction<T>) => void;
    options: T[] | Group<T>[];
    isClearable?: boolean;
    placeholder?: string;
    isMultiple?: boolean;
    isOptionDisabled?: (option: T) => boolean;
    label?: string;
    selectLabel?: string;
    getLabel?: () => string;
    Option?: (props: { value: T; isSelected?: boolean; groupKey?: string }) => JSX.Element;
}
