import type { ActionReducerMapBuilder, Draft, PayloadAction } from "@reduxjs/toolkit";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import type { ReportMutationOptions } from "../services";
import { reportsApi } from "../services";
import type { NoInfer } from "react-redux";
import type { Report, ReportColumn, ReportFilter } from "../../core/entities";

interface ReportsState {
    report: Report | null;
    draft: Report | null;
}

const initialState: ReportsState = {
    report: null,
    draft: null,
};

export const reportsSlice = createSlice({
    name: "reports-slice",
    initialState,

    reducers: {
        setReport: (state: Draft<ReportsState>, action: PayloadAction<Report | null>) => {
            state.report = action.payload;
            state.draft = action.payload;
        },
        setDraft: (state: Draft<ReportsState>, action: PayloadAction<Report | null>) => {
            state.draft = action.payload;
        },
        setReportColumns: (state: Draft<ReportsState>, action: PayloadAction<ReportColumn[]>) => {
            if (!state.report) return;

            state.report.columns = action.payload;
        },
        setDraftColumns: (state: Draft<ReportsState>, action: PayloadAction<ReportColumn[]>) => {
            if (!state.draft) return;

            state.draft.columns = action.payload;
        },
        updateDraftColumn: (state: Draft<ReportsState>, action: PayloadAction<ReportColumn>) => {
            if (!state.draft) return;

            const foundColumnIndex = state.draft.columns.findIndex((column: ReportColumn) => {
                return column.id === action.payload.id;
            });

            if (foundColumnIndex !== -1) {
                state.draft.columns.splice(foundColumnIndex, 1, action.payload);
            }
        },
        updateDraftFilter: (state: Draft<ReportsState>, action: PayloadAction<ReportFilter>) => {
            if (!state.draft) return;

            const foundFilterIndex = state.draft.filters.findIndex((filter: ReportFilter) => {
                return filter.id === action.payload.id;
            });

            if (foundFilterIndex !== -1) {
                state.draft.filters.splice(foundFilterIndex, 1, action.payload);
            }
        },
    },

    extraReducers: (builder: ActionReducerMapBuilder<NoInfer<ReportsState>>) => {
        builder.addMatcher(
            isAnyOf(
                reportsApi.endpoints.create.matchFulfilled,
                reportsApi.endpoints.update.matchFulfilled,
                reportsApi.endpoints.copy.matchFulfilled,
            ),
            (
                state: Draft<ReportsState>,
                action: PayloadAction<
                    Report,
                    string,
                    {
                        arg: {
                            originalArgs: {
                                options?: ReportMutationOptions;
                            };
                        };
                    }
                >,
            ) => {
                const {
                    options = {
                        silent: false,
                    },
                } = action.meta.arg.originalArgs;

                if (!options.silent) {
                    state.report = action.payload;
                    state.draft = action.payload;
                }
            },
        );
    },
});
