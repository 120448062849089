import React from "react";
import ReactDOM from "react-dom/client";

import "@total-typescript/ts-reset";
import "normalize.css";
import "the-new-css-reset/css/reset.css";

import "src/view/styles/global.scss";

import reportWebVitals from "./reportWebVitals";

import { App } from "./App";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
    // FIXME abort controller in useLayoutEffect
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>,
);

reportWebVitals();
