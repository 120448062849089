import type { CSSProperties } from "react";
import React, { useLayoutEffect } from "react";
import { useElementSize } from "../hooks";
import type { OverflowContainerProvided } from "../types";

const hiddenStyles: CSSProperties = {
    position: "absolute",
    opacity: 0,
    zIndex: -1,
    pointerEvents: "none",
};

function View({
    items,
    getData, //
    index,
    children,
}: Props) {
    const { elementRef, size } = useElementSize();

    useLayoutEffect(() => {
        getData(size.width, index);
    }, [getData, size.width, index]);

    const visible = items[index];

    return (
        <div
            style={visible ? undefined : hiddenStyles}
            ref={elementRef}
        >
            {children}
        </div>
    );
}

export const Overflow = React.memo(View);

type Props = OverflowContainerProvided &
    ChildrenProps & {
        index: number;
    };
