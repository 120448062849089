import React, { useId } from "react";
import classnames from "classnames/bind";
import styles from "./BasicCheckbox.module.scss";
import { CheckboxIcon } from "../../../custom-icons";

const cx = classnames.bind(styles);

export function BasicCheckbox({
    checked, //
    halfChecked = false,
    disabled,
    onChange,
    renderLabel,
    ...attributes
}: Props) {
    const inputId = useId();

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!disabled) {
            onChange(event.target.checked, event);
        }
    }

    return (
        <div className={cx("basic-checkbox")}>
            <label
                htmlFor={inputId}
                className={cx({
                    disabled,
                })}
            >
                <input
                    id={inputId}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                    {...attributes}
                />
                <div className={cx("checkbox-wrapper")}>
                    <CheckboxIcon
                        disabled={disabled}
                        checked={!checked && halfChecked ? "half-checked" : checked}
                    />
                </div>

                <div className={cx("label-text")}>{renderLabel(checked, disabled ?? false)}</div>
            </label>
        </div>
    );
}

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "checked" | "onChange"> {
    checked: boolean;
    halfChecked?: boolean;
    onChange: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
    renderLabel: (checked: boolean, disabled: boolean) => React.ReactNode;
}
