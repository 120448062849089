import React from "react";
import type { ForwardedRef, RefAttributes } from "react";

import type { ITooltip, TooltipRefProps } from "react-tooltip";
import { Tooltip } from "react-tooltip";

const baseStyle: React.CSSProperties = {
    // transition: "none",
    background: "#2E3138",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 0,
    padding: 0,
    zIndex: 1000,
};

function View(props: Props, ref: ForwardedRef<TooltipRefProps>) {
    const { style, ...rest } = props;

    return (
        <Tooltip
            ref={ref}
            style={{
                ...baseStyle,
                ...(style ?? {}),
            }}
            noArrow
            opacity={1}
            delayShow={0}
            {...rest}
        />
    );
}

type Props = ITooltip & RefAttributes<TooltipRefProps>;

export const BaseTooltip = React.forwardRef(View);
