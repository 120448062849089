import type { FormEvent } from "react";
import React, { useCallback, useRef, useState } from "react";

import classnames from "classnames/bind";

import styles from "./ReportColumnsForm.module.scss";
import { DotSpinner } from "../../basic/spinners";
import { ButtonWrapper, InputErrorMessage } from "../../mix";
import { BasicButton, GhostButton, HiddenFormButton } from "../../basic/buttons";
import type { FieldErrors } from "react-hook-form";
import { useController, useFormContext } from "react-hook-form";
import type { ReportColumn, ReportFilter } from "src/core/entities";
import type { ReportFormInput, ReportFormOutput } from "src/validation";
import { reportFormSchema } from "src/validation";
import { useFindSourceColumnsQuery } from "src/store/services";
import invariant from "tiny-invariant";
import { ColumnList } from "./elements/ColumnList";
import type { SourceColumn } from "src/core/entities/SourceColumn";
import { reportColumnMapper, reportFilterMapper } from "src/utils/mappers";
import { getApiUrl, getReportSource } from "../../../../utils/common";

const cx = classnames.bind(styles);

export type ReportColumnsFormOutput = Pick<
    ReportFormOutput,
    "name" | "sourceId" | "type" | "columns" | "filters" | "API_URL" | "creatorEmail"
>;

const DEFAULT_COLUMNS: SourceColumn[] = [];

/**
 * Exclude by id predicate
 */
function ById(filterId: number) {
    return ({ id }: { id: number }) => id !== filterId;
}

/**
 * Restore default column value
 */
function restoreColumn(column: ReportColumn, defaultColumns: ReportColumn[]): ReportColumn {
    if (defaultColumns.length === 0) {
        return column;
    }

    const foundDefaultColumn = defaultColumns.find((col: ReportColumn) => {
        return col.id === column.id;
    });

    if (!foundDefaultColumn) {
        return column;
    }

    return { ...foundDefaultColumn };
}

/**
 * Restore default filter value
 */
function restoreFilter(filter: ReportFilter, defaultFilters: ReportFilter[]): ReportFilter {
    if (defaultFilters.length === 0) {
        return filter;
    }

    const foundDefaultFilter = defaultFilters.find((fil: ReportFilter) => {
        return fil.id === filter.id;
    });

    if (!foundDefaultFilter) {
        return filter;
    }

    if (foundDefaultFilter.value.type !== filter.value.type) {
        return filter;
    }

    return { ...foundDefaultFilter };
}

/**
 * Sort columns by prev positions and set new positions from 0 to ...
 */
function updateColumnsPositions(columns: ReportColumn[]) {
    if (columns.length === 0) {
        return columns;
    }

    const sorted = [...columns];

    sorted.sort((a: ReportColumn, b: ReportColumn) => {
        return a.position - b.position;
    });

    return sorted.map((column: ReportColumn, index: number) => ({
        ...column,
        position: index,
    }));
}

export function ReportColumnsForm({
    mode, //
    onSubmit,
    onNext,
    onBack,
}: Props) {
    const {
        control, //
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useFormContext<ReportFormInput, unknown, ReportColumnsFormOutput>();

    const [isSubmitting, setSubmitting] = useState(false);

    const [defaultColumns] = useState<ReportColumn[]>(updateColumnsPositions(getValues().columns));
    const [defaultFilters] = useState<ReportFilter[]>(getValues().filters);

    const formButtonRef = useRef<HTMLButtonElement>(null);

    const { sourceId } = getValues();

    invariant(sourceId, "sourceId should be specified");

    const {
        data: columnsList = DEFAULT_COLUMNS, //
        isLoading: isColumnsLoading,
    } = useFindSourceColumnsQuery(getReportSource(sourceId));

    const { field: columns } = useController({
        name: "columns",
        control,
        defaultValue: defaultColumns,
        rules: {
            validate: (value: ReportColumn[]) => {
                const parsed = reportFormSchema.columns.safeParse(value);

                if (!parsed.success) {
                    return parsed.error.issues[0]?.message;
                }

                return;
            },
        },
    });

    const { field: filters } = useController({
        name: "filters",
        control,
        rules: {
            validate: (value: ReportFilter[]) => {
                const parsed = reportFormSchema.filters.safeParse(value);

                if (!parsed.success) {
                    return parsed.error.issues[0]?.message;
                }

                return;
            },
        },
    });

    function handleNext() {
        onNext();
    }

    async function handleSubmitAsync(values: ReportColumnsFormOutput) {
        setSubmitting(true);

        try {
            if (values.filters.length === 0) {
                const sourceColumns = columnsList.filter((column: SourceColumn) => {
                    return values.columns.some((col: ReportColumn) => column.id === col.id);
                });

                const API_URL = getApiUrl({
                    sourceId: values.sourceId,
                    columns: sourceColumns,
                    pairs: [],
                });

                setValue("API_URL", API_URL);

                await onSubmit({
                    ...values,
                    API_URL,
                });
            } else {
                await onSubmit(values);
            }
        } catch {
            //
        }

        setSubmitting(false);
    }

    function handleReset() {
        updateColumns([]);
        updateFilters([]);
    }

    const handleBack = useCallback(() => {
        onBack();
    }, [onBack]);

    function onValid(values: ReportColumnsFormOutput) {
        const updated = updateColumnsPositions(values.columns);

        setValue("columns", updated);

        if (values.filters.length === 0) {
            void handleSubmitAsync({
                ...values,
                columns: updated,
            });
        } else {
            handleNext();
        }
    }

    function onInvalid(_: FieldErrors<ReportColumnsFormOutput>) {
        console.log(_);
    }

    /**
     * Convert source column to report column
     */
    function mapColumn(sourceColumn: SourceColumn): ReportColumn {
        return restoreColumn(
            reportColumnMapper.toValue(sourceColumn), //
            defaultColumns,
        );
    }

    /**
     * Convert source column to report filter
     */
    function mapFilter(sourceColumn: SourceColumn): ReportFilter {
        return restoreFilter(
            reportFilterMapper.toValue(sourceColumn), //
            defaultFilters,
        );
    }

    function updateColumns(list: ReportColumn[]) {
        columns.onChange(list);
    }

    function updateFilters(list: ReportFilter[]) {
        filters.onChange(list);
    }

    /**
     * Update "Show totals" option in numeric column
     */
    function updateShowTotals(checked: boolean, column: ReportColumn) {
        updateColumns(
            columns.value.map((col: ReportColumn) => {
                if (column.id === col.id) {
                    return {
                        ...col,
                        showTotals: checked,
                    };
                }

                return col;
            }),
        );
    }

    /**
     * Update "Show averages" option in numeric column
     */
    function updateShowAverages(checked: boolean, column: ReportColumn) {
        updateColumns(
            columns.value.map((col: ReportColumn) => {
                if (column.id === col.id) {
                    return {
                        ...col,
                        showAverages: checked,
                    };
                }

                return col;
            }),
        );
    }

    function handleColumnChange(checked: boolean, sourceColumn: SourceColumn) {
        if (checked) {
            updateColumns([...columns.value, mapColumn(sourceColumn)]);
        } else {
            updateColumns(columns.value.filter(ById(sourceColumn.id)));
            updateFilters(filters.value.filter(ById(sourceColumn.id)));
        }
    }

    function handleFilterChange(checked: boolean, sourceColumn: SourceColumn) {
        if (checked) {
            updateFilters([...filters.value, mapFilter(sourceColumn)]);
        } else {
            updateFilters(filters.value.filter(ById(sourceColumn.id)));
        }
    }

    function handleShowTotalsChange(checked: boolean, column: SourceColumn) {
        const foundColumn = columns.value.find((col: ReportColumn) => col.id === column.id);

        if (!foundColumn) return;

        updateShowTotals(checked, foundColumn);
    }

    function handleShowAveragesChange(checked: boolean, column: SourceColumn) {
        const foundColumn = columns.value.find((col: ReportColumn) => col.id === column.id);

        if (!foundColumn) return;

        updateShowAverages(checked, foundColumn);
    }

    function handleCheckAll(newColumns: SourceColumn[]) {
        updateColumns(
            newColumns.map((col: SourceColumn) => {
                if (col.type === "NUMERIC") {
                    return {
                        ...mapColumn(col),
                        showTotals: true,
                        showAverages: true,
                    };
                }

                return mapColumn(col);
            }),
        );
    }

    const nextButtonTitle = (() => {
        if (filters.value.length > 0) {
            return "Configure filters";
        }

        if (mode === "create") {
            return "Create report";
        }

        return "Save report";
    })();

    return (
        <div className={cx("report-columns-form")}>
            <form
                onSubmit={(event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault();

                    void handleSubmit(onValid, onInvalid)(event);
                }}
            >
                {isColumnsLoading ? (
                    <div className={cx("loading")}>
                        <DotSpinner />
                    </div>
                ) : (
                    <ColumnList
                        options={columnsList}
                        values={{
                            columns: columns.value,
                            filters: filters.value,
                        }}
                        onColumnChange={handleColumnChange}
                        onFilterChange={handleFilterChange}
                        onShowTotalsChange={handleShowTotalsChange}
                        onShowAveragesChange={handleShowAveragesChange}
                        onCheckAll={handleCheckAll}
                    />
                )}
                <HiddenFormButton ref={formButtonRef} />
            </form>

            <div className={cx("footer")}>
                <ButtonWrapper shift="left">
                    <BasicButton
                        title={nextButtonTitle}
                        style={{ width: "132px" }}
                        isLoading={isSubmitting}
                        disabled={isColumnsLoading || columns.value.length === 0}
                        onClick={() => {
                            formButtonRef.current?.click();
                        }}
                    />
                    <GhostButton
                        title="Reset Columns"
                        style={{ width: "128px", marginLeft: "16px" }}
                        onClick={handleReset}
                    />
                    <GhostButton
                        title="Back"
                        style={{ width: "100px", marginLeft: "16px" }}
                        onClick={handleBack}
                    />
                </ButtonWrapper>

                <div className={cx("is-column-not-found")}>
                    <InputErrorMessage
                        name="columns"
                        errors={errors}
                    />
                </div>
            </div>
        </div>
    );
}

interface Props {
    mode: "create" | "edit";
    onSubmit: (values: ReportColumnsFormOutput) => Promise<void> | void;
    onNext: () => void;
    onBack: () => void;
}
