import React, { useCallback, useLayoutEffect } from "react";

import classnames from "classnames/bind";

import styles from "./DeleteReportPopup.module.scss";
import { DialogPopup } from "../../dialog";
import type { RootState } from "../../../../../store/store";
import { useAppSelector } from "../../../../../store/store";
import invariant from "tiny-invariant";
import { useDeleteReportMutation } from "../../../../../store/services";
import type { Report } from "../../../../../core/entities";
import { Description } from "../../../mix";
import { useNotifications, usePopups, useReports, useVisibleReportsList } from "src/view/hooks";
import { isErrorWithMessage, isFetchBaseQueryError } from "src/utils/helpers";

const cx = classnames.bind(styles);

const titles = {
    confirm: "Yes",
    cancel: "No",
};

export function DeleteReportPopup() {
    const { actions: popupsActions } = usePopups();
    const { notify } = useNotifications();
    const { actions: reportsActions } = useReports();

    const [deleteReport, { isSuccess: isDeleteSuccess, error: deleteReportError }] = useDeleteReportMutation();

    const { data: visibleReports } = useVisibleReportsList();
    const { report } = useAppSelector((state: RootState) => state.reports);

    invariant(report, "Report should be specified");

    useLayoutEffect(() => {
        if (isFetchBaseQueryError(deleteReportError) && deleteReportError.status === "FETCH_ERROR") {
            notify.error(deleteReportError.error);
        }

        if (isErrorWithMessage(deleteReportError)) {
            notify.error(deleteReportError.message);
        }
    }, [deleteReportError, notify]);

    /**
     * Select another report with same source type (if exists), or first report from list
     */
    useLayoutEffect(() => {
        if (isDeleteSuccess) {
            if (visibleReports && visibleReports.length > 1) {
                const anotherReport = visibleReports.find((r: Report) => {
                    return r.sourceId === report.sourceId && r.id !== report.id;
                });

                reportsActions.setReport(anotherReport ?? visibleReports[0] ?? null);
            } else {
                reportsActions.setReport(null);
            }

            popupsActions.deleteReportPopup.close();
        }
    }, [report, visibleReports, isDeleteSuccess, popupsActions, reportsActions]);

    const handleConfirm = useCallback(async () => {
        await deleteReport(report.id);
    }, [report, deleteReport]);

    const handleClose = useCallback(() => {
        popupsActions.deleteReportPopup.close();
    }, [popupsActions]);

    return (
        <DialogPopup
            titles={titles}
            onConfirm={handleConfirm}
            onCancel={handleClose}
            onClose={handleClose}
        >
            <div className={cx("delete-report-popup")}>
                <Description
                    title="You are going to delete the Report"
                    description="Click Yes to proceed, or No to cancel."
                />
            </div>
        </DialogPopup>
    );
}
