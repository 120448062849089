import classNames from "classnames/bind";
import { createAbbreviation } from "src/utils/helpers";
import styles from "./Person.module.scss";
import React from "react";

const cx = classNames.bind(styles);

export function Person({ name, logo, role }: Props) {
    const abbreviation = createAbbreviation(name);

    return (
        <div className={cx("person")}>
            <div className={cx("avatar")}>
                <div
                    style={{
                        backgroundImage: `url(${logo})`,
                    }}
                    className={cx("image-logo")}
                />
                <div className={cx("abbreviation")}>{abbreviation.trim() || "?"}</div>
            </div>

            <div className={cx("name-wrapper")}>
                <div className={cx("name")}>{name.trim() || "Unknown"}</div>
                <div className={cx("position")}>{role}</div>
            </div>
        </div>
    );
}

interface Props {
    name: string;
    logo: string;
    role: string;
}
