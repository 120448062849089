import { Endpoints } from "../../core/constants/endpoint.constants";
import type { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { z } from "zod";
import type { User } from "../../core/entities";

const PHOTO_URL = "https://compass.apexdigital.online/secure/api/v2/90539/User/Photo/attachment/";

const userSchema = z.object({
    "@row.id": z.number(),
    "Full Name": z.string(),
    "Role": z.string(),
    "E-mail": z.string(),
    "Holiday Approval First Instance": z.string(),
    "Photo": z.string().nullable(),
});

type UserSchemaInput = z.input<typeof userSchema>;
type UserSchemaOutput = z.output<typeof userSchema>;

function getTeamLead(user: UserSchemaOutput, users: UserSchemaInput[]) {
    const teamLeadEmail = user["Holiday Approval First Instance"].match(/<(.*)>/)?.[1] ?? null;

    return users.find((resource: UserSchemaInput) => resource["E-mail"] === teamLeadEmail)?.["@row.id"] ?? null;
}

function getLogo(user: UserSchemaOutput) {
    const imagePath = user.Photo?.split(";")[2] ?? null;

    return imagePath ? PHOTO_URL + imagePath : null;
}

export const usersApi = createApi({
    reducerPath: "users-api",
    baseQuery: fetchBaseQuery({
        baseUrl: Endpoints.API_USER_DATA,
    }),
    endpoints: (builder: EndpointBuilder<BaseQueryFn, string, string>) => ({
        /**
         * Get full list of users
         */
        findAll: builder.query<User[], void>({
            query: () => {
                const urlParams = new URLSearchParams();

                urlParams.append("column", "Full Name");
                urlParams.append("column", "Role");
                urlParams.append("column", "E-mail");
                urlParams.append("column", "Holiday Approval First Instance");
                urlParams.append("column", "Photo");

                urlParams.append("filter", "[Show in Resource Scheduler]=true");

                return "select.json?" + urlParams.toString();
            },
            transformResponse(raw: UserSchemaInput[]): User[] {
                return raw
                    .map((item: UserSchemaInput) => {
                        const parsed = userSchema.safeParse(item);

                        if (!parsed.success) {
                            return null;
                        }

                        return parsed.data;
                    })
                    .filter(Boolean)
                    .map((item: UserSchemaOutput) => ({
                        id: item["@row.id"],
                        fullName: item["Full Name"],
                        role: item.Role,
                        email: item["E-mail"],
                        teamLeadId: getTeamLead(item, raw),
                        logo: getLogo(item),
                    }));
            },
        }),
    }),
});

export const {
    useFindAllQuery: useFindAllUsersQuery, //
} = usersApi;
